import React, { useState } from 'react';
import {
  Breadcrumbs,
  Typography,
  Link,
  AppBar,
  Box,
  Backdrop,
  Button,
  Grid,
} from '@material-ui/core';
import { Link as RouterLink, useHistory, useParams } from 'react-router-dom';
import {
  VerticalBoxContainer,
  TabPanel,
  useAPI,
  Spinner,
  AntTab,
  AntTabs,
  PageContentWrapper,
} from '@shared/components';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import StorageIcon from '@material-ui/icons/Storage';

import PageHeader from '../../components/PageHeader';
import { a11yProps } from '../../utils/tabFormatter';
import SubjectsModal from '../Subjects/Modal';
import axios from '../../utils/axios';
import useGlobalAlertContext from '../../hooks/useGlobalAlertContext';
import useTheme from '../../hooks/useTheme';
import useAuthContext from '../../hooks/useAuthContext';
import ActionModal from '../Subjects/ActionModal';
import ArchiveModal from '../Subjects/ArchiveModal';
import DocumentHistory from './Tabs/History';
import Documents from './Tabs/Documents';
import Comments from './Tabs/Comments';
import SubjectInfo from './SubjectInfo';

import { ActionButton } from './style';

const SubjectDetails = () => {
  const theme = useTheme();
  const [currentTab, setCurrentTab] = useState(0);
  const [modalVisible, setModalVisible] = useState(false);
  const [triggerRerender, setRerender] = useState(false);
  const [loadingReturn, setLoadingReturn] = useState(false);
  const [actionModalVisible, setActionModalVisible] = useState(false);
  const [archiveModalVisible, setArchiveModalVisible] = useState(false);

  const history = useHistory();
  const { sendSuccessAlertData, sendErrorAlertData } = useGlobalAlertContext();
  const { id } = useParams();
  const { user } = useAuthContext();
  const SCREEN = 'EKRAN1';


  // to do dodati uslove za ekran
  const [subject, error, apiLoading] = useAPI(
    () => axios.get(`subjects/${id}`, { params: { screen: SCREEN } }).then(({ data }) => data),
    [triggerRerender],
  );

  const canArchive = subject?.status === 2 || subject?.status === 4;
  const canResolve = subject?.status === 1 || subject?.status === 5;

  const canEdit = subject?.status === 1 || subject?.status === 5;

  const handleChange = (_, newValue) => {
    setCurrentTab(newValue);
  };

  const tabs = [
    {
      name: 'Dokumenti',
      component: <Documents subjectId={id} editable={canEdit} insertAllowed={subject?.canAddDocuments === 'D'} />,
    },
    {
      name: 'Komentari',
      component: <Comments subjectId={id} insertAllowed={subject?.canAddDocuments === 'D'} />,
    },
    {
      name: 'Historija',
      component: <DocumentHistory subjectId={id} />,
    },
  ];

  if (apiLoading)
    return (
      <Backdrop
        open
        style={{
          zIndex: theme.zIndex.drawer - 1,
          backgroundColor: theme.palette.background.default,
        }}
      >
        <Spinner size={50} />
      </Backdrop>
    );

  if (error) {
    sendErrorAlertData(
      `${
        error?.response?.data?.details?.reason ||
        error?.response?.data?.error?.message ||
        `Došlo je do greške prilikom preuzimanja informacija o predmetu`
      }`,
    );
    history.replace('/subjects');
  }

  return (
    <VerticalBoxContainer>
      <SubjectsModal
        subject={subject}
        setSubject={() => {}}
        resetSubject={() => {}}
        visible={modalVisible}
        setVisible={setModalVisible}
        sendSuccessAlertData={sendSuccessAlertData}
        sendErrorAlertData={sendErrorAlertData}
        setRerender={setRerender}
        disabled={
          subject &&
          ((subject.status !== 5 && subject.status !== 1) ||
            user?.username !== subject.responsiblePersonUsername)
        }
      />
      <ActionModal
        subjectId={subject?.id}
        visible={actionModalVisible}
        setVisible={setActionModalVisible}
        sendSuccessAlertData={sendSuccessAlertData}
        sendErrorAlertData={sendErrorAlertData}
        setRerender={setRerender}
        setLoadingReturn={setLoadingReturn}
        actionType="resolve"
        // disabled={!userHasAnyRoles(['DMS_PD_EDIT'])}
      />
      <ArchiveModal
        subject={subject}
        setSubject={() => {}}
        resetSubject={() => {}}
        visible={archiveModalVisible}
        setVisible={setArchiveModalVisible}
        sendSuccessAlertData={sendSuccessAlertData}
        sendErrorAlertData={sendErrorAlertData}
        setRerender={setRerender}
        disabled={subject?.status !== 2 && subject?.status !== 4}
      />
      <PageHeader
        showBackIcon
        title={`Detalji predmeta ${id}`}
        history={history}
        extra={
          <Grid container spacing={4}>
            {subject?.responsiblePersonUsername === user?.username && (
              <>
                <Grid item>
                  <ActionButton
                    variant="outlined"
                    disabled={!canArchive && !canResolve}
                    actioncolor={theme.palette.info.main}
                    onClick={() => {
                      if (canArchive) setArchiveModalVisible(true);
                      else setActionModalVisible(true);
                    }}
                    startIcon={
                      loadingReturn ? (
                        <Spinner size={20} />
                      ) : canArchive ? (
                        <StorageIcon />
                      ) : (
                        <AssignmentTurnedInIcon />
                      )
                    }
                  >
                    <Box fontSize="small">
                      {canArchive ? 'Arhiviraj predmet' : 'Riješi predmet'}
                    </Box>
                  </ActionButton>
                </Grid>
                {canEdit && (
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={apiLoading ? <Spinner size={15} /> : <EditOutlinedIcon />}
                      disabled={apiLoading || !canEdit}
                      onClick={() => setModalVisible(true)}
                    >
                      <Box fontSize="small">Uredi predmet</Box>
                    </Button>
                  </Grid>
                )}
              </>
            )}
          </Grid>
        }
      />
      <PageContentWrapper style={{ padding: `80px 0 0 0` }}>
        <Breadcrumbs
          style={{
            padding: `${theme.spacing(5)}px 0 ${theme.spacing(3)}px ${theme.spacing(6)}px`,
          }}
        >
          <Link variant="body1" color="inherit" to="/" component={RouterLink}>
            Početna
          </Link>
          <Link variant="body1" color="inherit" to="/subjects" component={RouterLink}>
            Predmeti
          </Link>
          <Typography variant="body1" color="textPrimary">
            {tabs[currentTab].extendedName ? tabs[currentTab].extendedName : tabs[currentTab].name}
          </Typography>
        </Breadcrumbs>
        <Box mr={6} ml={6} style={{ marginBottom: '24px' }}>
          <SubjectInfo subject={subject} />
        </Box>
        <Box mr={6} ml={6}>
          <AppBar
            position="static"
            style={{
              background: `${theme.palette.background.default}`,
              padding: '0',
            }}
            elevation={0}
          >
            <AntTabs
              value={currentTab}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
            >
              {tabs.map((tab, index) => (
                <AntTab key={tab.name} label={tab.name} {...a11yProps(index)} />
              ))}
            </AntTabs>
          </AppBar>
          {tabs.map((tab, index) => (
            <TabPanel
              key={tab.name}
              value={currentTab}
              index={index}
              dir={theme.direction}
              theme={theme}
              style={{ marginTop: '0px' }}
            >
              {tab.component}
            </TabPanel>
          ))}
        </Box>
      </PageContentWrapper>
    </VerticalBoxContainer>
  );
};

export default SubjectDetails;
