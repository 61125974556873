import React, { useState, useRef, useCallback, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import Card from '@material-ui/core/Card';
import ErrorOutlineRoundedIcon from '@material-ui/icons/ErrorOutlineRounded';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { Spinner, QuickSearch, useAPI } from '@shared/components';
import { useTransferListStyles, ErrorMessage } from './style';
import axios from '../../../utils/axios';

// Logic functions
const not = (a, b, keyL, keyR) => {
  return a.filter((value) => b.map((item) => item[keyR]).indexOf(value[keyL]) === -1);
};

const intersection = (a, b) => {
  return a.filter((value) => b.map((item) => item.id).indexOf(value.id) !== -1);
};

const union = (a, b, keyL, keyR) => {
  return [...a, ...not(b, a, keyL, keyR)];
};

const ListCard = ({
  title,
  list,
  side,
  offsetList,
  setOffsetList,
  checked,
  setChecked,
  loading,
  limitValue,
  labelName,
  keyNameLeft,
  // keyNameRight,
  classes,
}) => {
  const lastListItem = useRef(null);
  const [searchFilter, setSearchFilter] = useState({ [labelName]: '' });

  useEffect(() => {
    // TODO: Pokreće se nakon funkcije lastListElementRef
    if (setOffsetList && !searchFilter[labelName]) setOffsetList(limitValue);
  }, [searchFilter]);

  // Only left side has infinite loading
  const filteredList = list
    .filter((listItem) =>
      searchFilter[labelName]
        ? listItem[labelName].toLowerCase().includes(searchFilter[labelName].toLowerCase())
        : true,
    )
    .slice(0, side === 'L' ? offsetList : list.length);

  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleToggle = (value) => () => {
    const currentIndex = checked.map((item) => item.id).indexOf(value.id);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items, keyNameLeft, keyNameLeft));
    } else {
      setChecked(union(checked, items, keyNameLeft, keyNameLeft));
    }
  };

  const lastListElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (lastListItem.current) lastListItem.current.disconnect();
      lastListItem.current = new window.IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && list.length > filteredList.length) {
          setOffsetList((prev) => prev + limitValue);
        }
      });
      if (node) lastListItem.current.observe(node);
    },
    [loading],
  );

  return (
    <Card>
      <CardHeader
        className={classes.cardHeader}
        avatar={
          <Checkbox
            onClick={handleToggleAll(filteredList)}
            checked={
              numberOfChecked(filteredList) === filteredList.length && filteredList.length !== 0
            }
            indeterminate={
              numberOfChecked(filteredList) !== filteredList.length &&
              numberOfChecked(filteredList) !== 0
            }
            disabled={filteredList.length === 0}
            inputProps={{ 'aria-label': 'all items selected' }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(filteredList)}/${filteredList.length} odabrano`}
      />
      <Box>
        <QuickSearch
          name={labelName}
          className={classes.search}
          placeholder="Brza pretraga"
          setFilters={setSearchFilter}
          mainFilter={searchFilter}
          fullWidth
        />
      </Box>
      <Divider />
      <CardContent className={classes.cardContent}>
        <List className={classes.list} dense component="div" role="list">
          {filteredList.map((value, i) => {
            const isLastElement = filteredList.length === i + 1;
            const labelId = `transfer-list-all-item-${value[labelName]}-${value.id}-label`;
            return (
              <ListItem
                key={`${value[labelName]}-${value.id}`}
                {...(side === 'L' && isLastElement && { ref: lastListElementRef })}
                role="listitem"
                button
                onClick={handleToggle(value)}
              >
                <ListItemIcon>
                  <Checkbox
                    checked={checked.indexOf(value) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{ variant: 'body2' }}
                  id={labelId}
                  primary={`${value[labelName]}`}
                />
              </ListItem>
            );
          })}
          {loading && (
            <ListItem>
              <ListItemIcon>
                <Spinner size={20} />
              </ListItemIcon>
              <ListItemText primary="Učitavanje..." />
            </ListItem>
          )}
        </List>
      </CardContent>
    </Card>
  );
};

const TransferList = ({
  visible,
  cardTitleLeft,
  cardTitleRight,
  toggleFullHeight,
  routeNameLeft,
  routeNameRight,
  rightSideList,
  setRightSideList,
  listMounted,
  setListMounted,
  defaultConditionsRight = {},
  defaultConditionsLeft = {},
  defaultSorters = [],
  labelName = 'name',
  keyNameLeft = 'id',
  keyNameRight = 'id',
  limitValue = 20,
}) => {
  // Retrieve data once the first time list is mounted
  useEffect(() => {
    if (visible) setListMounted(true);
  }, [visible]);

  const classes = useTransferListStyles({ toggleFullHeight });

  const [checked, setChecked] = useState([]);
  const [leftSideList, setLeftSideList] = useState([]);

  // Transfer list elements to show each load
  const [offsetList, setOffsetList] = useState(20);

  const [, rightError, rightLoading] = useAPI(() => {
    return routeNameRight && visible && !listMounted
      ? axios
        .get(routeNameRight, {
          params: {
            // limit: limitValue, // temp
            orderBy: defaultSorters,
            conditions: defaultConditionsRight,
          },
        })
        .then(({ data }) => {
          setRightSideList(data.nodes);
        })
      : Promise.resolve(() => ({ nodes: [], totalCount: 0 }));
  }, [listMounted, visible]);

  const [, leftError, leftLoading] = useAPI(() => {
    return routeNameLeft && visible && !rightLoading
      ? axios
        .get(routeNameLeft, {
          params: {
            orderBy: defaultSorters,
            conditions: defaultConditionsLeft,
          },
        })
        .then(({ data }) => {
          setLeftSideList(not(data.nodes, rightSideList, keyNameLeft, keyNameRight));
        })
      : Promise.resolve(() => ({ nodes: [], totalCount: 0 }));
    }, [rightLoading, visible, rightSideList]);

  // Moving elements of the list
  const leftChecked = intersection(checked, leftSideList);
  const rightChecked = intersection(checked, rightSideList);

  const handleCheckedRight = () => {
    setRightSideList(rightSideList.concat(leftChecked));
    setLeftSideList(not(leftSideList, leftChecked, keyNameLeft, keyNameLeft));
    setChecked(not(checked, leftChecked, keyNameLeft, keyNameLeft));
  };

  const handleCheckedLeft = () => {
    setLeftSideList(leftSideList.concat(rightChecked));
    setRightSideList(not(rightSideList, rightChecked, keyNameRight, keyNameRight));
    setChecked(not(checked, rightChecked, keyNameRight, keyNameRight));
  };

  return (
    <>
      {leftError || rightError ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="37.1rem"
          minWidth="100%"
        >
          <Box display="flex" justifyContent="center">
            <ErrorOutlineRoundedIcon fontSize="large" />
            <ErrorMessage>
              <Typography variant="h3">
                {leftError?.data?.details?.reason ||
                  rightError?.data?.details?.reason ||
                  'Došlo je do greške'}
              </Typography>
            </ErrorMessage>
          </Box>
        </Box>
      ) : (
        <Grid container spacing={2} direction="row" alignItems="center" className={classes.root}>
          <Grid item sm={5} className={classes.gridItem}>
            <ListCard
              side="L"
              offsetList={offsetList}
              setOffsetList={setOffsetList}
              title={cardTitleLeft}
              list={leftSideList}
              checked={checked}
              setChecked={setChecked}
              loading={leftLoading}
              limitValue={limitValue}
              labelName={labelName}
              keyNameLeft={keyNameLeft}
              keyNameRight={keyNameRight}
              classes={classes}
            />
          </Grid>
          <Grid item sm={2}>
            <Grid container direction="column" alignItems="center">
              <Button
                size="large"
                variant="outlined"
                className={classes.button}
                onClick={handleCheckedRight}
                disabled={leftChecked.length === 0}
                aria-label="move selected right"
              >
                &gt;
              </Button>
              <Button
                size="large"
                variant="outlined"
                className={classes.button}
                onClick={handleCheckedLeft}
                disabled={rightChecked.length === 0}
                aria-label="move selected left"
              >
                &lt;
              </Button>
            </Grid>
          </Grid>
          <Grid item sm={5} className={classes.gridItem}>
            <ListCard
              side="R"
              title={cardTitleRight}
              list={rightSideList}
              checked={checked}
              setChecked={setChecked}
              loading={rightLoading}
              limitValue={limitValue}
              labelName={labelName}
              keyNameLeft={keyNameLeft}
              keyNameRight={keyNameRight}
              classes={classes}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default TransferList;
