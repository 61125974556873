import React from 'react';
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  Tooltip,
  TablePagination,
  Box,
  Typography,
} from '@material-ui/core';
import ErrorOutlineRoundedIcon from '@material-ui/icons/ErrorOutlineRounded';
import { TableSkeleton, dateFormatterTime, dateFormatterDate } from '@shared/components';
import { ErrorMessage } from './style';

const List = ({
  subjectHistory,
  tableColumns,
  page,
  setPage,
  totalCount,
  rowsPerPage,
  setRowsPerPage,
  error,
  loading,
  sorters,
  setSorters,
  handleSortChange,
  getSortDirection,
}) => {
  const handleRowsPerPageChange = ({ target: { value } }) => {
    if (value * (page + 1) >= totalCount) setPage(Math.floor(totalCount / value));
    setRowsPerPage(value);
  };

  return (
    <Table>
      <TableHead>
        <TableRow>
          {tableColumns.map((column) => (
            <TableCell width={column.width} key={column.value} align={column.align}>
              <Tooltip
                enterDelay={300}
                placement="bottom-start"
                title={`Sortiraj prema ${column.placeholder}`}
              >
                <TableSortLabel
                  active={!!getSortDirection(column.value, sorters)}
                  direction={getSortDirection(column.value, sorters)}
                  onClick={() => handleSortChange(column.value, sorters, setSorters)}
                >
                  {column.name}
                </TableSortLabel>
              </Tooltip>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {error ? (
          <TableRow>
            <TableCell align="center" colSpan="100%" height={5 * 70.33}>
              <Box display="flex" justifyContent="center">
                <ErrorOutlineRoundedIcon fontSize="large" />
                <ErrorMessage>{error?.data?.details?.reason || 'Došlo je do greške'}</ErrorMessage>
              </Box>
            </TableCell>
          </TableRow>
        ) : loading || !subjectHistory ? (
          <TableRow>
            <TableCell align="center" colSpan="100%">
              <TableSkeleton size={rowsPerPage} />
            </TableCell>
          </TableRow>
        ) : subjectHistory?.length === 0 ? (
          <TableRow>
            <TableCell align="center" colSpan="100%" height={80}>
              <Typography>Nije pronađena historija predmeta</Typography>
            </TableCell>
          </TableRow>
        ) : (
          subjectHistory?.map((subjectHistoryRow) => (
            <TableRow
              style={{
                borderBottom: 0,
              }}
              key={subjectHistoryRow.id}
            >
              <TableCell width={150}>{subjectHistoryRow.action}</TableCell>
              <TableCell width={150}>{subjectHistoryRow.createdBy}</TableCell>
              <TableCell width={150}>
                {dateFormatterDate(subjectHistoryRow.actionDate)}
                <Typography variant="h6" color="textSecondary">
                  {dateFormatterTime(subjectHistoryRow.actionDate)}
                </Typography>
              </TableCell>
              <TableCell width={150}>{subjectHistoryRow.description}</TableCell>
            </TableRow>
          ))
        )}
        <TableRow>
          <TablePagination
            width="100%"
            count={totalCount}
            page={page}
            labelRowsPerPage="Broj redova po stranici"
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleRowsPerPageChange}
            rowsPerPageOptions={[5, 10, 20, 25]}
            onPageChange={(_, newPage) => setPage(newPage)}
          />
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default List;
