import React, { useState, useEffect } from 'react';
import {
  Box,
  CardContent,
  Divider,
  Grid,
  TextField,
  FormHelperText,
  Typography,
  CardHeader,
  Card,
  MenuItem,
} from '@material-ui/core';
import ErrorOutlineRoundedIcon from '@material-ui/icons/ErrorOutlineRounded';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import {
  InputModal,
  FormSelect,
  FormAutocomplete,
  FormSelectDependant,
  dateFormatter,
  useAPI,
  dateNow,
} from '@shared/components';
import { documentAttributeSchema } from '@shared/partner-schemas';
import axios from '../../../utils/axios';
import useTheme from '../../../hooks/useTheme';
import { ContentBox, ErrorMessage } from './style';

const Modal = ({
  document,
  setDocument,
  visible,
  setVisible,
  sendSuccessAlertData,
  resetDocument,
  setRerender,
}) => {
  const [loading, setLoading] = useState(false);
  const [errorAlertOpen, setErrorAlertOpen] = useState(false);
  const [requestError, setRequestError] = useState();

  const theme = useTheme();
  const defaultData = { nodes: [], totalCount: 0 };
  const { register, handleSubmit, clearErrors, errors, reset, control, watch } = useForm({
    mode: 'onChange',
    resolver: yupResolver(documentAttributeSchema),
  });

  const watchType = watch('documentType', document ? document?.documentType : '');

  useEffect(() => {
    setLoading(false);
    setRequestError(null);
    clearErrors();
    reset();
  }, []);

  const [
    acData = {
      documentTypes: [],
      documentsData: [],
    },
    acError,
    acLoading,
  ] = useAPI(() => {
    return document && visible
      ? Promise.all([
          document.documentType ? axios.get(`document-types`) : Promise.resolve(() => defaultData),
          document.document
            ? axios.get(`documents`, {
                params: {
                  limit: 10,
                  conditions: document
                    ? {
                        id: document.document,
                        screen: 'EKRAN0',
                      }
                    : {},
                },
              })
            : Promise.resolve(() => defaultData),
        ]).then(([{ data: documentTypes }, { data: documentsData }]) => ({
          documentTypes,
          documentsData,
        }))
      : Promise.resolve(() => defaultData);
  }, [document]);

  const handleClose = () => {
    clearErrors();
    setVisible(false);
    resetDocument();
  };

  const handleAlertClose = () => setErrorAlertOpen(false);

  const onSubmit = async ({ documentID, ...values }) => {
    const documentValues = {
      document: documentID ? documentID.id : null,
      ...values,
    };

    try {
      setLoading(true);
      const { data } = await axios.put(`document-attributes/${document.id}`, {
        documentAttribute: documentValues,
      });
      sendSuccessAlertData(`Uspješno izmijenjen dokument`);
      if (setDocument) setDocument((prevState) => ({ ...prevState, ...data }));
      setRerender((prevState) => !prevState);
      handleClose();
    } catch (error) {
      setRequestError(error.response);
      setErrorAlertOpen(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <InputModal
      object={document}
      updateTitle={`Pregled dokumenta ${document?.id}`}
      visible={visible}
      onSubmit={onSubmit}
      handleSubmit={handleSubmit}
      handleClose={handleClose}
      handleAlertClose={handleAlertClose}
      requestError={requestError}
      errorAlertOpen={errorAlertOpen}
      modalWidth="35%"
      loading={loading}
      showHeader
      theme={theme}
      disabledEdit
    >
      <ContentBox>
        {acError ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height={435}
            minWidth="100%"
          >
            <Box display="flex" justifyContent="center">
              <ErrorOutlineRoundedIcon fontSize="large" />
              <ErrorMessage>
                <Typography variant="h3">
                  {acError?.data?.details?.reason || 'Došlo je do greške'}
                </Typography>
              </ErrorMessage>
            </Box>
          </Box>
        ) : (
          <Grid container spacing={6}>
            <Grid item xs={12} sm={12}>
              <Card style={{ height: '100%' }} elevation={4}>
                <CardHeader
                  title="Osnovni podaci"
                  titleTypographyProps={{ variant: 'subtitle2' }}
                />
                <Divider />
                <CardContent>
                  <Box mt={2}>
                    <TextField
                      fullWidth
                      name="id"
                      id="id"
                      label="ID"
                      inputRef={register}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      defaultValue={document?.id}
                      disabled
                    />
                  </Box>
                  <Box mt={2}>
                    <TextField
                      fullWidth
                      name="dateCreated"
                      id="dateCreated"
                      label="Datum kreiranja"
                      inputRef={register}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      defaultValue={
                        document
                          ? dateFormatter(document?.dateCreated, true)
                          : dateFormatter(dateNow(), true)
                      }
                      disabled
                    />
                  </Box>
                  <Box mt={2}>
                    <TextField
                      fullWidth
                      name="createdBy"
                      id="createdBy"
                      label="Korisnik kreirao"
                      inputRef={register}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      defaultValue={document?.createdBy}
                      disabled
                    />
                  </Box>
                  <Box mt={2}>
                    <FormAutocomplete
                      error={!!errors.documentID}
                      fullWidth
                      label="Dokument*"
                      name="documentID"
                      placeholder="Unesite dva ili više karaktera"
                      defaultValue={document?.document}
                      routeName="documents"
                      initialData={acData?.documentsData}
                      optionsLabel="documentTypeName"
                      showSide="id"
                      variant="standard"
                      defaultConditions={{ screen: 'EKRAN0' }}
                      control={control}
                      axios={axios}
                      register={register}
                      loading={acLoading}
                      required
                      acDisabled
                    />
                    {errors.documentID && (
                      <FormHelperText error>{errors.documentID.message}</FormHelperText>
                    )}
                  </Box>
                  <Box mt={2}>
                    <FormSelect
                      error={!!errors.documentType}
                      fullWidth
                      required
                      name="documentType"
                      label="Tip dokumenta*"
                      id="documentType"
                      variant="standard"
                      defaultValue={document?.documentType}
                      placeholder="Izaberite tip dokumenta"
                      control={control}
                      loading={acLoading}
                      disabled
                    >
                      {!acData?.documentTypes || !acData?.documentTypes?.nodes?.length ? (
                        <MenuItem value={document?.document}>{document?.documentTypeName}</MenuItem>
                      ) : (
                        acData?.documentTypes?.nodes.map((documentTypes) => (
                          <MenuItem key={documentTypes.id} value={documentTypes.id}>
                            {documentTypes.name}
                          </MenuItem>
                        ))
                      )}
                    </FormSelect>
                    {errors.documentType && (
                      <FormHelperText error>{errors.documentType.message}</FormHelperText>
                    )}
                  </Box>
                  <Box mt={2}>
                    <FormSelectDependant
                      error={!!errors.attribute}
                      fullWidth
                      required
                      id="attribute"
                      name="attribute"
                      label="Atribut*"
                      variant="standard"
                      placeholder="Unesite broj blagajne"
                      defaultValue={document?.attribute}
                      defaultLabel={document?.attributeName}
                      control={control}
                      displayName="label"
                      valueName="attribute"
                      route={`document-type-details/${watchType}`}
                      conditionField="documentType"
                      value={watchType}
                      disabled
                      axios={axios}
                    />
                    {errors.attribute && (
                      <FormHelperText error>{errors.attribute.message}</FormHelperText>
                    )}
                  </Box>
                  <Box mt={2}>
                    <TextField
                      error={!!errors.valueDisplayed}
                      fullWidth
                      name="valueDisplayed"
                      id="valueDisplayed"
                      label="Vrijednost"
                      inputRef={register}
                      defaultValue={document?.valueDisplayed}
                      placeholder="Unesite broj stranica dokumenta"
                      disabled
                    />
                    {errors.valueDisplayed && (
                      <FormHelperText error>{errors.valueDisplayed.message}</FormHelperText>
                    )}
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        )}
      </ContentBox>
    </InputModal>
  );
};

export default Modal;
