import React, { useState, useEffect } from 'react';
import {
  Box,
  CardContent,
  Divider,
  Grid,
  TextField,
  FormHelperText,
  CardHeader,
  Card,
} from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { groupAccordanceSchema } from '@shared/partner-schemas';
import { InputModal, FormTextArea } from '@shared/components';
import axios from '../../../utils/axios';
import useTheme from '../../../hooks/useTheme';
import { ContentBox } from './style';

const GroupAccordancesModal = ({
  groupAccordance,
  setGroupAccordance,
  visible,
  setVisible,
  sendSuccessAlertData,
  sendErrorAlertData,
  resetGroupAccordance,
  setRerender,
  disabled,
}) => {
  const [loading, setLoading] = useState(false);
  const [errorAlertOpen, setErrorAlertOpen] = useState(false);
  const [requestError, setRequestError] = useState();
  const theme = useTheme();

  const { register, handleSubmit, clearErrors, errors, reset } = useForm({
    mode: 'onChange',
    resolver: yupResolver(groupAccordanceSchema),
  });
  useEffect(() => {
    setLoading(false);
    setRequestError(null);
    clearErrors();
    reset();
  }, []);

  const handleClose = () => {
    clearErrors();
    setVisible(false);
    resetGroupAccordance();
  };

  const handleAlertClose = () => setErrorAlertOpen(false);

  const onSubmit = async (values) => {
    const groupAccordanceValues = {
      ...values,
    };
    try {
      setLoading(true);
      const { data } = groupAccordance
        ? await axios.put(`group-accordances/${groupAccordance.id}`, {
            groupAccordance: groupAccordanceValues,
          })
        : await axios.post(`group-accordances`, { groupAccordance: groupAccordanceValues });
      if (!data) throw new Error();
      sendSuccessAlertData(
        `Uspješno ${groupAccordance ? 'izmijenjena' : 'dodana'} grupa saglasnosti`,
      );
      if (setGroupAccordance) setGroupAccordance((prevState) => ({ ...prevState, ...data }));
      setRerender((prevState) => !prevState);
    } catch (error) {
      sendErrorAlertData(
        `${
          error?.response?.data?.details?.reason ||
          error?.response?.data?.error?.message ||
          `Došlo je do greške prilikom ${
            groupAccordance ? 'uređivanja' : 'unosa'
          } grupe saglasnosti`
        }`,
      );
    } finally {
      setLoading(false);
      handleClose();
    }
  };

  return (
    <InputModal
      object={groupAccordance}
      createTitle="Kreiraj novu grupu saglasnosti"
      updateTitle={`Uredi grupu saglasnosti ${groupAccordance?.id}`}
      createButtonText="Kreiraj grupu"
      updateButtonText="Uredi grupu"
      visible={visible}
      onSubmit={onSubmit}
      handleSubmit={handleSubmit}
      handleClose={handleClose}
      handleAlertClose={handleAlertClose}
      requestError={requestError}
      errorAlertOpen={errorAlertOpen}
      modalWidth="35%"
      loading={loading}
      showHeader
      theme={theme}
      disabled={disabled}
    >
      <ContentBox>
        <Grid container spacing={6}>
          <Grid item xs={12} sm={12}>
            <Card style={{ height: '100%' }} elevation={4}>
              <CardHeader title="Osnovni podaci" titleTypographyProps={{ variant: 'subtitle2' }} />
              <Divider />
              <CardContent>
                <Box mt={5}>
                  <TextField
                    error={!!errors.name}
                    fullWidth
                    name="name"
                    id="name"
                    label="Naziv*"
                    inputRef={register}
                    defaultValue={groupAccordance?.name}
                    placeholder="Unesite naziv grupe saglasnosti"
                  />
                  {errors.name && <FormHelperText error>{errors.name.message}</FormHelperText>}
                </Box>
                <Box mt={5}>
                  <FormTextArea
                    register={register}
                    object={groupAccordance}
                    name="description"
                    placeholder="Unesite opis grupe saglasnosti"
                    errors={errors}
                    maxLength={4000}
                    rows={7}
                  />
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </ContentBox>
    </InputModal>
  );
};

export default GroupAccordancesModal;
