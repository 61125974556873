import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  FormHelperText,
  Card,
  CardContent,
  MenuItem,
  ListItemText,
  ListItem,
  List,
  Checkbox,
  ListItemSecondaryAction,
  // Divider,
  CardHeader,
  Grid,
  Tooltip,
} from '@material-ui/core';
import { useForm } from 'react-hook-form';
import Skeleton from '@material-ui/lab/Skeleton';
import { yupResolver } from '@hookform/resolvers/yup';
import { InputModal, FormTextArea, FormSelect, useAPI } from '@shared/components';
import { groupAccordanceDocumentSchema } from '@shared/partner-schemas';
import axios from '../../../../../utils/axios';
import useTheme from '../../../../../hooks/useTheme';

const GroupModal = ({
  document,
  setDocument,
  visible,
  setVisible,
  sendSuccessAlertData,
  sendErrorAlertData,
  setRerender,
  documentLoading,
}) => {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState([]);
  const [requestError, setRequestError] = useState({});
  const { handleSubmit, errors, reset, clearErrors, control, register } = useForm({
    resolver: yupResolver(groupAccordanceDocumentSchema),
  });

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };
  const onSubmit = async ({ groupAccordance, groupComment }) => {
    setLoading(true);
    const values = {
      groupAccordanceId: groupAccordance,
      groupComment,
    };
    try {
      const attributesAllowed =
        attributes.nodes?.length &&
        attributes.nodes.map((attribute) => {
          if (checked.includes(attribute.id)) return { id: attribute.id, allowed: true };
          else return { id: attribute.id, allowed: false };
        });

      const { data } = await axios.put(`documents/${document.id}/group-accordances`, {
        groupDocumentAccordance: values,
        attributesAllowed,
      });

      if (!data) throw new Error();
      setDocument(data);
      sendSuccessAlertData(`Uspješno dodana grupa saglasnosti za dokument ${document.id}`);
      setRerender((prevState) => !prevState);
      handleClose();
    } catch (error) {
      sendErrorAlertData(
        `${
          error?.response?.data?.details?.reason ||
          error?.response?.data?.error?.message ||
          'Došlo je do greške prilikom dodavanje grupe saglasnosti za dokument'
        }`,
      );
    } finally {
      setLoading(false);
    }
  };
  const [groupAccordances = { nodes: [], totalCount: 0 }, fsError, fsLoading] = useAPI(
    () =>
      document && visible
        ? axios
            .get(`group-accordances`, {
              params: {
                conditions: {},
                orderBy: ['"id" asc'],
              },
            })
            .then(({ data }) => data)
        : Promise.resolve(() => ({ nodes: [], totalCount: 0 })),
    [document, visible],
  );

  const [attributes = { nodes: [], totalCount: 0 }, attributesError, attributesLoading] = useAPI(
    () =>
      document && visible
        ? axios
            .get(`document-attributes`, {
              params: {
                conditions: { document: document.id },
                orderBy: ['"ordNumber" asc'],
              },
            })
            .then(({ data }) => {
              setChecked(data.nodes.map((node) => node.id));
              return data;
            })
        : Promise.resolve(() => {}),
    [document, visible],
  );

  const handleClose = () => {
    setVisible(false);
  };

  useEffect(() => {
    setLoading(false);
    setRequestError(null);
    clearErrors();
    reset();
  }, []);

  return (
    <InputModal
      createTitle={`Nova grupa saglasnosti za dokument ${document?.id}`}
      createButtonText="Dodaj grupu"
      visible={visible}
      onSubmit={onSubmit}
      handleSubmit={handleSubmit}
      handleClose={handleClose}
      requestError={requestError}
      modalWidth="50%"
      loading={loading || documentLoading}
      theme={theme}
    >
      <Box
        padding={`${theme.spacing(5)}px`}
        style={{ backgroundColor: theme.palette.background.dark }}
      >
        <Grid container direction="row" spacing={5}>
          <Grid item xs={6}>
            <Card elevation={3} style={{ height: '100%' }}>
              <CardContent>
                <Box
                  minHeight={10}
                  padding={`${theme.spacing(5)}px`}
                  paddingBottom={`${theme.spacing(5)}px`}
                >
                  <Typography
                    variant="h4"
                    style={{ padding: `${theme.spacing(4)}px 0 0` }}
                    align="center"
                  >
                    {`Dodavanje radnika iz grupe saglasnosti za dokument ${document?.id}`}
                  </Typography>
                </Box>
                <FormSelect
                  error={!!errors.groupAccordance || fsError}
                  fullWidth
                  label="Naziv grupe saglasnosti*"
                  name="groupAccordance"
                  id="groupAccordance"
                  control={control}
                  required
                >
                  {!groupAccordances || !groupAccordances?.nodes?.length || fsLoading ? (
                    <MenuItem />
                  ) : (
                    groupAccordances?.nodes.map((groupAccordance) => (
                      <MenuItem key={groupAccordance.id} value={groupAccordance.id}>
                        {groupAccordance.name}
                      </MenuItem>
                    ))
                  )}
                </FormSelect>
                {errors.groupAccordance && (
                  <FormHelperText error>{errors.groupAccordance.message}</FormHelperText>
                )}
                <FormTextArea
                  register={register}
                  name="groupComment"
                  placeholder="Unesite komentar za radnike grupe"
                  errors={errors}
                  maxLength={4000}
                  rows={7}
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Card elevation={3} style={{ height: '100%' }}>
              <CardHeader
                title="Postavljanje privilegija ažuriranja atributa za grupu"
                titleTypographyProps={{ variant: 'subtitle2' }}
              />
              {/* <Divider style={{ margin: `${theme.spacing(4)}px`, padding: 0 }} /> */}
              <CardContent
                style={{ padding: 0, paddingBottom: `${theme.spacing(5)}px`, height: '100%' }}
              >
                {attributesLoading ? (
                  <Box style={{ padding: `${theme.spacing(4)}px` }}>
                    {[...Array(8).keys()].map((i) => (
                      <Skeleton animation="wave" key={i} height={30} />
                    ))}
                  </Box>
                ) : attributesError ? (
                  <Box style={{ padding: `${theme.spacing(4)}px` }}>
                    <Typography
                      variant="h3"
                      style={{ padding: `${theme.spacing(4)}px 0 0` }}
                      align="center"
                    >
                      {attributesError?.data?.details?.reason || 'Došlo je do greške'}
                    </Typography>
                  </Box>
                ) : (
                  <List dense style={{ maxHeight: 300, overflow: 'auto' }}>
                    <ListItem divider>
                      <ListItemText
                        primary="Atribut"
                        primaryTypographyProps={{ style: { fontWeight: 700 } }}
                      />
                      <ListItemSecondaryAction>
                        <Tooltip
                          enterDelay={300}
                          title={
                            checked.length !== attributes?.nodes?.length
                              ? 'Uključi sve'
                              : 'Isključi sve'
                          }
                        >
                          <Checkbox
                            edge="end"
                            checked={checked.length === attributes?.nodes?.length}
                            indeterminate={
                              checked.length > 0 && checked.length < attributes?.nodes?.length
                            }
                            onClick={() => {
                              if (checked.length === attributes?.nodes?.length) {
                                setChecked([]);
                              } else {
                                setChecked(attributes?.nodes?.map((node) => node.id));
                              }
                            }}
                            disableRipple
                          />
                        </Tooltip>
                      </ListItemSecondaryAction>
                    </ListItem>
                    {attributes?.nodes?.map((value) => {
                      const labelId = `checkbox-lista-atributa-${value}`;
                      return (
                        <ListItem key={value.id} button onClick={handleToggle(value.id)}>
                          <ListItemText id={labelId} primary={value.attributeName} />
                          <ListItemSecondaryAction>
                            <Checkbox
                              edge="end"
                              onClick={handleToggle(value.id)}
                              checked={checked.indexOf(value.id) !== -1}
                              disableRipple
                              inputProps={{ 'aria-labelledby': labelId }}
                            />
                          </ListItemSecondaryAction>
                        </ListItem>
                      );
                    })}
                  </List>
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </InputModal>
  );
};

export default GroupModal;
