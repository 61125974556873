import React, { useState, useEffect } from 'react';
import {
  Divider,
  Grid,
  Box,
  Card,
  CardContent,
  FormHelperText,
  TextField,
  MenuItem,
} from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { InputModal, FormAutocomplete, FormSelect, FormCheckBox, useAPI } from '@shared/components';

import { documentSubjectAssign } from '@shared/partner-schemas';
import { Alert, AlertTitle } from '@material-ui/lab';
import axios from '../../../utils/axios';
import useTheme from '../../../hooks/useTheme';
import useAuthContext from '../../../hooks/useAuthContext';

import { CardHeader } from './style';

const AddToSubjectModal = ({
  document,
  setDocument,
  visible,
  setVisible,
  sendSuccessAlertData,
  sendErrorAlertData,
  setRerender,
  documentLoading = false,
  detailScreen = false,
}) => {
  const defaultData = { nodes: [], totalCount: 0 };
  const theme = useTheme();
  const { user } = useAuthContext();
  const [loading, setLoading] = useState(false);
  const [requestError, setRequestError] = useState({});
  const { handleSubmit, errors, reset, control, clearErrors, register, watch } = useForm({
    resolver: yupResolver(documentSubjectAssign),
  });

  const watchCheckbox = watch('existingSubject', true);

  const [
    acData = {
      bookTypeNames: [],
      responsiblePersonNames: [],
    },
    acError,
    acLoading,
  ] = useAPI(() => {
    return visible
      ? Promise.all([
          axios.get(`book-types`),
          axios.get(`workers`, {
            params: {
              conditions: { username: user.username, groupAccordance: true },
              orderBy: [],
              limit: 1,
            },
          }),
        ]).then(([{ data: bookTypeNames }, { data: responsiblePersonNames }]) => ({
          bookTypeNames,
          responsiblePersonNames,
        }))
      : Promise.resolve(() => defaultData);
  }, [visible]);

  const onSubmit = async ({
    subject,
    existingSubject,
    name,
    bookType,
    classificationMark,
    resolutionDeadline,
  }) => {
    // dodati yup
    let newSubject = {};
    const responsiblePerson = acData?.responsiblePersonNames?.nodes?.length
      ? acData.responsiblePersonNames.nodes[0]
      : null;
    const subjectValues = {
      name,
      bookType,
      resolutionDeadline,
      classificationMark: classificationMark ? classificationMark.id : null,
      organisation: responsiblePerson ? responsiblePerson.organisationId : null,
      responsiblePerson: responsiblePerson ? responsiblePerson.id : null,
    };
    setLoading(true);
    try {
      if (!existingSubject) {
        newSubject = await axios.post(`subjects`, {
          subject: subjectValues,
        });
        if (!newSubject) throw new Error();
      }

      const chosenSubj = existingSubject ? subject : newSubject.data;
      const requestFormData = new FormData();
      requestFormData.append(
        'documentData',
        JSON.stringify({
          id: document?.id,
          documentValues: {
            subject: chosenSubj ? chosenSubj.id : null,
          },
        }),
      );
      const data = await axios.put(`documents/${document.id}`, requestFormData);
      if (!data) throw new Error();
      sendSuccessAlertData('Uspješno dodan dokument na predmet');
      if (document) setDocument(data.data);
      setRerender((prevState) => !prevState);
      handleClose();
    } catch (error) {
      sendErrorAlertData(
        `${
          error?.response?.data?.details?.reason ||
          error?.response?.data?.error?.message ||
          'Došlo je do greške prilikom dodavanja dokumenta na predmet'
        }`,
      );
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    if (!detailScreen) setDocument(undefined);
    setVisible(false);
  };

  useEffect(() => {
    setLoading(false);
    setRequestError(null);
    clearErrors();
    reset();
  }, []);

  return (
    <InputModal
      object={document}
      updateTitle={`Dodaj dokument ${document?.id} na predmet`}
      updateButtonText="Potvrdi"
      visible={visible}
      onSubmit={onSubmit}
      handleSubmit={handleSubmit}
      handleClose={handleClose}
      requestError={requestError}
      modalWidth={700}
      loading={loading || documentLoading}
      theme={theme}
    >
      <Box
        padding={`${theme.spacing(5)}px`}
        style={{ backgroundColor: theme.palette.background.dark }}
      >
        <Card elevation={4} style={{ height: 'auto' }}>
          <CardContent>
            <Grid
              container
              style={{ minHeight: 100 }}
              spacing={6}
              direction="column"
              justifyContent="center"
              // alignItems="stretch"
            >
              <Grid item>
                <Alert severity="warning">
                  <AlertTitle>Upozorenje</AlertTitle>
                  Nakon što dodate dokument na predmet, dokument se više ne može ukloniti sa
                  predmeta
                </Alert>
              </Grid>
              <Grid item>
                <FormCheckBox
                  defaultChecked
                  labelPlacement="end"
                  name="existingSubject"
                  // backgroundColor="transparent"
                  control={control}
                  label="Postojeći predmet"
                  theme={theme}
                />
              </Grid>
              <Grid item>
                <FormAutocomplete
                  error={!!errors.subject}
                  fullWidth
                  label="Predmet"
                  name="subject"
                  placeholder="Unesite dva ili više karaktera"
                  routeName="subjects"
                  optionsLabel="name"
                  acDisabled={!watchCheckbox}
                  showSide="subjectNumber"
                  variant="standard"
                  control={control}
                  defaultConditions={{ canAddDocuments: 'D', screen: 'EKRAN1' }}
                  axios={axios}
                  register={register}
                />
                {errors.subject && <FormHelperText error>{errors.subject.message}</FormHelperText>}
              </Grid>
              <Grid item>
                <Card style={{ height: '100%', marginBottom: '0px' }} elevation={3}>
                  <CardHeader
                    title="Novi predmet"
                    // titleTypographyProps={{ variant: 'subtitle2' }}
                    style={{
                      padding: '8px',
                      color: watchCheckbox && theme.palette.text.secondary,
                      backgroundColor: watchCheckbox && theme.palette.background.dark,
                    }}
                    disabled={watchCheckbox}
                  />
                  <Divider />
                  <CardContent>
                    <Grid container spacing={4} direction="column">
                      <Grid item>
                        <TextField
                          error={!!errors.name}
                          size="small"
                          fullWidth
                          label="Naziv predmeta*"
                          name="name"
                          disabled={watchCheckbox}
                          id="name"
                          placeholder="Unesite naziv predmeta"
                          inputRef={register}
                        />
                        {errors.name && (
                          <FormHelperText error>{errors.name.message}</FormHelperText>
                        )}
                      </Grid>
                      <Grid item>
                        <FormSelect
                          error={!!errors.bookType}
                          fullWidth
                          disabled={watchCheckbox}
                          loading={acLoading}
                          label="Vrsta knjige*"
                          name="bookType"
                          id="bookType"
                          control={control}
                        >
                          {acError ? (
                            <MenuItem disabled>Došlo je do greške</MenuItem>
                          ) : acData.bookTypeNames?.nodes ? (
                            acData?.bookTypeNames?.nodes.map((bookType) => (
                              <MenuItem
                                key={bookType.id}
                                value={bookType.id}
                                disabled={bookType.isValid === 'N'}
                              >
                                {bookType.name}
                              </MenuItem>
                            ))
                          ) : (
                            <MenuItem disabled>Nema podataka</MenuItem>
                          )}
                        </FormSelect>
                        {errors?.bookType && (
                          <FormHelperText error>{errors?.bookType.message}</FormHelperText>
                        )}
                      </Grid>
                      <Grid item>
                        <FormAutocomplete
                          fullWidth
                          control={control}
                          loading={acLoading}
                          register={register}
                          error={acError || !!errors.classificationMark}
                          placeholder="Unesite klasifikacijsku oznaku"
                          label="Klasifikacijska oznaka*"
                          name="classificationMark"
                          routeName="classification-marks"
                          defaultConditions={{ isValid: 'D' }}
                          optionsLabel="name"
                          showSide="id"
                          axios={axios}
                          acDisabled={watchCheckbox}
                        />
                        {errors?.classificationMark && (
                          <FormHelperText error>
                            {errors?.classificationMark.message}
                          </FormHelperText>
                        )}
                        {acError && <FormHelperText error>Došlo je do greške.</FormHelperText>}
                      </Grid>
                      <Grid item>
                        <TextField
                          label="Rok rješavanja predmeta"
                          name="resolutionDeadline"
                          id="resolutionDeadline"
                          type="date"
                          disabled={watchCheckbox}
                          fullWidth
                          placeholder="Unesite datum roka rješavanja predmeta"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputRef={register()}
                        />
                        {errors.resolutionDeadline && (
                          <FormHelperText error>{errors.resolutionDeadline.message}</FormHelperText>
                        )}
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </InputModal>
  );
};

export default AddToSubjectModal;
