import React, { useState } from 'react';
import { Breadcrumbs, Typography, Link, AppBar } from '@material-ui/core';
import { Link as RouterLink, useHistory, useParams } from 'react-router-dom';
import { VerticalBoxContainer, TabPanel, useAPI } from '@shared/components';
import PageHeader from '../../../components/PageHeader';
import { a11yProps } from '../../../utils/tabFormatter';
import useTheme from '../../../hooks/useTheme';
import axios from '../../../utils/axios';
import useGlobalAlertContext from '../../../hooks/useGlobalAlertContext';
import DocumentTypes from './DocumentTypes';
import Logs from './Logs';
import { AntTabs, AntTab, PageContentWrapper } from './style';

const Details = () => {
  const theme = useTheme();
  const [currentTab, setCurrentTab] = useState(0);
  const history = useHistory();
  const { id: username } = useParams();
  const [user, setUser] = useState({});
  const { sendErrorAlertData } = useGlobalAlertContext();

  const [, , loading] = useAPI(
    () =>
      axios
        .get(`scan-users/${username}`)
        .then(({ data }) => {
          setUser(data);
        })
        .catch((error) => {
          sendErrorAlertData(
            `${
              error?.response?.data?.details?.reason ||
              error?.response?.data?.error?.message ||
              `Došlo je do greške prilikom preuzimanja informacija o korisniku za skeniranje`
            }`,
          );
          history.replace('/scan-users');
        }),
    [username],
  );

  const handleChange = (_, newValue) => {
    setCurrentTab(newValue);
  };

  const tabs = [
    {
      name: 'Tipovi dokumenata za korisnika',
      component: <DocumentTypes username={username} />,
    },
    {
      name: 'Logovi prijava u aplikaciju',
      component: <Logs username={username} />,
    },
  ];

  return (
    <VerticalBoxContainer>
      <PageHeader
        showBackIcon
        title={`Pregled: Korisnik za skeniranje ${username}`}
        subtitle={`Postavka skenera: ${loading ? '' : user?.scanOptionName}`}
        history={history}
      />
      <PageContentWrapper style={{ padding: `80px 0 0 0` }}>
        <AppBar
          position="static"
          style={{ background: `${theme.palette.background.default}` }}
          elevation={0}
        >
          <AntTabs
            value={currentTab}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
          >
            {tabs.map((tab, index) => (
              <AntTab key={tab.name} label={tab.name} {...a11yProps(index)} />
            ))}
          </AntTabs>
        </AppBar>
        <Breadcrumbs
          style={{
            padding: `${theme.spacing(5)}px 0 ${theme.spacing(3)}px ${theme.spacing(6)}px`,
          }}
        >
          <Link variant="body1" color="inherit" to="/" component={RouterLink}>
            Početna
          </Link>
          <Link variant="body1" color="inherit" to="/scan-users" component={RouterLink}>
            Korisnici za skeniranje
          </Link>
          <Typography variant="body1" color="textPrimary">
            {tabs[currentTab].extendedName ? tabs[currentTab].extendedName : tabs[currentTab].name}
          </Typography>
        </Breadcrumbs>
        {tabs.map((tab, index) => (
          <TabPanel
            key={tab.name}
            value={currentTab}
            index={index}
            dir={theme.direction}
            theme={theme}
          >
            {tab.component}
          </TabPanel>
        ))}
      </PageContentWrapper>
    </VerticalBoxContainer>
  );
};

export default Details;
