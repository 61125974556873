import React, { useState } from 'react';
import {
  CardHeader,
  Divider,
  Grid,
  Button,
  Box,
  Card,
  Breadcrumbs,
  Typography,
  Link,
} from '@material-ui/core';
import { Link as RouterLink, useHistory, useParams } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import FilterListIcon from '@material-ui/icons/FilterList';
import {
  VerticalBoxContainer,
  PageContentWrapper,
  FilterChip,
  Spinner,
  QuickSearch,
  useAPI,
  DeleteModal,
} from '@shared/components';
import PageHeader from '../../components/PageHeader';
import axios from '../../utils/axios';
import useTheme from '../../hooks/useTheme';
import ExcelExportButton from '../../components/ExcelExportButton';
import useGlobalAlertContext from '../../hooks/useGlobalAlertContext';
import DocumentTypeModal from '../DocumentTypes/Modal';
import { handleFilterChange, handleChipDelete } from '../../utils/filterHelpers';
import DetailsTable from './Table';
import Filter from './Filter';
import Modal from './Modal';

const fields = [
  { label: 'Redni broj', value: 'orderNumber' },
  { label: 'Labela', value: 'label' },
  { label: 'Šifra atributa', value: 'attributeCode' },
  { label: 'Grupa atributa', value: 'attributeGroupName' },
  { label: 'Inicijalna vrijednost', value: 'initialValue' },
  { label: 'SQL upit za podatke', value: 'sqlCodeForData' },
  { label: 'Uputstvo(hint)', value: 'instructions' },
  { label: 'Obavezan', value: 'required' },
  { label: 'Spasi', value: 'save' },
  { label: 'Važi', value: 'valid' },
  { label: 'Prikaži na skeniranju', value: 'showOnScan' },
  { label: 'Prikaži u porukama', value: 'showInMessages' },
];

const getLabelText = (field) => {
  const map = {
    orderNumber: 'Redni broj',
    label: 'Labela',
    attributeCode: 'Šifra atributa',
    initialValue: 'Inicijalna vrijednost',
    sqlCodeForData: 'SQL upit',
    instructions: 'Uputstvo(hint)',
    required: 'Obavezan',
    save: 'Spasi',
    valid: 'Važi',
    showOnScan: 'Prikaži na skeniranju',
    showInMessages: 'Prikaži u porukama',
    attributeGroupName: 'Grupa atributa',
  };
  return map[field];
};

const getChipValue = (chip, filters) => {
  const map = {
    valid: filters[chip] === 'D' ? 'Da' : 'Ne',
    save: filters[chip] === 2 ? 'Da' : 'Ne',
    required: filters[chip] === 'D' ? 'Da' : 'Ne',
    showOnScan: filters[chip] === 'D' ? 'Da' : 'Ne',
    showInMessages: filters[chip] === 'D' ? 'Da' : 'Ne',
  };
  return map[chip] ?? filters[chip];
};

const DocumentTypeDetails = () => {
  const theme = useTheme();
  const [sorters, setSorters] = useState([]);
  const [filters, setFilters] = useState({});
  const { id } = useParams();

  const [page, setPage] = useState(0);
  const history = useHistory();
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [triggerRerender, setRerender] = useState(false);
  const [filterModalVisible, setFilterModalVisible] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [docTypeModalVisible, setDocTypeModalVisible] = useState(false);
  const [documentType, setDocumentType] = useState({});
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [selectedUnit, setSelectedUnit] = useState();

  const { sendSuccessAlertData, sendErrorAlertData } = useGlobalAlertContext();

  const [, , documentTypeLoading] = useAPI(
    () =>
      axios
        .get(`document-types/${id}`)
        .then(({ data }) => {
          setDocumentType(data);
        })
        .catch((error) => {
          sendErrorAlertData(
            `${
              error?.response?.data?.details?.reason ||
              error?.response?.data?.error?.message ||
              `Došlo je do greške prilikom preuzimanja informacija o tipu dokumenta`
            }`,
          );
          history.replace('/document-types');
        }),
    [id, triggerRerender],
  );

  const [documentTypeDetails = { nodes: [], totalCount: 0 }, error, loading] = useAPI(
    () =>
      axios
        .get(`document-type-details/${id}`, {
          params: {
            limit: rowsPerPage,
            offset: page * rowsPerPage,
            conditions: {
              documentType: id,
              ...filters,
              save: filters.save !== undefined ? filters.save - 1 : undefined,
            },
            orderBy: [...sorters, '"orderNumber" asc'],
          },
        })
        .then(({ data }) => data),
    [page, rowsPerPage, filters, sorters, triggerRerender],
  );

  return (
    <VerticalBoxContainer>
      <Filter
        currentValues={filters}
        visible={filterModalVisible}
        setVisible={setFilterModalVisible}
        handleFilterChange={handleFilterChange}
        getLabelText={getLabelText}
        getChipValue={getChipValue}
        setFilters={setFilters}
        setPage={setPage}
      />
      <DocumentTypeModal
        documentType={documentType}
        setDocumentType={setDocumentType}
        resetDocumentType={() => setDocumentType(undefined)}
        visible={docTypeModalVisible}
        setVisible={setDocTypeModalVisible}
        setRerender={setRerender}
        sendSuccessAlertData={sendSuccessAlertData}
        sendErrorAlertData={sendErrorAlertData}
        detailed
      />
      <Modal
        documentTypeDetail={selectedUnit}
        setDocumentTypeDetail={setSelectedUnit}
        resetDocumentTypeDetail={() => setSelectedUnit(undefined)}
        visible={modalVisible}
        setVisible={setModalVisible}
        setRerender={setRerender}
        sendSuccessAlertData={sendSuccessAlertData}
        sendErrorAlertData={sendErrorAlertData}
        parentData={documentType}
      />
      <DeleteModal
        objectId={selectedUnit?.id}
        title={`Ukloni atribut ${
          selectedUnit?.label ? `sa šifrom ${selectedUnit?.attributeCode}` : ''
        }`}
        path="document-type-details"
        message="Uspješno izbrisan atribut datog tipa dokumenta"
        buttonText="Ukloni atribut"
        promptText={`Da li ste sigurni da želite izbrisati atribut ${
          selectedUnit?.label ? `sa šifrom ${selectedUnit?.attributeCode}` : ''
        }?`}
        resetObject={() => setSelectedUnit(undefined)}
        visible={deleteModalVisible}
        setVisible={setDeleteModalVisible}
        sendSuccessAlertData={sendSuccessAlertData}
        sendErrorAlertData={sendErrorAlertData}
        setRerender={setRerender}
        setPage={setPage}
        page={page}
        dataLength={documentTypeDetails.nodes.length}
        axios={axios}
        theme={theme}
      />
      <PageHeader
        showBackIcon
        title={`Atributi za tip dokumenta ${documentType?.name || ''}`}
        extra={
          <Grid container spacing={4}>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setDocTypeModalVisible(true)}
                startIcon={
                  documentTypeLoading ? <Spinner size={15} /> : <AddCircleOutlineOutlinedIcon />
                }
                disabled={documentTypeLoading}
              >
                <Box fontSize="small">Uredi tip dokumenta</Box>
              </Button>
            </Grid>
          </Grid>
        }
      />
      <PageContentWrapper>
        <Breadcrumbs style={{ paddingBottom: `${theme.spacing(5)}px` }}>
          <Link variant="body1" color="inherit" to="/" component={RouterLink}>
            Početna
          </Link>
          <Link variant="body1" color="inherit" to="/document-types" component={RouterLink}>
            Šifarnik: Tipovi dokumenata
          </Link>
          <Typography variant="body1" color="textPrimary">
            Atributi za tip dokumenta
          </Typography>
        </Breadcrumbs>
        <Card>
          <CardHeader
            title={
              <>
                <QuickSearch
                  name="attributeCode"
                  placeholder="Brza pretraga po šifri atributa"
                  setFilters={setFilters}
                  mainFilter={filters}
                  setPage={setPage}
                />
              </>
            }
            action={
              <Grid container spacing={4}>
                <Grid item>
                  <Button
                    color="primary"
                    disabled={Object.keys(filters).filter((k) => filters[k])?.length === 0}
                    startIcon={<CloseIcon fontSize="small" />}
                    onClick={() => setFilters({})}
                  >
                    <Box fontSize="small">Ukloni sve filtere</Box>
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<FilterListIcon fontSize="small" />}
                    onClick={() => setFilterModalVisible(true)}
                  >
                    <Box fontSize="small">Filteri</Box>
                  </Button>
                </Grid>
                <Grid item>
                  <ExcelExportButton
                    fields={fields}
                    variant="outlined"
                    sorters={[...sorters, '"orderNumber" asc']}
                    filters={{
                      documentType: id,
                      ...filters,
                      save: filters.save !== undefined ? filters.save - 1 : undefined,
                    }}
                    routeName={`document-type-details/${id}`}
                    fileName={`atributi-tipa-dokumenta-${id}`}
                    totalCount={documentTypeDetails.totalCount}
                  />
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddCircleOutlineOutlinedIcon />}
                    onClick={() => setModalVisible(true)}
                  >
                    <Box fontSize="small">Novi atribut dokumenta</Box>
                  </Button>
                </Grid>
              </Grid>
            }
          />
          <Divider />
          {Object.keys(filters).filter((k) => filters[k])?.length !== 0 && (
            <>
              <Box ml={2} p={2} display="flex" alignItems="center" flexWrap="wrap">
                {Object.keys(filters).map(
                  (chip) =>
                    filters[chip] !== undefined && (
                      <FilterChip
                        color="primary"
                        variant="outlined"
                        key={chip}
                        label={`${getLabelText(chip)}: ${getChipValue(chip, filters)}`}
                        onDelete={() => handleChipDelete(chip, setFilters, setPage)}
                      />
                    ),
                )}
              </Box>
            </>
          )}
          <DetailsTable
            loading={loading}
            error={error}
            documentTypeDetails={documentTypeDetails.nodes}
            page={page}
            setPage={setPage}
            sorters={sorters}
            setSorters={setSorters}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            totalCount={documentTypeDetails.totalCount}
            setSelectedUnit={setSelectedUnit}
            setDeleteModalVisible={setDeleteModalVisible}
            setModalVisible={setModalVisible}
          />
        </Card>
      </PageContentWrapper>
    </VerticalBoxContainer>
  );
};

export default DocumentTypeDetails;
