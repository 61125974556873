import React, { useState, useEffect } from 'react';
import {
  Box,
  CardContent,
  Divider,
  Grid,
  TextField,
  FormHelperText,
  Typography,
  CardHeader,
  Card,
  MenuItem,
} from '@material-ui/core';
import { yupResolver } from '@hookform/resolvers/yup';
import ErrorOutlineRoundedIcon from '@material-ui/icons/ErrorOutlineRounded';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import {
  groupAccordanceWorkerSchema,
  groupAccordanceWorkerSchemaUpdate,
} from '@shared/partner-schemas';
import {
  InputModal,
  Spinner,
  FormAutocomplete,
  FormSelect,
  useAPI,
  dateFormatter,
  dateNow,
} from '@shared/components';
import axios from '../../../utils/axios';
import useTheme from '../../../hooks/useTheme';
import { ContentBox, ErrorMessage } from './style';

const GroupWorkersModal = ({
  groupAccordance,
  worker,
  setSelectedWorker,
  visible,
  setVisible,
  sendSuccessAlertData,
  sendErrorAlertData,
  resetWorker,
  setRerender,
}) => {
  const [loading, setLoading] = useState(false);
  const { id: groupId } = useParams();
  const [errorAlertOpen, setErrorAlertOpen] = useState(false);
  const [requestError, setRequestError] = useState();
  const theme = useTheme();

  const { register, handleSubmit, clearErrors, errors, reset, control } = useForm({
    mode: 'onChange',
    resolver: yupResolver(worker ? groupAccordanceWorkerSchemaUpdate : groupAccordanceWorkerSchema),
  });
  useEffect(() => {
    setLoading(false);
    setRequestError(null);
    clearErrors();
    reset();
  }, []);

  const handleClose = () => {
    clearErrors();
    setVisible(false);
    resetWorker();
  };

  const handleAlertClose = () => setErrorAlertOpen(false);

  const onSubmit = async ({ id, ...values }) => {
    setLoading(true);
    values = {
      workerId: values?.worker?.id,
      accordanceId: groupAccordance?.id,
      ...values,
    };

    try {
      const { data } = worker
        ? await axios.put(`group-accordances-details/${worker.id}`, {
            groupAccordance: values,
          })
        : await axios.post(`group-accordances-details`, {
            groupAccordance: values,
          });
      if (!data) throw new Error();
      sendSuccessAlertData(`Uspješno ${worker ? 'izmijenjen' : 'dodan'} radnik grupe`);
      if (setSelectedWorker) setSelectedWorker((prevState) => ({ ...prevState, ...data }));
      setRerender((prevState) => !prevState);
      handleClose();
    } catch (error) {
      sendErrorAlertData(
        `${
          error?.response?.data?.details?.reason ||
          error?.response?.data?.error?.message ||
          `Došlo je do greške prilikom ${worker ? 'uređivanja' : 'unosa'} radnika grupe`
        }`,
      );
    } finally {
      setLoading(false);
    }
  };

  const [acSelectedWorker, acError, acLoading] = useAPI(() => {
    return worker && visible
      ? axios
          .get(`workers`, {
            params: {
              conditions: { id: worker?.workerId, groupAccordance: true },
              orderBy: [],
              limit: 1,
            },
          })
          .then(({ data }) => data)
      : Promise.resolve(() => ({ nodes: [], totalCount: 0 }));
  }, [worker]);

  return (
    <InputModal
      object={worker}
      createTitle={`Novi radnik grupe ${groupAccordance?.name}`}
      updateTitle={`Uredi radnika ${worker?.id}`}
      createButtonText="Dodaj radnika"
      updateButtonText="Uredi radnika"
      visible={visible}
      onSubmit={onSubmit}
      handleSubmit={handleSubmit}
      handleClose={handleClose}
      handleAlertClose={handleAlertClose}
      requestError={requestError}
      errorAlertOpen={errorAlertOpen}
      modalWidth="35%"
      loading={loading}
      showHeader
      theme={theme}
    >
      <ContentBox>
        {acError ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height={265}
            minWidth="100%"
          >
            <Box display="flex" justifyContent="center">
              <ErrorOutlineRoundedIcon fontSize="large" />
              <ErrorMessage>
                <Typography variant="h3">
                  {acError?.data?.details?.reason || 'Došlo je do greške'}
                </Typography>
              </ErrorMessage>
            </Box>
          </Box>
        ) : acLoading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height={265}
            minWidth="100%"
          >
            <Box display="flex" justifyContent="center">
              <Spinner size={60} />
            </Box>
          </Box>
        ) : (
          <Grid container spacing={6}>
            <Grid item xs={12} sm={12}>
              <Card style={{ height: '100%' }} elevation={4}>
                <CardHeader
                  title="Osnovni podaci"
                  titleTypographyProps={{ variant: 'subtitle2' }}
                />
                <Divider />
                <CardContent>
                  <Box>
                    <FormAutocomplete
                      error={acError || !!errors.worker}
                      fullWidth
                      label="Radnik*"
                      name="worker"
                      placeholder="Unesite dva ili više karaktera"
                      routeName="workers"
                      initialData={acSelectedWorker}
                      required
                      optionsLabel="fullName"
                      showSide="code"
                      variant="standard"
                      control={control}
                      defaultConditions={{
                        groupAccordance: true,
                        accordanceId: groupId,
                        removeAddedConsent: true,
                      }}
                      axios={axios}
                      register={register}
                      loading={acLoading}
                      acDisabled={!!worker}
                    />
                    {errors.worker && (
                      <FormHelperText error>{errors.worker.message}</FormHelperText>
                    )}
                  </Box>
                  <Box mt={5}>
                    <Box mt={5}>
                      <FormSelect
                        id="valid"
                        name="valid"
                        label="Važi"
                        required
                        error={!!errors.valid}
                        defaultValue={worker?.valid || 'D'}
                        control={control}
                        fullWidth
                      >
                        <MenuItem value="D">DA</MenuItem>
                        <MenuItem value="N">NE</MenuItem>
                      </FormSelect>
                      {errors.valid && (
                        <FormHelperText error>{errors.valid.message}</FormHelperText>
                      )}
                    </Box>
                  </Box>
                  <Box mt={5}>
                    <TextField
                      fullWidth
                      name="dateCreated"
                      id="dateCreated"
                      label="Datum kreiranja"
                      inputRef={register}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      defaultValue={
                        worker
                          ? dateFormatter(worker?.dateCreated, true)
                          : dateFormatter(dateNow(), true)
                      }
                      disabled
                    />
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        )}
      </ContentBox>
    </InputModal>
  );
};

export default GroupWorkersModal;
