import styled from 'styled-components';
import { Button as MuiButton } from '@material-ui/core';


export const ActionButton = styled(MuiButton)`
  :not(:disabled) {
    color: ${(props) => props?.actioncolor || 'primary'};
    border: 1px solid ${(props) => props?.actioncolor || 'primary'};
  }
`;
