import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  Tooltip,
  IconButton,
  TablePagination,
  Box,
  Typography,
  Collapse,
  Divider,
  SvgIcon,
} from '@material-ui/core';
import { useHistory } from 'react-router';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';
import LoopIcon from '@material-ui/icons/Loop';
import ErrorOutlineRoundedIcon from '@material-ui/icons/ErrorOutlineRounded';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import FindInPageOutlinedIcon from '@material-ui/icons/FindInPageOutlined';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { TableSkeleton, dateFormatterDate, dateFormatterTime, Spinner } from '@shared/components';
import { getSortDirection, handleSortChange } from '../../../utils/sortHelpers';
import useAuthContext from '../../../hooks/useAuthContext';
import useTheme from '../../../hooks/useTheme';
import { ErrorMessage, ExpandableRowCell, ExpandedRow, Chip } from './style';

const tableProperties = {
  headColumn: '5%',
  tailColumn: '20%',
  columns: [
    {
      value: 'subjectNumber',
      name: 'Broj predmeta',
      sortName: 'broju predmeta',
      width: '13%',
    },
    {
      value: 'name',
      name: 'Naziv predmeta',
      sortName: 'nazivu',
      width: '11%',
    },
    {
      value: 'classificationMarkName',
      name: 'Klasifikaciona oznaka',
      sortName: 'klasifikacionoj oznaci',
      width: '16%',
    },
    {
      value: 'bookTypeName',
      name: 'Vrsta knjige',
      sortName: 'vrsti knjige',
      width: '11%',
    },
    {
      value: 'archiveDate',
      name: 'Datum arhiviranja',
      sortName: 'datumu arhiviranja',
      width: '11%',
    },
    {
      value: 'archiveLocationName',
      name: 'Arhivska lokacija',
      sortName: 'arhivskoj lokaciji',
      width: '12%',
    },
  ],
};


const ExpandableRow = ({
  subject,
  setSelectedSubject,
  setModalVisible,
  sendSuccessAlertData,
  loadingReturn,
  setActionModalVisible,
  setActionType,
}) => {
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const theme = useTheme();
  const { user } = useAuthContext();

  return (
    <>
      <TableRow
        style={{
          borderBottom: 0,
        }}
        key={subject.id}
      >
        <ExpandableRowCell>
          <IconButton size="small" onClick={() => setOpen((prevState) => !prevState)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </ExpandableRowCell>
        <ExpandableRowCell>
          <Tooltip enterDelay={100} placement="top-end" arrow title="Kopiraj broj predmeta">
            <Chip
              label={subject.subjectNumber}
              color="primary"
              onClick={() =>
                navigator.clipboard
                  .writeText(subject.subjectNumber)
                  .then(() => sendSuccessAlertData(`Uspješno kopiran broj predmeta`))
              }
            />
          </Tooltip>
        </ExpandableRowCell>
        <ExpandableRowCell>{subject.name}</ExpandableRowCell>
        <ExpandableRowCell>{subject.classificationMarkName}</ExpandableRowCell>
        <ExpandableRowCell>{subject.bookTypeName}</ExpandableRowCell>
        <ExpandableRowCell>
          {dateFormatterDate(subject.archiveDate)}
          <Typography variant="h6" color="textSecondary">
            {dateFormatterTime(subject.archiveDate)}
          </Typography>
        </ExpandableRowCell>
        <ExpandableRowCell>
          {subject.archiveLocationName}
        </ExpandableRowCell>
        <ExpandableRowCell align="right">
          <>
            <Tooltip enterDelay={300} placement="top-end" arrow title="Aktiviraj predmet iz arhive">
              <span>
                <IconButton
                  disabled={loadingReturn || subject?.responsiblePersonUsername !== user?.username}
                  onClick={() => {
                    setActionType('activateFromArchive');
                    setActionModalVisible(true);
                    setSelectedSubject(subject);
                  }}
                >
                  <SvgIcon fontSize="small">{loadingReturn ? <Spinner /> : <LoopIcon />}</SvgIcon>
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip enterDelay={300} placement="top-end" arrow title="Izdaj predmet na revers">
              <span>
                <IconButton
                  disabled={loadingReturn || subject?.responsiblePersonUsername !== user?.username}
                  onClick={() => {
                    setActionType('issueOnReverse');
                    setActionModalVisible(true);
                    setSelectedSubject(subject);
                  }}
                >
                  <SvgIcon fontSize="small">
                    {loadingReturn ? <Spinner /> : <OpenInBrowserIcon />}
                  </SvgIcon>
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip enterDelay={300} placement="top-end" arrow title="Pregledaj predmet">
              <span>
                <IconButton
                  onClick={() => {
                    setSelectedSubject(subject);
                    setModalVisible(true);
                  }}
                >
                  <SvgIcon fontSize="small">
                    <FindInPageOutlinedIcon />
                  </SvgIcon>
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip enterDelay={300} placement="top-end" arrow title="Detaljni pregled predmeta">
              <IconButton
                onClick={() =>
                  history.push({
                    pathname: `/archived-subjects/${subject.id}`,
                    state: { subject },
                  })
                }
              >
                <SvgIcon fontSize="small">
                  <ArrowForwardIcon />
                </SvgIcon>
              </IconButton>
            </Tooltip>
          </>
        </ExpandableRowCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan="100%" style={{ padding: 0 }}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Divider light />
            <Box>
              <ExpandedRow>
                <Table size="small" aria-label="expanded">
                  <TableHead>
                    <TableRow>
                      <ExpandableRowCell width={tableProperties.headColumn} />
                      <ExpandableRowCell width="13%">Datum kreiranja</ExpandableRowCell>
                      <ExpandableRowCell width="11%">Organizacija</ExpandableRowCell>
                      <ExpandableRowCell width="16%">Odgovorna osoba</ExpandableRowCell>
                      <ExpandableRowCell width="11%">Korisnik kreirao</ExpandableRowCell>
                      <ExpandableRowCell width="11%">Važeći predmet</ExpandableRowCell>
                      <ExpandableRowCell width="11%">Vlastiti predmet</ExpandableRowCell>
                      <ExpandableRowCell width="28%" />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <ExpandableRowCell />
                      <ExpandableRowCell>
                        {dateFormatterDate(subject.dateCreated)}
                        <Typography variant="h6" color="textSecondary">
                          {dateFormatterTime(subject.dateCreated)}
                        </Typography>
                      </ExpandableRowCell>
                      <ExpandableRowCell>{subject.organisationName || ''}</ExpandableRowCell>
                      <ExpandableRowCell>{subject.responsiblePersonName || ''}</ExpandableRowCell>
                      <ExpandableRowCell>{subject.createdBy}</ExpandableRowCell>
                      <ExpandableRowCell>
                        <SvgIcon style={{ verticalAlign: 'middle' }}>
                          {subject.subjectValid === 'D' ? (
                            <DoneIcon style={{ color: theme.palette.success.main }} />
                          ) : (
                            <CloseIcon style={{ color: theme.palette.error.main }} />
                          )}
                        </SvgIcon>
                      </ExpandableRowCell>
                      <ExpandableRowCell>
                        <SvgIcon style={{ verticalAlign: 'middle' }}>
                          {subject.ownSubject === 'D' ? (
                            <DoneIcon style={{ color: theme.palette.success.main }} />
                          ) : (
                            <CloseIcon style={{ color: theme.palette.error.main }} />
                          )}
                        </SvgIcon>
                      </ExpandableRowCell>

                      <ExpandableRowCell />
                    </TableRow>
                  </TableBody>
                </Table>
              </ExpandedRow>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const SubjectsTable = ({
  subjects,
  page,
  setPage,
  totalCount,
  rowsPerPage,
  setRowsPerPage,
  error,
  loading,
  sorters,
  setSorters,
  setSelectedSubject,
  setModalVisible,
  sendSuccessAlertData,
  loadingReturn,
  setActionModalVisible,
  setActionType,
}) => {
  const handleRowsPerPageChange = ({ target: { value } }) => {
    if (value * (page + 1) >= totalCount) setPage(Math.floor(totalCount / value));
    setRowsPerPage(value);
  };

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell width={tableProperties.headColumn} />
          {tableProperties.columns.map((column) => (
            <TableCell key={column.value} width={column.width}>
              <Tooltip
                enterDelay={300}
                title={`Sortiraj prema ${column.sortName}`}
                placement="bottom-start"
              >
                <TableSortLabel
                  active={!!getSortDirection(column.value, sorters)}
                  direction={getSortDirection(column.value, sorters)}
                  onClick={() => handleSortChange(column.value, sorters, setSorters)}
                >
                  {column.name}
                </TableSortLabel>
              </Tooltip>
            </TableCell>
          ))}
          <TableCell width={tableProperties.tailColumn} />
        </TableRow>
      </TableHead>
      <TableBody>
        {error ? (
          <TableRow>
            <TableCell align="center" colSpan="100%" height={5 * 70.33}>
              <Box display="flex" justifyContent="center">
                <ErrorOutlineRoundedIcon fontSize="large" />
                <ErrorMessage>{error?.data?.details?.reason || 'Došlo je do greške'}</ErrorMessage>
              </Box>
            </TableCell>
          </TableRow>
        ) : loading || !subjects ? (
          <TableRow>
            <TableCell align="center" colSpan="100%" style={{ paddingTop: '0' }}>
              <TableSkeleton size={rowsPerPage} />
            </TableCell>
          </TableRow>
        ) : subjects?.length === 0 ? (
          <TableRow>
            <TableCell align="center" colSpan="100%" height={80}>
              <Typography>Nije pronađen niti jedan predmet</Typography>
            </TableCell>
          </TableRow>
        ) : (
          subjects?.map((subject) => (
            <ExpandableRow
              key={subject.id}
              subject={subject}
              setSelectedSubject={setSelectedSubject}
              setModalVisible={setModalVisible}
              sendSuccessAlertData={sendSuccessAlertData}
              loadingReturn={loadingReturn}
              setActionModalVisible={setActionModalVisible}
              setActionType={setActionType}
            />
          ))
        )}

        <TableRow>
          <TablePagination
            width="100%"
            count={totalCount}
            page={page}
            labelRowsPerPage="Broj redova po stranici"
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleRowsPerPageChange}
            rowsPerPageOptions={[5, 10, 20, 25]}
            onPageChange={(_, newPage) => setPage(newPage)}
          />
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default SubjectsTable;
