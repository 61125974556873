import React, { useState, useEffect } from 'react';
import {
  Box,
  CardContent,
  Divider,
  Grid,
  TextField,
  FormHelperText,
  Typography,
  CardHeader,
  Card,
  MenuItem,
} from '@material-ui/core';
import mime from 'mime-types';
import ErrorOutlineRoundedIcon from '@material-ui/icons/ErrorOutlineRounded';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { documentSchema } from '@shared/partner-schemas';
import {
  InputModal,
  Spinner,
  FormAutocomplete,
  FormSelect,
  FormFileUpload,
  useAPI,
  dateFormatter,
  dateNow,
} from '@shared/components';
import axios from '../../../../../utils/axios';
import useTheme from '../../../../../hooks/useTheme';
import { ContentBox, ErrorMessage } from './style';
import useAuthContext from '../../../../../hooks/useAuthContext';

const DocumentsModal = ({
  workerId,
  subject,
  document,
  setDocument,
  visible,
  setVisible,
  sendSuccessAlertData,
  sendErrorAlertData,
  resetDocument,
  setRerender,
  documentLoading = false,
}) => {
  const [loading, setLoading] = useState(false);
  const [errorAlertOpen, setErrorAlertOpen] = useState(false);
  const [requestError, setRequestError] = useState();
  const theme = useTheme();
  const { user } = useAuthContext();

  // Document Upload
  const [selectedFileName, setSelectedFileName] = useState('');
  const [selectedFile, setSelectedFile] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);

  const { register, handleSubmit, clearErrors, errors, reset, control, setValue } = useForm({
    mode: 'onChange',
    resolver: yupResolver(documentSchema),
  });
  useEffect(() => {
    setLoading(false);
    setRequestError(null);
    clearErrors();
    reset();
  }, []);

  const handleClose = () => {
    clearErrors();
    setVisible(false);
    setSelectedFile(null);
    setIsFilePicked(false);
    setSelectedFileName('');
    resetDocument();
  };

  const handleAlertClose = () => setErrorAlertOpen(false);
  const onSubmit = async ({ responsiblePerson, ...values }) => {
    const fileExtension = mime.extension(mime.contentType(selectedFileName));
    const documentValues = {
      ...values,
      responsiblePerson: responsiblePerson ? responsiblePerson.id : null,
      documentType: values?.documentType,
      documentName: selectedFile && selectedFileName.split('.').slice(0, -1).join('.'),
      documentFilesize: selectedFile && selectedFile?.size,
      documentExtension: selectedFile && `.${fileExtension}`,
      documentSort: 'I',
      subject,
    };
    const requestFormData = new FormData();
    requestFormData.append(
      'documentData',
      JSON.stringify({ id: document ? document?.id : null, documentValues }),
    );
    if (isFilePicked) requestFormData.append('file', selectedFile);
    // if (!document && (!isFilePicked || !fileExtension)) {
    //   sendErrorAlertData('Potrebno je da priložite/učitate dokument.');
    //   return;
    // }
    try {
      setLoading(true);
      const { data } = document
        ? await axios.put(`documents/${document.id}`, requestFormData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
        : await axios.post(`documents`, requestFormData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          });
      if (!data) throw new Error();
      sendSuccessAlertData(`Uspješno ${document ? 'izmijenjen' : 'dodan'} dokument`);
      if (setDocument) setDocument((prevState) => ({ ...prevState, ...data }));
      setRerender((prevState) => !prevState);
    } catch (error) {
      sendErrorAlertData(
        `${
          error?.response?.data?.details?.reason ||
          error?.response?.data?.error?.message ||
          `Došlo je do greške prilikom ${document ? 'uređivanja' : 'unosa'} dokumenta`
        }`,
      );
    } finally {
      setLoading(false);
      handleClose();
    }
  };

  useEffect(() => {
    if (selectedFile) {
      setValue('documentFilesize', selectedFile.size);
      const fileExtension = mime.extension(mime.contentType(selectedFileName));
      setValue('documentExtension', `.${fileExtension}`);
    } else {
      setValue('documentFilesize', '');
      setValue('documentExtension', '');
    }
  }, [selectedFile]);

  const [documentTypes, acError, acLoading] = useAPI(() => {
    return axios.get(`document-types`).then(({ data }) => data);
  }, []);
  const [acResponsiblePerson, acResponsiblePersonError, acResponsiblePersonLoading] = useAPI(() => {
    return visible && (document?.responsiblePerson || !document)
      ? axios
          .get(`workers`, {
            params: {
              conditions: { id: document?.responsiblePerson || workerId, groupAccordance: true },
              orderBy: [],
              limit: 1,
            },
          })
          .then(({ data }) => data)
      : Promise.resolve(() => ({ nodes: [], totalCount: 0 }));
  }, [document, visible]);
  return (
    <InputModal
      object={document}
      createTitle="Kreiraj novi izlazni dokument na predmetu"
      createButtonText="Kreiraj dokument"
      updateButtonText="Uredi dokument"
      updateTitle={
        document &&
        (document.status !== 0 || document?.createdBy !== user?.username) &&
        (document.status !== 2 || workerId !== document?.responsiblePerson)
          ? `Pregled dokumenta ${document?.id}`
          : `Uredi dokument ${document?.id}`
      }
      visible={visible}
      onSubmit={onSubmit}
      handleSubmit={handleSubmit}
      handleClose={handleClose}
      handleAlertClose={handleAlertClose}
      requestError={requestError}
      errorAlertOpen={errorAlertOpen}
      modalWidth="50%"
      loading={loading || documentLoading}
      showHeader
      disabledEdit={
        document &&
        (document.status !== 0 || document?.createdBy !== user?.username) &&
        (document.status !== 2 || workerId !== document?.responsiblePerson)
      }
      theme={theme}
    >
      <ContentBox>
        {acError ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height={610}
            minWidth="100%"
          >
            <Box display="flex" justifyContent="center">
              <ErrorOutlineRoundedIcon fontSize="large" />
              <ErrorMessage>
                <Typography variant="h3">
                  {acError?.data?.details?.reason || 'Došlo je do greške'}
                </Typography>
              </ErrorMessage>
            </Box>
          </Box>
        ) : acLoading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height={610}
            minWidth="100%"
          >
            <Box display="flex" justifyContent="center">
              <Spinner size={60} />
            </Box>
          </Box>
        ) : (
          <Grid direction="column" container spacing={6}>
            <Grid item xs={12}>
              <Grid container spacing={6}>
                <Grid item xs={12} sm={12}>
                  <Card style={{ height: '100%' }} elevation={4}>
                    <CardHeader
                      title="Osnovni podaci"
                      titleTypographyProps={{ variant: 'subtitle2' }}
                    />
                    <Divider />
                    <CardContent>
                      <Box>
                        <FormSelect
                          error={!!errors.documentType}
                          fullWidth
                          required
                          name="documentType"
                          label="Tip dokumenta*"
                          id="documentType"
                          variant="standard"
                          defaultValue={document?.documentType}
                          placeholder="Izaberite tip dokumenta"
                          control={control}
                          loading={acLoading}
                        >
                          {documentTypes?.nodes?.map((documentType) => (
                            <MenuItem key={documentType?.id} value={documentType?.id}>
                              {documentType?.name}
                            </MenuItem>
                          ))}
                        </FormSelect>
                        {errors.documentType && (
                          <FormHelperText error>{errors.documentType.message}</FormHelperText>
                        )}
                      </Box>
                      <Box mt={5}>
                        <TextField
                          error={!!errors.documentNoPages}
                          fullWidth
                          name="documentNoPages"
                          id="documentNoPages"
                          label="Broj stranica"
                          inputRef={register}
                          defaultValue={document?.documentNoPages}
                          placeholder="Unesite broj stranica dokumenta"
                          type="number"
                        />
                        {errors.documentNoPages && (
                          <FormHelperText error>{errors.documentNoPages.message}</FormHelperText>
                        )}
                      </Box>
                      <Box mt={5}>
                        <FormAutocomplete
                          error={acResponsiblePersonError || !!errors.responsiblePerson}
                          fullWidth
                          label="Odgovorna osoba"
                          name="responsiblePerson"
                          placeholder="Unesite dva ili više karaktera"
                          routeName="workers"
                          optionsLabel="fullName"
                          showSide="code"
                          variant="standard"
                          control={control}
                          axios={axios}
                          register={register}
                          loading={acResponsiblePersonLoading}
                          initialData={acResponsiblePerson}
                          defaultConditions={{
                            groupAccordance: true,
                          }}
                        />
                        {errors.responsiblePerson && (
                          <FormHelperText error>{errors.responsiblePerson.message}</FormHelperText>
                        )}
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Card style={{ height: '100%' }} elevation={4}>
                    <CardHeader title="Dokument" titleTypographyProps={{ variant: 'subtitle2' }} />
                    <Divider />
                    <CardContent>
                      <>
                        <Box mb={4}>
                          <FormFileUpload
                            fullWidth
                            placeholder="DODAJ DOKUMENT"
                            setSelectedFile={setSelectedFile}
                            selectedFileName={selectedFileName}
                            setSelectedFileName={setSelectedFileName}
                            setIsFilePicked={setIsFilePicked}
                            isFilePicked={isFilePicked}
                          />
                        </Box>
                        <Divider />
                      </>
                      <Box mt={!document ? 4 : 0}>
                        <TextField
                          fullWidth
                          name="documentExtension"
                          id="documentExtension"
                          label="Ekstenzija"
                          inputRef={register}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          defaultValue={document?.documentExtension}
                          disabled
                        />
                      </Box>
                      <Box mt={1}>
                        <TextField
                          fullWidth
                          name="documentFilesize"
                          id="documentFilesize"
                          label="Veličina fajla (bajt)"
                          inputRef={register}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          defaultValue={document?.documentFilesize}
                          disabled
                        />
                      </Box>
                      <Box mt={1}>
                        <TextField
                          fullWidth
                          name="dateCreated"
                          id="dateCreated"
                          label="Datum kreiranja"
                          inputRef={register}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          defaultValue={
                            document
                              ? dateFormatter(document?.dateCreated, true)
                              : dateFormatter(dateNow(), true)
                          }
                          disabled
                        />
                      </Box>
                      <Box mt={1}>
                        <TextField
                          fullWidth
                          name="createdBy"
                          id="createdBy"
                          label="Korisnik kreirao"
                          inputRef={register}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          defaultValue={document?.createdBy || user.username}
                          disabled
                        />
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </ContentBox>
    </InputModal>
  );
};

export default DocumentsModal;
