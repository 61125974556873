import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  Tooltip,
  TablePagination,
  Box,
  Typography,
  SvgIcon,
  IconButton,
} from '@material-ui/core';
import mime from 'mime-types';
import FindInPageOutlinedIcon from '@material-ui/icons/FindInPageOutlined';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import ErrorOutlineRoundedIcon from '@material-ui/icons/ErrorOutlineRounded';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import {
  TableSkeleton,
  Spinner,
  StatusLabel,
  dateFormatterTime,
  dateFormatterDate,
} from '@shared/components';
import useGlobalAlertContext from '../../../../../hooks/useGlobalAlertContext';

import axios from '../../../../../utils/axios';
import { ErrorMessage, Chip } from './style';
import useAuthContext from '../../../../../hooks/useAuthContext';

const List = ({
  workerId,
  documents,
  page,
  setPage,
  totalCount,
  rowsPerPage,
  setRowsPerPage,
  error,
  loading,
  sorters,
  setSorters,
  handleSortChange,
  getSortDirection,
  setSelectedDocumentFile,
  setDocumentModalVisible,
  sendSuccessAlertData,
  editable=false,
}) => {
  const { user } = useAuthContext();
  const handleRowsPerPageChange = ({ target: { value } }) => {
    if (value * (page + 1) >= totalCount) setPage(Math.floor(totalCount / value));
    setRowsPerPage(value);
  };

  const [downloadLoading, setDownloadLoading] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(undefined);
  const { sendErrorAlertData } = useGlobalAlertContext();

  const getDocumentFile = async (id, extension) => {
    setDownloadLoading(true);
    try {
      const { data } = await axios.get(`get-document/${id}`);
      const { documentFile } = data;
      const url = window.URL.createObjectURL(
        new Blob([new Uint8Array(documentFile.data).buffer], {
          type: mime.lookup(extension) || 'application/pdf',
        }),
      );

      const link = document.createElement('a');
      link.href = url;

      // link.setAttribute('download', `dokument-${id}${extension}`);
      link.setAttribute('target', '_blank');

      link.click();
    } catch (documentError) {
      sendErrorAlertData(
        `${
          documentError?.response?.data ||
          documentError?.data?.error?.message ||
          `Došlo je do greške prilikom preuzimanja dokumenta`
        }`,
      );
    } finally {
      setDownloadLoading(false);
      setSelectedDocument(undefined);
    }
  };

  const getStatusLabel = (status, statusName) => {
    if (status !== 0 && !status) return '';
    const map = {
      0: {
        color: 'info',
      },
      1: {
        color: 'neutral',
      },
      2: {
        color: 'secondary',
      },
      3: {
        color: 'primary',
      },
      4: {
        color: 'success',
      },
      5: {
        color: 'error',
      },
      6: {
        color: 'warning',
      },
      7: {
        color: 'info',
      },
    };

    const { color } = map[status] || 'neutral';

    return <StatusLabel color={color}>{statusName}</StatusLabel>;
  };

  return (
    <Table>
      <TableHead>
        <TableRow height="auto" style={{ maxHeight: '20%' }}>
          <TableCell width={110}>
            <Tooltip enterDelay={300} title="Sortiraj prema ID-u" placement="bottom-start">
              <TableSortLabel
                active={!!getSortDirection('id', sorters)}
                direction={getSortDirection('id', sorters)}
                onClick={() => handleSortChange('id', sorters, setSorters)}
              >
                ID
              </TableSortLabel>
            </Tooltip>
          </TableCell>
          <TableCell width={150}>
            <Tooltip enterDelay={300} title="Sortiraj prema ID-u" placement="bottom-start">
              <TableSortLabel
                active={!!getSortDirection('docProtocolNumber', sorters)}
                direction={getSortDirection('docProtocolNumber', sorters)}
                onClick={() => handleSortChange('docProtocolNumber', sorters, setSorters)}
              >
                Broj protokola
              </TableSortLabel>
            </Tooltip>
          </TableCell>
          <TableCell width={150}>
            <Tooltip
              enterDelay={300}
              title="Sortiraj prema tipu dokumenta"
              placement="bottom-start"
            >
              <TableSortLabel
                active={!!getSortDirection('documentTypeName', sorters)}
                direction={getSortDirection('documentTypeName', sorters)}
                onClick={() => handleSortChange('documentTypeName', sorters, setSorters)}
              >
                Tip dokumenta
              </TableSortLabel>
            </Tooltip>
          </TableCell>
          <TableCell width={150}>
            <Tooltip enterDelay={300} title="Sortiraj prema statusu" placement="bottom-start">
              <TableSortLabel
                active={!!getSortDirection('statusName', sorters)}
                direction={getSortDirection('StatusName', sorters)}
                onClick={() => handleSortChange('statusName', sorters, setSorters)}
              >
                Status
              </TableSortLabel>
            </Tooltip>
          </TableCell>
          <TableCell width={130}>
            <Tooltip
              enterDelay={300}
              title="Sortiraj prema datumu kreiranja"
              placement="bottom-start"
            >
              <TableSortLabel
                active={!!getSortDirection('dateCreated', sorters)}
                direction={getSortDirection('dateCreated', sorters)}
                onClick={() => handleSortChange('dateCreated', sorters, setSorters)}
              >
                Datum kreiranja
              </TableSortLabel>
            </Tooltip>
          </TableCell>
          <TableCell width={110}>
            <Tooltip enterDelay={300} title="Sortiraj prema korisniku" placement="bottom-start">
              <TableSortLabel
                active={!!getSortDirection('createdBy', sorters)}
                direction={getSortDirection('createdBy', sorters)}
                onClick={() => handleSortChange('createdBy', sorters, setSorters)}
              >
                Korisnik kreirao
              </TableSortLabel>
            </Tooltip>
          </TableCell>
          <TableCell width={120}>
            <Tooltip
              enterDelay={300}
              title="Sortiraj prema odgovornoj osobi"
              placement="bottom-start"
            >
              <TableSortLabel
                active={!!getSortDirection('responsiblePersonName', sorters)}
                direction={getSortDirection('responsiblePersonName', sorters)}
                onClick={() => handleSortChange('responsiblePersonName', sorters, setSorters)}
              >
                Odgovorna osoba
              </TableSortLabel>
            </Tooltip>
          </TableCell>
          <TableCell width={80} />
        </TableRow>
      </TableHead>
      <TableBody>
        {error ? (
          <TableRow>
            <TableCell align="center" colSpan="100%" height={5 * 70.33}>
              <Box display="flex" justifyContent="center">
                <ErrorOutlineRoundedIcon fontSize="large" />
                <ErrorMessage>{error?.data?.details?.reason || 'Došlo je do greške'}</ErrorMessage>
              </Box>
            </TableCell>
          </TableRow>
        ) : loading || !documents ? (
          <TableRow>
            <TableCell align="center" colSpan="100%">
              <TableSkeleton size={rowsPerPage} />
            </TableCell>
          </TableRow>
        ) : documents?.length === 0 ? (
          <TableRow>
            <TableCell align="center" colSpan="100%" height={80}>
              <Typography>Nije pronađen niti jedan dokument</Typography>
            </TableCell>
          </TableRow>
        ) : (
          documents?.map((document) => (
            <TableRow key={document.id} height="auto" style={{ maxHeight: '20%' }}>
              <TableCell width={110}>
                <Tooltip
                  enterDelay={100}
                  placement="top-start"
                  arrow
                  title={document.documentSort === 'U' ? 'Ulazni dokument' : 'Izlazni dokument'}
                >
                  <Box display="flex" gridGap={5}>
                    {document.documentSort === 'U' && (
                      <ExitToAppIcon fontSize="small" color="secondary" />
                    )}
                    <Typography>{document.id}</Typography>
                    {document.documentSort === 'I' && (
                      <ExitToAppIcon fontSize="small" color="primary" />
                    )}
                  </Box>
                </Tooltip>
              </TableCell>
              <TableCell width={150}>
                <Tooltip enterDelay={100} placement="top-end" arrow title="Kopiraj broj dokumenta">
                  <Chip
                    label={document.docProtocolNumber}
                    color={document.documentSort === 'U' ? 'secondary' : 'primary'}
                    onClick={() =>
                      navigator.clipboard
                        .writeText(document.docProtocolNumber)
                        .then(() => sendSuccessAlertData(`Uspješno kopiran broj dokumenta`))
                    }
                  />
                </Tooltip>
              </TableCell>
              <TableCell width={150}> {document.documentTypeName}</TableCell>
              <TableCell width={150}>
                {getStatusLabel(document.status, document.statusName)}
              </TableCell>
              <TableCell width={130}>
                {dateFormatterDate(document.dateCreated)}
                <Typography variant="h6" color="textSecondary">
                  {dateFormatterTime(document.dateCreated)}
                </Typography>
              </TableCell>
              <TableCell width={110}> {document.createdBy}</TableCell>
              <TableCell width={120}> {document.responsiblePersonName}</TableCell>

              <TableCell width={80} align="right">
                <>
                  <Tooltip
                    enterDelay={300}
                    placement="top-end"
                    arrow
                    title={
                      downloadLoading && document?.id === selectedDocument?.id
                        ? ''
                        : 'Prikaži dokument'
                    }
                  >
                    {downloadLoading && document?.id === selectedDocument?.id ? (
                      <span>
                        <IconButton size="small" disabled>
                          <Box pr={3}>
                            <Spinner color="primary" size={20} />
                          </Box>
                        </IconButton>
                      </span>
                    ) : (
                      <span>
                        <IconButton
                          disabled={downloadLoading}
                          onClick={() => {
                            setSelectedDocument(document);
                            getDocumentFile(document.id, document.documentExtension);
                          }}
                        >
                          <SvgIcon fontSize="small">
                            <InsertDriveFileOutlinedIcon />
                          </SvgIcon>
                        </IconButton>
                      </span>
                    )}
                  </Tooltip>
                  <Tooltip
                    enterDelay={300}
                    placement="top-end"
                    arrow
                    title={
                      (document &&
                      (document.status !== 0 || document?.createdBy !== user?.username) &&
                      (document.status !== 2 || workerId !== document?.responsiblePerson)) || !editable
                        ? 'Pregled dokumenta'
                        : 'Uredi dokument'
                    }
                  >
                    <IconButton
                      onClick={() => {
                        setSelectedDocumentFile(document);
                        setDocumentModalVisible(true);
                      }}
                    >
                      <SvgIcon fontSize="small">
                        {document &&
                        (document.status !== 0 || document?.createdBy !== user?.username) &&
                        (document.status !== 2 || workerId !== document?.responsiblePerson) ? (
                          <FindInPageOutlinedIcon />
                        ) : (
                          <EditOutlinedIcon />
                        )}
                      </SvgIcon>
                    </IconButton>
                  </Tooltip>
                </>
              </TableCell>
            </TableRow>
          ))
        )}
        <TableRow>
          <TablePagination
            width="100%"
            count={totalCount}
            page={page}
            labelRowsPerPage="Broj redova po stranici"
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleRowsPerPageChange}
            rowsPerPageOptions={[5, 10, 20, 25]}
            onPageChange={(_, newPage) => setPage(newPage)}
          />
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default List;
