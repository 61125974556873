import React, { useState, useEffect } from 'react';
import {
  Box,
  CardContent,
  Divider,
  Grid,
  TextField,
  FormHelperText,
  Typography,
  CardHeader,
  Card,
  Tabs,
  Tab,
  MenuItem,
  Paper,
} from '@material-ui/core';
import ErrorOutlineRoundedIcon from '@material-ui/icons/ErrorOutlineRounded';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { makeStyles } from '@material-ui/core/styles';
import { InputModal, FormSelect, dateFormatter, useAPI, dateNow } from '@shared/components';
import { accordanceDocumentSchema } from '@shared/partner-schemas';
import axios from '../../../utils/axios';
import useTheme from '../../../hooks/useTheme';
import { ContentBox, ErrorMessage } from './style';
import TransferList from '../TransferList';
import useAuthContext from '../../../hooks/useAuthContext';

export const useModalStyles = makeStyles(() => ({
  transferListGrid: {
    width: '100%',
  },
}));

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <Typography component="div" role="tabpanel" hidden={value !== index} {...other}>
      <Box p={1}>{children}</Box>
    </Typography>
  );
};

const DocumentsAccordanceModal = ({
  document,
  setDocument,
  visible,
  setVisible,
  sendSuccessAlertData,
  sendErrorAlertData,
  resetDocument,
  setRerender,
  documentLoading = false,
}) => {
  const {
    user: { username },
  } = useAuthContext();

  const [rightSideOrgList, setRightSideOrgList] = useState([]);
  const [orgListMounted, setOrgListMounted] = useState(false);
  const [signPeopleList, setSignedPeopleList] = useState([]);
  const [polListMounted, setPolListMounted] = useState(false);

  const [loading, setLoading] = useState(false);
  const [errorAlertOpen, setErrorAlertOpen] = useState(false);
  const [requestError, setRequestError] = useState();
  const [tabValue, setTabValue] = useState(0);
  const theme = useTheme();
  const classes = useModalStyles();

  const handleTabChange = (_, newValue) => {
    setTabValue(newValue);
  };

  const { register, handleSubmit, clearErrors, errors, reset, control } = useForm({
    resolver: yupResolver(accordanceDocumentSchema),
  });
  useEffect(() => {
    setLoading(false);
    setRequestError(null);
    clearErrors();
    reset();
  }, []);

  const handleClose = () => {
    clearErrors();
    setVisible(false);
    resetDocument();
    setRightSideOrgList([]);
    setSignedPeopleList([]);
    setOrgListMounted(false);
    setPolListMounted(false);
  };

  const handleAlertClose = () => setErrorAlertOpen(false);

  const onSubmit = async (values) => {
    const documentValues = {
      ...values,
      screen: 'EKRAN2',
    };
    const requestFormData = new FormData();
    requestFormData.append(
      'documentData',
      JSON.stringify({ id: document ? document?.id : null, documentValues }),
    );
    try {
      setLoading(true);
      let data = {};
      if (document) {
        const response = await axios.put(`documents/${document.id}`, requestFormData);
        data = response.data;
        if (!data) throw new Error();
      }
      if (orgListMounted)
        await axios.post(`/documents/other/organization`, {
          did: document?.id,
          username,
          rightSideOrgList,
        });

      if (polListMounted)
        await axios.post(`/documents/other/people`, {
          did: document?.id,
          username,
          signPeopleList,
        });
      sendSuccessAlertData('Uspješno izmijenjen dokument');
      if (setDocument) setDocument((prevState) => ({ ...prevState, ...data }));
      setRerender((prevState) => !prevState);
    } catch (error) {
      sendErrorAlertData(
        `${
          error?.response?.data?.details?.reason ||
          error?.response?.data?.error?.message ||
          'Došlo je do greške prilikom uređivanja dokumenta'
        }`,
      );
    } finally {
      setLoading(false);
      handleClose();
    }
  };

  const [documentTypes, acError, acLoading] = useAPI(() => {
    return axios.get(`document-types`).then(({ data }) => data);
  }, []);

  return (
    <InputModal
      object={document}
      createTitle="Kreiraj novi dokument"
      updateTitle={`Uredi dokument ${document?.id}`}
      createButtonText="Kreiraj dokument"
      updateButtonText="Uredi dokument"
      visible={visible}
      onSubmit={onSubmit}
      handleSubmit={handleSubmit}
      handleClose={handleClose}
      handleAlertClose={handleAlertClose}
      requestError={requestError}
      errorAlertOpen={errorAlertOpen}
      modalWidth="70%"
      loading={loading || documentLoading}
      showHeader
      theme={theme}
      disabledEdit={username !== document?.responsiblePersonUsername}
    >
      <ContentBox>
        {acError ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height={700}
            minWidth="100%"
          >
            <Box display="flex" justifyContent="center">
              <ErrorOutlineRoundedIcon fontSize="large" />
              <ErrorMessage>
                <Typography variant="h3">
                  {acError?.data?.details?.reason || 'Došlo je do greške'}
                </Typography>
              </ErrorMessage>
            </Box>
          </Box>
        ) : (
          <Box
            padding={`${theme.spacing(5)}px`}
            style={{ backgroundColor: theme.palette.background.dark }}
          >
            <Grid
              container
              spacing={6}
              direction="row"
              justifyContent="space-between"
              style={{ minHeight: 700 }}
              alignItems="stretch"
            >
              <Grid
                item
                container
                direction="column"
                spacing={5}
                justifyContent="flex-start"
                xs={5}
                style={{ padding: 0 }}
              >
                <Grid item>
                  <Card style={{ height: '100%' }} elevation={4}>
                    <CardHeader
                      title="Metapodaci"
                      titleTypographyProps={{ variant: 'subtitle2' }}
                    />
                    <Divider />
                    <CardContent>
                      <Box mt={4}>
                        <TextField
                          fullWidth
                          name="documentExtension"
                          id="documentExtension"
                          label="Ekstenzija"
                          inputRef={register}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          defaultValue={document?.documentExtension}
                          disabled
                        />
                      </Box>
                      <Box mt={1}>
                        <TextField
                          fullWidth
                          name="documentFilesize"
                          id="documentFilesize"
                          label="Veličina fajla (bajt)"
                          inputRef={register}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          defaultValue={document?.documentFilesize}
                          disabled
                        />
                      </Box>
                      <Box mt={1}>
                        <TextField
                          fullWidth
                          name="dateCreated"
                          id="dateCreated"
                          label="Datum kreiranja"
                          inputRef={register}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          defaultValue={
                            document
                              ? dateFormatter(document?.dateCreated, true)
                              : dateFormatter(dateNow(), true)
                          }
                          disabled
                        />
                      </Box>
                      <Box mt={1}>
                        <TextField
                          fullWidth
                          name="createdBy"
                          id="createdBy"
                          label="Korisnik kreirao"
                          inputRef={register}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          defaultValue={document?.createdBy || username}
                          disabled
                        />
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item>
                  <Card style={{ height: '100%' }} elevation={4}>
                    <CardHeader
                      title="Podaci o dokumentu"
                      titleTypographyProps={{ variant: 'subtitle2' }}
                    />
                    <Divider />
                    <CardContent>
                      <Box mt={4}>
                        <TextField
                          fullWidth
                          name="documentName"
                          id="documentName"
                          label="Naziv dokumenta"
                          inputRef={register}
                          control={control}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          disabled={username !== document?.responsiblePersonUsername}
                          defaultValue={document?.documentName}
                        />
                        {errors.documentName && (
                          <FormHelperText error>{errors.documentName.message}</FormHelperText>
                        )}
                      </Box>
                      <Box mt={1}>
                        <TextField
                          fullWidth
                          name="accordancePercentage"
                          id="accordancePercentage"
                          label="Procenat saglasnosti (1-100)"
                          inputRef={register}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          defaultValue={document?.accordancePercentage}
                          disabled={username !== document?.responsiblePersonUsername}
                        />
                        {errors.accordancePercentage && (
                          <FormHelperText error>
                            {errors.accordancePercentage.message}
                          </FormHelperText>
                        )}
                      </Box>
                      <Box mt={1}>
                        <FormSelect
                          error={!!errors.documentType}
                          fullWidth
                          required
                          name="documentType"
                          label="Tip dokumenta*"
                          id="documentType"
                          variant="standard"
                          defaultValue={document?.documentType}
                          placeholder="Izaberite tip dokumenta"
                          control={control}
                          loading={acLoading}
                          disabled
                        >
                          {documentTypes?.nodes?.map((documentType) => (
                            <MenuItem key={documentType?.id} value={documentType?.id}>
                              {documentType?.name}
                            </MenuItem>
                          ))}
                        </FormSelect>
                        {errors.documentType && (
                          <FormHelperText error>{errors.documentType.message}</FormHelperText>
                        )}
                      </Box>
                      <Box mt={1}>
                        <TextField
                          fullWidth
                          name="responsiblePersonName"
                          id="responsiblePersonName"
                          label="Odgovorna osoba"
                          inputRef={register}
                          control={control}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          defaultValue={document?.responsiblePersonName}
                          disabled
                        />
                      </Box>
                      <Box mt={1}>
                        <TextField
                          fullWidth
                          name="organization"
                          id="organization"
                          label="Organizacija"
                          inputRef={register}
                          control={control}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          defaultValue={document?.organization}
                          disabled
                        />
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
              <Grid item container direction="column" xs={7} style={{ padding: 0 }}>
                <Grid item>
                  <Card elevation={4}>
                    <Paper square>
                      <Tabs
                        value={tabValue}
                        variant="fullWidth"
                        indicatorColor="primary"
                        TabIndicatorProps={{
                          style: {
                            display: 'block',
                          },
                        }}
                        textColor="primary"
                        onChange={handleTabChange}
                        aria-label="radnici za uvid u dokument"
                      >
                        <Tab label="Radnici" />
                        <Tab label="Organizacije" />
                      </Tabs>
                    </Paper>
                    <Divider />
                    <CardContent>
                      <TabPanel value={tabValue} index={0}>
                        <Grid
                          container
                          direction="column"
                          spacing={2}
                          alignItems="center"
                          classes={classes.root}
                        >
                          <Grid item>
                            <Typography variant="body1" color="textSecondary">
                              Dodjela radnika za uvid (FYI)
                            </Typography>
                          </Grid>
                          <Grid item className={classes.transferListGrid}>
                            <TransferList
                              visible={tabValue === 0}
                              toggleFullHeight
                              rightSideList={signPeopleList}
                              setRightSideList={setSignedPeopleList}
                              listMounted={polListMounted}
                              setListMounted={setPolListMounted}
                              cardTitleLeft="Svi radnici"
                              cardTitleRight="Radnici za uvid"
                              routeNameLeft="workers"
                              routeNameRight="documents/other/people"
                              defaultConditionsRight={{ documentId: document?.id }}
                              defaultSorters={[
                                "replace(replace(replace(replace(replace(upper('name'), 'Č', 'C'), 'Ć', 'C'), 'Š', 'S'), 'Ž', 'Z'), 'Đ', 'DJ'), upper('surname') ASC",
                              ]}
                              defaultConditionsLeft={{}}
                              labelName="workerName"
                              keyNameLeft="id"
                              keyNameRight="workerId"
                            />
                          </Grid>
                        </Grid>
                      </TabPanel>
                      <TabPanel value={tabValue} index={1}>
                        <Grid container direction="column" spacing={2} alignItems="center">
                          <Grid item>
                            <Typography variant="body1" color="textSecondary">
                              Dodjela organizacija za uvid (FYI)
                            </Typography>
                          </Grid>
                          <Grid item className={classes.transferListGrid}>
                            <TransferList
                              visible={tabValue === 1}
                              toggleFullHeight
                              rightSideList={rightSideOrgList}
                              setRightSideList={setRightSideOrgList}
                              listMounted={orgListMounted}
                              setListMounted={setOrgListMounted}
                              cardTitleLeft="Sve organizacije"
                              cardTitleRight="Organizacije za uvid"
                              routeNameLeft="organisations"
                              routeNameRight="documents/other/organization"
                              defaultConditionsRight={{ documentId: document?.id }}
                              defaultSorters={[`id ASC`]}
                              defaultConditionsLeft={{}}
                              labelName="organizationName"
                              keyNameLeft="id"
                              keyNameRight="organizationId"
                            />
                          </Grid>
                        </Grid>
                      </TabPanel>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        )}
      </ContentBox>
    </InputModal>
  );
};

export default DocumentsAccordanceModal;
