import React, { useState, useEffect } from 'react';
import {
  Divider,
  Grid,
  Box,
  Typography,
  FormHelperText,
  MenuItem,
  CardHeader,
  Card,
  TextField,
  CardContent,
} from '@material-ui/core';
import { useForm } from 'react-hook-form';
import ErrorOutlineRoundedIcon from '@material-ui/icons/ErrorOutlineRounded';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  InputModal,
  FormSelect,
  useAPI
} from '@shared/components';
import { userDocumentTypeSchema } from '@shared/partner-schemas';
import axios from '../../../../../utils/axios';
import useTheme from '../../../../../hooks/useTheme';
import { ErrorMessage, ContentBox } from './style';

const Modal = ({
  selectedDocumentType,
  setSelectedDocumentType,
  visible,
  setVisible,
  sendSuccessAlertData,
  sendErrorAlertData,
  resetSelectedDocument,
  setRerender,
  username,
  disabled,
}) => {
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const { register, handleSubmit, clearErrors, errors, reset, control } = useForm({
    mode: 'onChange',
    resolver: yupResolver(userDocumentTypeSchema),
  });

  const [documentTypes = { nodes: [], totalCount: 0 }, errorDocTypes, loadingDocTypes] = useAPI(
    () => axios.get(`/document-types`).then(({ data }) => data),
    [],
  );

  const handleClose = () => {
    setVisible(false);
    setLoading(false);
    clearErrors();
    reset();
    resetSelectedDocument();
  };

  const onSubmit = async (values) => {
    setLoading(true);
    const documentType = {
      ...values,
      username,
    };

    try {
      const { data } = selectedDocumentType
        ? await axios.put(`scan-users/document-types/${selectedDocumentType.id}`, {
            documentType,
          })
        : await axios.post(`/scan-users/document-types`, {
            documentType,
          });
      if (!data) throw new Error();
      sendSuccessAlertData(
        `Uspješno ${selectedDocumentType ? 'izmijenjen' : 'unesen'} tip dokumenta`,
      );
      if (setSelectedDocumentType) setSelectedDocumentType({ ...values });
      setRerender((prevState) => !prevState);
      handleClose();
    } catch (error) {
      sendErrorAlertData(
        `${
          error?.response?.data?.details?.reason ||
          error?.response?.data?.error?.message ||
          `Došlo je do greške prilikom ${selectedDocumentType ? 'izmjene' : 'unosa'} tipa dokumenta`
        }`,
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(false);
    clearErrors();
    reset();
  }, []);

  return (
    <InputModal
      object={selectedDocumentType}
      updateTitle={`Uredi tip dokumenta ${selectedDocumentType?.id}`}
      updateButtonText="Uredi tip dokument"
      createTitle="Kreiraj tip dokumenta"
      createButtonText="Kreiraj tip dokumenta"
      visible={visible}
      onSubmit={onSubmit}
      handleSubmit={handleSubmit}
      handleClose={handleClose}
      modalWidth={600}
      loading={loading}
      theme={theme}
      disabledEdit={disabled}
    >
      {errorDocTypes ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minWidth="80vw"
          height={600}
        >
          <ContentBox display="flex" justifyContent="center">
            <ErrorOutlineRoundedIcon fontSize="large" />
            <ErrorMessage>
              <Typography variant="h3">
                {errorDocTypes?.data?.details?.reason || 'Došlo je do greške'}
              </Typography>
            </ErrorMessage>
          </ContentBox>
        </Box>
      ) : (
        <Box
          padding={`${theme.spacing(5)}px`}
          style={{ backgroundColor: theme.palette.background.dark }}
        >
          <Card style={{ height: '100%' }} elevation={4}>
            <CardHeader titleTypographyProps={{ variant: 'subtitle2' }} title="Osnovni podaci" />
            <Divider />
            <CardContent>
              <Grid container direction="column" spacing={5}>
                <Grid item>
                  <TextField
                    error={!!errors.ordNum}
                    fullWidth
                    label="Redni broj"
                    name="ordNum"
                    id="ordNum"
                    defaultValue={selectedDocumentType?.ordNum}
                    placeholder="Unesite redni broj tipa dokumenta"
                    inputRef={register}
                  />
                  {errors.ordNum && <FormHelperText error>{errors.ordNum.message}</FormHelperText>}
                </Grid>
                <Grid item>
                  <FormSelect
                    error={!!errors.documentType}
                    fullWidth
                    required
                    name="documentType"
                    label="Tip dokumenta*"
                    id="documentType"
                    variant="standard"
                    defaultValue={selectedDocumentType?.documentType}
                    placeholder="Izaberite tip dokumenta"
                    control={control}
                    loading={loadingDocTypes}
                  >
                    {documentTypes?.nodes?.map((documentType) => (
                      <MenuItem key={documentType?.id} value={documentType?.id}>
                        {documentType?.name}
                      </MenuItem>
                    ))}
                  </FormSelect>
                  {errors.documentType && (
                    <FormHelperText error>{errors.documentType.message}</FormHelperText>
                  )}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
      )}
    </InputModal>
  );
};

export default Modal;
