import React, { useState, useEffect } from 'react';
import {
  Divider,
  Grid,
  Box,
  CardHeader,
  Card,
  CardContent,
} from '@material-ui/core';
import { useForm } from 'react-hook-form';
// import ErrorOutlineRoundedIcon from '@material-ui/icons/ErrorOutlineRounded';
import { yupResolver } from '@hookform/resolvers/yup';
import { documentCommentsSchema } from '@shared/partner-schemas';
import { InputModal, FormTextArea } from '@shared/components';
import axios from '../../../../../utils/axios';
import useTheme from '../../../../../hooks/useTheme';

const Modal = ({
  selectedComment,
  setSelectedComment,
  visible,
  setVisible,
  sendSuccessAlertData,
  sendErrorAlertData,
  resetSelectedDocument,
  setRerender,
  parentDataId,
  disabled,
}) => {
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const { register, handleSubmit, clearErrors, errors, reset, } = useForm({
    mode: 'onChange',
    resolver: yupResolver(documentCommentsSchema),
  });

  const handleClose = () => {
    setVisible(false);
    setLoading(false);
    clearErrors();
    reset();
    resetSelectedDocument();
  };

  const onSubmit = async (values) => {
    setLoading(true);
    const comment = {
      ...values,
      documentId: parentDataId,
    };


    try {
      const { data } = selectedComment
        ? await axios.put(`comments/${selectedComment.id}`, {
          comment,
        })
        : await axios.post(`/comments`, {
          comment,
        });
      if (!data) throw new Error();
      sendSuccessAlertData(
        `Uspješno ${selectedComment ? 'izmijenjen' : 'unesen'} komentara`,
      );
      if (setSelectedComment) setSelectedComment({ ...values });
      setRerender((prevState) => !prevState);
      handleClose();
    } catch (error) {
      sendErrorAlertData(
        `${error?.response?.data?.details?.reason ||
        error?.response?.data?.error?.message ||
        `Došlo je do greške prilikom ${selectedComment ? 'izmjene' : 'unosa'} komentara`
        }`,
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(false);
    clearErrors();
    reset();
  }, []);

  return (
    <InputModal
      object={selectedComment}
      updateTitle={`Uredi komentar ${selectedComment?.id}`}
      updateButtonText="Uredi komentar"
      createTitle="Kreiraj komentar"
      createButtonText="Kreiraj komentar"
      visible={visible}
      onSubmit={onSubmit}
      handleSubmit={handleSubmit}
      handleClose={handleClose}
      modalWidth={600}
      loading={loading}
      theme={theme}
      disabledEdit={disabled}
    >
      <Box
        padding={`${theme.spacing(5)}px`}
        style={{ backgroundColor: theme.palette.background.dark }}
      >
        <Card style={{ height: '100%' }} elevation={4}>
          <CardHeader titleTypographyProps={{ variant: 'subtitle2' }} title="Komentar" />
          <Divider />
          <CardContent>
            <Grid container direction="column" spacing={5}>
              <Grid item>
                <FormTextArea
                  register={register}
                  object={selectedComment}
                  name="documentComment"
                  errors={errors}
                  maxLength={4000}
                  rows={7}
                />

              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </InputModal>
  );
};

export default Modal;
