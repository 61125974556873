import styled from 'styled-components';
import {
  InputLabel, Typography,
} from '@material-ui/core';

export const StyledLabel = styled(InputLabel)`
  font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
  color: black;
  padding-left: 10px;
`;

export const StyledTypography = styled(Typography)`
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
`;


