import * as yup from 'yup';

export const classificationMarkSchema = yup.object().shape({
  name: yup.string().required('Naziv je obavezno polje'),
  code: yup.string().required('Šifra je obavezno polje'),
  retentionPeriod: yup.string().nullable(),
  description: yup.string().nullable(),
  isValid: yup
    .string()
    .required('Važi je obavezno polje')
    .oneOf(['D', 'N', ''], 'Morate odabrati vrijednosti DA ili NE.'),
});

export const updateClassificationMarkSchema = yup.object().shape({
  name: yup.string().required('Naziv je obavezno polje'),
  retentionPeriod: yup.string().nullable(),
  description: yup.string().nullable(),
  isValid: yup
    .string()
    .required('Važi je obavezno polje')
    .oneOf(['D', 'N', ''], 'Morate odabrati vrijednosti DA ili NE.'),
});