import React, { useState, useEffect } from 'react';
import {
  Divider,
  Tooltip,
  Box,
  Card,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
  Paper,
  Checkbox,
  FormControlLabel,
  Switch,
  Grid,
  Button,
  MenuItem,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ErrorOutlineRoundedIcon from '@material-ui/icons/ErrorOutlineRounded';
import { Spinner, QuickSearch, dateFormatter, InputModal } from '@shared/components';
import useAPI from '@shared/components/src/hooks/useAPI';
import { ContentBox, ErrorMessage, TextField } from './style';
import { getSortDirection, handleSortChange } from '../../../../../utils/sortHelpers';

import useTheme from '../../../../../hooks/useTheme';
import axios from '../../../../../utils/axios';

const mainTableColumns = [
  { name: 'ID', value: 'id', align: 'left', width: '50' },
  {
    name: 'Tip dokumenta',
    value: 'documentTypeName',
    align: 'left',
    width: '100',
  },
  { name: 'Naziv', value: 'documentName', align: 'left', width: '95' },
  { name: 'Organizacija', value: 'organizationName', align: 'left', width: '90' },
];

const dateTableColumns = [
  { name: 'Datum kreiranja', value: 'dateCreated', align: 'left', width: '150' },
  {
    name: 'Datum posljednje izmjene',
    value: 'dateModified',
    align: 'left',
    width: '150',
  },
];

const getSearchText = (field) => {
  const map = {
    id: 'ID-u',
    documentTypeName: 'tipu dokumenta',
    documentName: 'nazivu',
    organizationName: 'organizaciji',
    dateCreated: 'datumu kreiranja',
    dateModified: 'datumu posljednje izmjene',
  };
  return map[field];
};

const EnhancedTableHead = ({ sorters, setSorters }) => {
  return (
    <TableHead>
      <TableRow width={1085}>
        <TableCell padding="checkbox" />
        {mainTableColumns.concat(dateTableColumns).map((column) => (
          <TableCell key={column.value} align={column.align} width={column.width}>
            <Tooltip enterDelay={300} title={`Sortiraj prema ${column.name}`}>
              <TableSortLabel
                active={!!getSortDirection(column.value, sorters)}
                direction={getSortDirection(column.value, sorters)}
                onClick={() => handleSortChange(column.value, sorters, setSorters)}
              >
                {column.name}
              </TableSortLabel>
            </Tooltip>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const EnhancedTable = ({
  rows,
  error,
  loading,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  sorters,
  setSorters,
  totalCount,
  selected,
  setSelected,
}) => {
  const [dense, setDense] = useState(true);

  const handleClick = (_, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleRowsPerPageChange = ({ target: { value } }) => {
    if (value * (page + 1) >= totalCount) setPage(Math.floor(totalCount / value));
    setRowsPerPage(value);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const emptyRows = rowsPerPage - rows?.length;

  return (
    <div>
      <Paper>
        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            aria-label="enhanced table"
          >
            <EnhancedTableHead sorters={sorters} setSorters={setSorters} />
            <TableBody>
              {error ? (
                <TableRow width={1085}>
                  <TableCell align="center" colSpan="100%" height={252}>
                    <Box display="flex" justifyContent="center">
                      <ErrorOutlineRoundedIcon fontSize="large" />
                      <ErrorMessage>
                        {error?.data?.details?.reason || 'Došlo je do greške'}
                      </ErrorMessage>
                    </Box>
                  </TableCell>
                </TableRow>
              ) : loading || !rows ? (
                <TableRow>
                  <TableCell align="center" colSpan="100%" height={240}>
                    <Spinner size={60} />
                  </TableCell>
                </TableRow>
              ) : rows?.length === 0 ? (
                <TableRow>
                  <TableCell align="center" colSpan="100%" height={240}>
                    <Typography>Nije pronađen niti jedan dokument</Typography>
                  </TableCell>
                </TableRow>
              ) : (
                <>
                  {rows.map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        onClick={(event) => handleClick(event, row.id)}
                        role="checkbox"
                        width="1500px"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            inputProps={{ 'aria-labelledby': labelId }}
                          />
                        </TableCell>
                        {mainTableColumns.map((column) => (
                          <TableCell key={`main ${column.value}`} width={column.width}>
                            {row[column.value]}
                          </TableCell>
                        ))}
                        {dateTableColumns.map((column) => (
                          <TableCell key={`main ${column.value}`} width={column.width}>
                            {dateFormatter(row[column.value])}
                          </TableCell>
                        ))}
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan="100%" />
                    </TableRow>
                  )}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          width="100%"
          count={totalCount}
          page={page}
          component="div"
          labelRowsPerPage="Broj redova po stranici"
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleRowsPerPageChange}
          rowsPerPageOptions={[5]}
          onPageChange={(_, newPage) => setPage(newPage)}
        />
      </Paper>
      {!dense && (
        <FormControlLabel
          control={<Switch checked={dense} onChange={handleChangeDense} />}
          label="Dense padding"
        />
      )}
    </div>
  );
};

const AddDocumentsModal = ({
  visible,
  setVisible,
  subjectId,
  sendSuccessAlertData,
  sendErrorAlertData,
  setRerender,
  triggerRerender,
  getLabelText,
}) => {
  const theme = useTheme();
  const [filterValues, setFilterValues] = useState({});
  const [filterField, setFilterField] = useState('id');
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [sorters, setSorters] = useState([]);
  const [selected, setSelected] = useState([]);

  const fields = [
    'id',
    'documentTypeName',
    'documentName',
    'organizationName',
    'dateCreated',
    'dateModified',
  ];


  const handleClose = () => setVisible(false);

  useEffect(() => {
    setLoading(false);
    setSelected([]);
  }, []);

  const onSubmit = async () => {
    setLoading(true);
    try {
      const { data } = await axios.post(`subjects/${subjectId}/unassigned-documents`, {
        documents: selected,
      });

      if (!data) throw new Error();

      setSelected([]);
      sendSuccessAlertData(`Uspješno dodani dokumenti`);

      setRerender((prevState) => !prevState);
      handleClose();
    } catch (error) {
      sendErrorAlertData(
        error?.response?.data?.details?.reason ||
        error?.response?.data?.error?.message ||
        'Došlo je do greške prilikom dodavanja dokumenata u predmet',
      );
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (event) => {
    setFilterField(event.target.value);
    if (Object.keys(filterValues)?.length !== 0) setFilterValues({});
  };

  const [unassignedDocuments = { nodes: [], totalCount: 0 }, clError, clLoading] = useAPI(
    () =>
      axios
        .get(`/documents-subjects`, {
          params: {
            limit: rowsPerPage,
            offset: page * rowsPerPage,
            conditions: filterValues,
            orderBy: [...sorters, 'ID DESC'],
          },
        })
        .then(({ data }) => data),
    [page, rowsPerPage, filterValues, sorters, triggerRerender],
  );

  return (
    <InputModal
      createTitle="Dodavanje dokumenata"
      createButtonText="Dodaj dokumente"
      filter
      filterTitle="Dodavanje dokumenata"
      visible={visible}
      onSubmit={onSubmit}
      handleSubmit={() => {}}
      handleClose={handleClose}
      modalWidth="85%"
      loading={loading}
      filterSubmitText="Dodaj dokumente"
      showHeader
      theme={theme}
      filterButtonDisabled={selected?.length === 0}
    >
      <ContentBox>
        <Card>
          <CardHeader
            title={
              <Grid container spacing={5} direction="row" alignItems="center">
                <Grid item xs={2}>
                  <TextField
                    fullWidth
                    id="filterField"
                    select
                    label="Pretraži po"
                    value={filterField}
                    onChange={handleChange}
                    variant="outlined"
                  >
                    {fields.map((option) => (
                      <MenuItem key={option} value={option}>
                        {getLabelText(option)}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={10}>
                  <QuickSearch
                    name={filterField}
                    placeholder={`Brza pretraga po ${getSearchText(filterField)}`}
                    setFilters={(f) => setFilterValues(() => f({}))}
                    mainFilter={filterValues}
                    setPage={setPage}
                    type={filterField.includes('date') ? 'date' : 'text'}
                  />
                </Grid>
              </Grid>
            }
            action={
              <Grid container direction="row" spacing={5} alignItems="center">
                <Grid item>
                  <Typography>{`Odabrano dokumenata: ${selected?.length}`}</Typography>
                </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => setSelected([])}
                    startIcon={<CloseIcon fontSize="small" />}
                    disabled={selected?.length === 0}
                  >
                    <Box fontSize="small">Ukloni sve odabrane dokumente</Box>
                  </Button>
                </Grid>
              </Grid>
            }
          />
          <EnhancedTable
            rows={unassignedDocuments.nodes}
            totalCount={unassignedDocuments.totalCount}
            error={clError}
            loading={clLoading}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            sorters={sorters}
            setSorters={setSorters}
            selected={selected}
            setSelected={setSelected}
          />
        </Card>
      </ContentBox>
      <Divider />
    </InputModal>
  );
};

export default AddDocumentsModal;
