import React, { useState, useEffect } from 'react';
import {
  Divider,
  Grid,
  Box,
  TextField,
  FormHelperText,
  Card,
  CardHeader,
  CardContent,
  MenuItem,
} from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { InputModal, FormSelect } from '@shared/components';
import { classificationMarkSchema, updateClassificationMarkSchema } from '@shared/partner-schemas';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from '../../../utils/axios';
import useTheme from '../../../hooks/useTheme';

const ClassMarkModal = ({
  classMark,
  setClassMark,
  visible,
  setVisible,
  sendSuccessAlertData,
  resetClassMark,
  setRerender,
  sendErrorAlertData,
  detailed = false,
}) => {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit, clearErrors, errors, reset, control } = useForm({
    resolver: yupResolver(!classMark ? classificationMarkSchema : updateClassificationMarkSchema),
  });

  const handleClose = () => {
    setVisible(false);
    if (!detailed) resetClassMark();
  };

  const onSubmit = async (values) => {
    setLoading(true);
    try {
      const { data } = classMark
        ? await axios.put(`classification-marks/${classMark.id}`, {
            classificationMark: values,
          })
        : await axios.post(`classification-marks`, {
            classificationMark: values,
          });
      if (!data) throw new Error();
      sendSuccessAlertData(
        `Uspješno ${classMark ? 'izmijenjena' : 'kreirana'} klassifikaciona oznaka`,
      );
      if (setClassMark) setClassMark((prevState) => ({ ...prevState, ...data }));
      setRerender((prevState) => !prevState);
      handleClose();
    } catch (error) {
      sendErrorAlertData(
        `${
          error?.response?.data?.details?.reason ||
          error?.response?.data?.error?.message ||
          `Došlo je do greške prilikom ${classMark ? 'uređivanja' : 'unosa'} `
        }`,
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(false);
    clearErrors();
    reset();
  }, []);

  return (
    <InputModal
      object={classMark}
      createTitle="Kreiraj klasifikacionu oznaku"
      updateTitle={`Uredi klasifikacionu oznaku ${classMark?.code}`}
      createButtonText="Kreiraj klasifikacionu oznaku"
      updateButtonText="Uredi klasifikacionu oznaku"
      visible={visible}
      onSubmit={onSubmit}
      handleSubmit={handleSubmit}
      handleClose={handleClose}
      modalWidth={600}
      loading={loading}
      theme={theme}
    >
      <Box
        padding={`${theme.spacing(5)}px`}
        style={{ backgroundColor: theme.palette.background.dark }}
      >
        <Grid container style={{ minHeight: 310 }} spacing={6} direction="row" alignItems="stretch">
          <Grid item xs={12}>
            <Card elevation={4} style={{ height: '100%' }}>
              <CardHeader title="Osnovni podaci" titleTypographyProps={{ variant: 'subtitle2' }} />
              <Divider />
              <CardContent>
                <Grid container direction="column" spacing={5}>
                  <Grid item>
                    <TextField
                      error={!!errors.code}
                      fullWidth
                      label="Šifra*"
                      name="code"
                      id="code"
                      defaultValue={classMark?.code}
                      placeholder="Unesite šifru"
                      inputRef={register}
                      disabled={!!classMark}
                    />
                    {errors.code && <FormHelperText error>{errors.code.message}</FormHelperText>}
                  </Grid>
                  <Grid item>
                    <TextField
                      error={!!errors.name}
                      fullWidth
                      label="Naziv*"
                      name="name"
                      id="name"
                      defaultValue={classMark?.name}
                      placeholder="Unesite naziv"
                      inputRef={register}
                    />
                    {errors.name && <FormHelperText error>{errors.name.message}</FormHelperText>}
                  </Grid>
                  <Grid item>
                    <FormSelect
                      id="isValid"
                      name="isValid"
                      label="Važi*"
                      required
                      error={!!errors.isValid}
                      defaultValue={classMark?.isValid}
                      control={control}
                      fullWidth
                    >
                      <MenuItem value="D">DA</MenuItem>
                      <MenuItem value="N">NE</MenuItem>
                    </FormSelect>
                    {errors.isValid && (
                      <FormHelperText error>{errors.isValid.message}</FormHelperText>
                    )}
                  </Grid>
                  <Grid item>
                    <TextField
                      error={!!errors.retentionPeriod}
                      fullWidth
                      label="Rok čuvanja"
                      name="retentionPeriod"
                      id="retentionPeriod"
                      defaultValue={classMark?.retentionPeriod}
                      placeholder="Unesite rok čuvanja"
                      inputRef={register}
                    />
                    {errors.retentionPeriod && (
                      <FormHelperText error>{errors.retentionPeriod.message}</FormHelperText>
                    )}
                  </Grid>
                  <Grid item>
                    <TextField
                      error={!!errors.description}
                      fullWidth
                      label="Opis"
                      name="description"
                      id="description"
                      defaultValue={classMark?.description}
                      placeholder="Unesite opis"
                      inputRef={register}
                    />
                    {errors.description && (
                      <FormHelperText error>{errors.description.message}</FormHelperText>
                    )}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </InputModal>
  );
};

export default ClassMarkModal;
