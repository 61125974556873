import styled from 'styled-components'
import { Chip as MuiChip } from '@material-ui/core';


export const ErrorMessage = styled.span`
  font-size: 24px;
  padding-left: ${({ theme }) => theme.spacing(2)}px;
`;

export const Chip = styled(MuiChip)`
  min-width: 160px;
`;