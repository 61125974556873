import React, { useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Grid,
  Divider,
  Breadcrumbs,
  Typography,
  Link,
} from '@material-ui/core';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';
import FilterListIcon from '@material-ui/icons/FilterList';
import {
  FilterChip,
  QuickSearch,
  useAPI,
  dateFormatter,
  VerticalBoxContainer,
  PageContentWrapper,
} from '@shared/components';
import PageHeader from '../../components/PageHeader';
import ExcelExportButton from '../../components/ExcelExportButton';
import { handleChipDelete } from '../../utils/filterHelpers';
import useTheme from '../../hooks/useTheme';
import axios from '../../utils/axios';
import Table from './Table';
import Filter from './Filter';
import WorkerOrganizationModal from './TableModal';

const fields = [
  {
    label: 'ID',
    value: 'id',
  },
  {
    label: 'Tip dokumenta',
    value: 'documentTypeName',
  },
  {
    label: 'Broj stranica',
    value: 'documentNoPages',
  },
  {
    label: 'Ekstenzija',
    value: 'documentExtension',
  },
  {
    label: 'Veličina fajla (bajt)',
    value: 'documentFilesize',
  },
  {
    label: 'Datum kreiranja',
    value: 'dateCreated',
    type: 'dateTime',
  },
  {
    label: 'Korisnik kreirao',
    value: 'createdBy',
  },
  {
    label: 'Trenutni procenat za usaglašavanje',
    value: 'currentPercentage',
  },
  {
    label: 'Potrebni procenat za usaglašavanje',
    value: 'accordancePercentage',
  },
  {
    label: 'Odgovorna osoba',
    value: 'responsiblePersonName',
  },
  { label: 'Status', value: 'statusName' },
];

const getLabelText = (field) => {
  const map = {
    id: 'ID',
    statusName: 'Status',
    documentTypeName: 'Tip dokumenta',
    documentNoPages: 'Broj stranica',
    documentExtension: 'Ekstenzija',
    documentFilesize: 'Veličina fajla (bajt)',
    dateCreated: 'Datum kreiranja',
    createdBy: 'Korisnik kreirao',
    documentAccordance: 'Procenat za usaglašavanje',
    docProtocolNumber: 'Broj protokola',
    responsiblePerson: 'Odgovorna osoba',
  };
  return map[field];
};

const getChipValue = (chip, filters) => {
  if (chip === 'dateCreated') return dateFormatter(filters[chip]);
  return filters[chip];
};

const DocumentsOverview = ({ documentSort = 'U' }) => {
  const theme = useTheme();
  const history = useHistory();
  const [page, setPage] = useState(0);
  const [filters, setFilters] = useState({});
  const [sorters, setSorters] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [filterModalVisible, setFilterModalVisible] = useState(false);
  const [workerOrgModalVisible, setWorkerOrgModalVisible] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const fileName = `${documentSort === 'U' ? 'ulazni': 'izlazni'}-dokumenti-pregled`;


  const [documents = { nodes: [], totalCount: 0 }, error, loading] = useAPI(
    () =>
      axios
        .get(`documents-overview`, {
          params: {
            limit: rowsPerPage,
            offset: page * rowsPerPage,
            conditions: { ...filters, documentSort, screen: 'EKRAN3' },
            orderBy: [...sorters, '"id" DESC'],
          },
        })
        .then(({ data }) => data),
    [page, rowsPerPage, filters, sorters],
  );

  const handleFilterChange = (filterValues) => {
    setPage(0);
    setFilters({ ...filterValues });
  };

  return (
    <VerticalBoxContainer>
      <Filter
        currentValues={filters}
        visible={filterModalVisible}
        setVisible={setFilterModalVisible}
        handleFilterChange={handleFilterChange}
        getLabelText={getLabelText}
        getChipValue={getChipValue}
        setFilters={setFilters}
        setPage={setPage}
      />
      <WorkerOrganizationModal
        document={selectedDocument}
        visible={workerOrgModalVisible}
        setVisible={setWorkerOrgModalVisible}
        resetDocument={() => setSelectedDocument(undefined)}
        title="Radnici/Organizacije dodijeljeni dokumentu na usaglašavanje"
        buttonText="Odustani"
      />
      <PageHeader title="Pregled dokumenata" />
      <PageContentWrapper>
        <Breadcrumbs style={{ paddingBottom: `${theme.spacing(5)}px` }}>
          <Link variant="body1" color="inherit" to="/" component={RouterLink}>
            Početna
          </Link>
          <Typography variant="body1" color="textPrimary">
            Pregled dokumenata
          </Typography>
        </Breadcrumbs>
        <Card>
          <CardHeader
            title={
              <QuickSearch
                name="id"
                placeholder="Brza pretraga po ID-u"
                setFilters={setFilters}
                mainFilter={filters}
                setPage={setPage}
              />
            }
            action={
              <Grid container spacing={4}>
                <Grid item>
                  <Button
                    color="primary"
                    disabled={Object.keys(filters).filter((k) => filters[k])?.length === 0}
                    startIcon={<CloseIcon fontSize="small" />}
                    onClick={() => setFilters({})}
                  >
                    <Box fontSize="small">Ukloni sve filtere</Box>
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<FilterListIcon fontSize="small" />}
                    onClick={() => setFilterModalVisible(true)}
                  >
                    <Box fontSize="small">Filteri</Box>
                  </Button>
                </Grid>
                <Grid item>
                  <ExcelExportButton
                    totalCount={documents.totalCount}
                    fields={fields}
                    sorters={[...sorters, '"id" DESC']}
                    filters={{ ...filters, documentSort, screen: 'EKRAN3' }}
                    variant="contained"
                    routeName="documents"
                    fileName={fileName}
                  />
                </Grid>
              </Grid>
            }
          />
          <Divider />
          {Object.keys(filters).filter((k) => filters[k])?.length !== 0 && (
            <>
              <Box ml={2} p={2} display="flex" alignItems="center" flexWrap="wrap">
                {Object.keys(filters).map(
                  (chip) =>
                    filters[chip] !== undefined && (
                      <FilterChip
                        color="primary"
                        variant="outlined"
                        key={chip}
                        label={`${getLabelText(chip)}: ${getChipValue(chip, filters)}`}
                        onDelete={() => handleChipDelete(chip, setFilters, setPage)}
                      />
                    ),
                )}
              </Box>
            </>
          )}
          <Table
            loading={loading}
            error={error}
            documents={documents.nodes}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            totalCount={documents.totalCount}
            sorters={sorters}
            setSorters={setSorters}
            history={history}
            selectedDocument={selectedDocument}
            setSelectedDocument={setSelectedDocument}
            setWorkerOrgModalVisible={setWorkerOrgModalVisible}
          />
        </Card>
      </PageContentWrapper>
    </VerticalBoxContainer>
  );
};

export default DocumentsOverview;
