import React, { useEffect } from 'react';
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  Tooltip,
  TablePagination,
  Box,
  Typography,
  SvgIcon,
  Button,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import ErrorOutlineRoundedIcon from '@material-ui/icons/ErrorOutlineRounded';
import { TableSkeleton, dateFormatterTime, dateFormatterDate } from '@shared/components';
import AttributeField from '../../../../../components/AttributeField';
import { getSortDirection, handleSortChange } from '../../../../../utils/sortHelpers';
import axios from '../../../../../utils/axios';
import useTheme from '../../../../../hooks/useTheme';
import { ErrorMessage } from './style';

const DocumentDetailsTable = ({
  attributes,
  page,
  setPage,
  totalCount,
  rowsPerPage,
  setRowsPerPage,
  error,
  loading,
  sorters,
  setSorters,
  methods,
  setDialogVisible,
  handleTabelReset,
  // setDeleteModalVisible,
}) => {
  const theme = useTheme();
  const handleRowsPerPageChange = ({ target: { value } }) => {
    if (value * (page + 1) >= totalCount) setPage(Math.floor(totalCount / value));
    setRowsPerPage(value);
  };

  const isEdited = attributes?.some((attribute) => attribute?.allowedToEdit === 'D');

  useEffect(() => {
    if (attributes) methods.reset();
  }, [JSON.stringify(attributes)]);

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell width={50}>
            <Tooltip enterDelay={300} title="Sortiraj prema ID-u" placement="bottom-start">
              <TableSortLabel
                active={!!getSortDirection('id', sorters)}
                direction={getSortDirection('id', sorters)}
                onClick={() => handleSortChange('id', sorters, setSorters)}
              >
                ID
              </TableSortLabel>
            </Tooltip>
          </TableCell>
          <TableCell width={50}>
            <Tooltip
              enterDelay={300}
              title="Sortiraj prema atributu dokumenta"
              placement="bottom-start"
            >
              <TableSortLabel
                active={!!getSortDirection('attributeName', sorters)}
                direction={getSortDirection('attributeName', sorters)}
                onClick={() => handleSortChange('attributeName', sorters, setSorters)}
              >
                Atribut (Metapodatak)
              </TableSortLabel>
            </Tooltip>
          </TableCell>
          <TableCell width={50}>
            <Tooltip
              enterDelay={300}
              title="Sortiraj prema vrijednosti atributa"
              placement="bottom-start"
            >
              <TableSortLabel
                active={!!getSortDirection('valueDisplayed', sorters)}
                direction={getSortDirection('valueDisplayed', sorters)}
                onClick={() => handleSortChange('valueDisplayed', sorters, setSorters)}
              >
                Vrijednost&nbsp;
                {isEdited && (
                  <SvgIcon fontSize="small">
                    <EditIcon />
                  </SvgIcon>
                )}
              </TableSortLabel>
            </Tooltip>
          </TableCell>

          <TableCell width={50}>
            <Tooltip
              enterDelay={300}
              title="Sortiraj prema datumu kreiranja"
              placement="bottom-start"
            >
              <TableSortLabel
                active={!!getSortDirection('dateCreated', sorters)}
                direction={getSortDirection('dateCreated', sorters)}
                onClick={() => handleSortChange('dateCreated', sorters, setSorters)}
              >
                Datum kreiranja
              </TableSortLabel>
            </Tooltip>
          </TableCell>
          <TableCell width={50}>
            <Tooltip enterDelay={300} title="Sortiraj prema korisniku" placement="bottom-start">
              <TableSortLabel
                active={!!getSortDirection('createdBy', sorters)}
                direction={getSortDirection('createdBy', sorters)}
                onClick={() => handleSortChange('createdBy', sorters, setSorters)}
              >
                Korisnik kreirao
              </TableSortLabel>
            </Tooltip>
          </TableCell>

          <TableCell width={170}>
            {Object.keys(methods.formState.dirtyFields).length !== 0 && !loading && (
              <Box display="flex" flexDirection="row-reverse">
                <Button
                  variant="outlined"
                  onClick={() => setDialogVisible(true)}
                  style={{
                    marginLeft: 4,
                    borderColor: theme.palette.success.main,
                    color: theme.palette.success.main,
                  }}
                >
                  Sačuvaj
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleTabelReset}
                  style={{ borderColor: theme.palette.error.main, color: theme.palette.error.main }}
                >
                  Poništi
                </Button>
              </Box>
            )}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {error ? (
          <TableRow>
            <TableCell align="center" colSpan="100%" height={5 * 70.33}>
              <Box display="flex" justifyContent="center">
                <ErrorOutlineRoundedIcon fontSize="large" />
                <ErrorMessage>{error?.data?.details?.reason || 'Došlo je do greške'}</ErrorMessage>
              </Box>
            </TableCell>
          </TableRow>
        ) : loading || !attributes ? (
          <TableRow>
            <TableCell align="center" colSpan="100%" style={{ paddingTop: '0' }}>
              <TableSkeleton size={rowsPerPage} />
            </TableCell>
          </TableRow>
        ) : attributes?.length === 0 ? (
          <TableRow>
            <TableCell align="center" colSpan="100%" height={80}>
              <Typography>Nije pronađen niti jedan atribut dokumenta</Typography>
            </TableCell>
          </TableRow>
        ) : (
          attributes?.map((documentAttribute) => (
            <TableRow key={documentAttribute.id}>
              <TableCell width={50}> {documentAttribute.id}</TableCell>
              <TableCell width={50}> {documentAttribute.attributeName}</TableCell>
              <TableCell width={50}>
                <Box width={200}>
                  {documentAttribute.allowedToEdit === 'D' ? (
                    <AttributeField
                      className="attribute-field"
                      name={`valueDisplayed-${documentAttribute.id}`}
                      id={`valueDisplayed-${documentAttribute.id}`}
                      defaultValue={documentAttribute?.valueDisplayed}
                      placeholder={documentAttribute?.hint}
                      attributeObject={documentAttribute}
                      isUpdating={documentAttribute}
                      errors={methods.errors}
                      register={methods.register}
                      control={methods.control}
                      axios={axios}
                      dirtyFields={methods.formState.dirtyFields}
                    />
                  ) : (
                    documentAttribute?.valueDisplayed
                  )}
                </Box>
              </TableCell>
              <TableCell width={50}>
                {dateFormatterDate(documentAttribute.dateCreated)}
                <Typography variant="h6" color="textSecondary">
                  {dateFormatterTime(documentAttribute.dateCreated)}
                </Typography>
              </TableCell>
              <TableCell width={50}> {documentAttribute.createdBy}</TableCell>
              <TableCell width={100} align="right" />
            </TableRow>
          ))
        )}
        <TableRow>
          <TablePagination
            width="100%"
            count={totalCount}
            page={page}
            labelRowsPerPage="Broj redova po stranici"
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleRowsPerPageChange}
            rowsPerPageOptions={[5, 10, 20, 25]}
            onPageChange={(_, newPage) => setPage(newPage)}
          />
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default DocumentDetailsTable;
