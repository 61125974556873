import React, { useState, useEffect } from 'react';
import {
  Divider,
  Grid,
  Box,
  TextField,
  FormHelperText,
  Card,
  CardHeader,
  CardContent,
  MenuItem,
} from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { InputModal, FormSelect } from '@shared/components';

import { bookTypeSchema, updateBookTypeSchema } from '@shared/partner-schemas';
import axios from '../../../utils/axios';
import useTheme from '../../../hooks/useTheme';

const BookTypesModal = ({
  bookType,
  setBookType,
  visible,
  setVisible,
  sendSuccessAlertData,
  sendErrorAlertData,
  setRerender,
  resetBookType,
}) => {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [requestError, setRequestError] = useState({});
  const { handleSubmit, errors, reset, clearErrors, register, control } = useForm({
    resolver: yupResolver(!bookType ? bookTypeSchema : updateBookTypeSchema),
  });

  const onSubmit = async ({ code, name, isValid, ownSubjectAllowed }) => {
    setLoading(true);
    try {
      const data = bookType
        ? await axios.put(`book-types/${bookType.id}`, {
            bookType: { code, name, isValid, ownSubjectAllowed },
          })
        : await axios.post(`book-types`, {
            bookType: { code, name, isValid, ownSubjectAllowed },
          });
      if (!data) throw new Error();
      sendSuccessAlertData(`Uspješno ${bookType ? 'izmijenjena' : 'unesena'} vrsta knjige`);
      if (bookType) setBookType(data.data);
      setRerender((prevState) => !prevState);
      handleClose();
    } catch (error) {
      sendErrorAlertData(
        `${
          error?.response?.data?.details?.reason ||
          error?.response?.data?.error?.message ||
          `Došlo je do greške prilikom ${bookType ? 'uređivanja' : 'unosa'} vrste knjige`
        }`,
      );
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setVisible(false);
    resetBookType();
  };

  useEffect(() => {
    setLoading(false);
    setRequestError(null);
    clearErrors();
    reset();
  }, []);

  return (
    <InputModal
      object={bookType}
      createTitle="Unos nove vrste knjige"
      updateTitle={`Uredi vrstu knjige: ${bookType?.code}`}
      createButtonText="Kreiraj vrstu knjige"
      updateButtonText="Uredi vrstu knjige"
      visible={visible}
      onSubmit={onSubmit}
      handleSubmit={handleSubmit}
      handleClose={handleClose}
      requestError={requestError}
      modalWidth={600}
      loading={loading}
      theme={theme}
    >
      <Box
        padding={`${theme.spacing(5)}px`}
        style={{ backgroundColor: theme.palette.background.dark }}
      >
        <Grid
          container
          style={{ minHeight: 250 }}
          spacing={6}
          direction="column"
          alignItems="stretch"
        >
          <Grid item>
            <Card elevation={4} style={{ height: '100%' }}>
              <CardHeader title="Osnovni podaci" titleTypographyProps={{ variant: 'subtitle2' }} />
              <Divider />
              <CardContent>
                <Grid container direction="column" spacing={6}>
                  <Grid item>
                    <TextField
                      fullWidth
                      error={!!errors.code}
                      label="Šifra*"
                      defaultValue={bookType?.code}
                      name="code"
                      id="code"
                      placeholder="Unesite šifru"
                      inputRef={register}
                      disabled={!!bookType}
                    />
                    {errors.code && <FormHelperText error>{errors.code.message}</FormHelperText>}
                  </Grid>
                  <Grid item>
                    <TextField
                      fullWidth
                      error={!!errors.name}
                      label="Naziv*"
                      defaultValue={bookType?.name}
                      name="name"
                      id="name"
                      placeholder="Unesite naziv"
                      inputRef={register}
                    />
                    {errors.name && <FormHelperText error>{errors.name.message}</FormHelperText>}
                  </Grid>
                  <Grid item>
                    <FormSelect
                      id="isValid"
                      name="isValid"
                      label="Važi*"
                      required
                      error={!!errors.isValid}
                      defaultValue={bookType?.isValid || 'D'}
                      control={control}
                      fullWidth
                    >
                      <MenuItem value="D">DA</MenuItem>
                      <MenuItem value="N">NE</MenuItem>
                    </FormSelect>
                    {errors.isValid && (
                      <FormHelperText error>{errors.isValid.message}</FormHelperText>
                    )}
                  </Grid>
                  <Grid item>
                    <FormSelect
                      id="ownSubjectAllowed"
                      name="ownSubjectAllowed"
                      label="Dozvoljen vlastiti predmet"
                      required
                      error={!!errors.ownSubjectAllowed}
                      defaultValue={bookType?.ownSubjectAllowed || 'N'}
                      control={control}
                      fullWidth
                    >
                      <MenuItem value="D">DA</MenuItem>
                      <MenuItem value="N">NE</MenuItem>
                    </FormSelect>
                    {errors.ownSubjectAllowed && (
                      <FormHelperText error>{errors.ownSubjectAllowed.message}</FormHelperText>
                    )}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </InputModal>
  );
};

export default BookTypesModal;
