import React, { useState, useEffect } from 'react';
import {
  Box,
  CardContent,
  Divider,
  Grid,
  TextField,
  FormHelperText,
  Typography,
  CardHeader,
  Card,
  MenuItem,
} from '@material-ui/core';
// import { useParams } from 'react-router';
import { Alert } from '@material-ui/lab';
import mime from 'mime-types';
import ErrorOutlineRoundedIcon from '@material-ui/icons/ErrorOutlineRounded';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { documentResOrgSchema } from '@shared/partner-schemas';

import {
  FormAutocomplete,
  FormSelect,
  FormFileUpload,
  InputModal,
  useAPI,
  dateFormatter,
  dateNow,
} from '@shared/components';

import axios from '../../../utils/axios';
import useTheme from '../../../hooks/useTheme';
import { ContentBox, ErrorMessage } from './style';
import useAuthContext from '../../../hooks/useAuthContext';

const FooterInfo = () => {
  return (
    <Alert
      severity="warning"
      style={{ fontSize: '.8em', paddingBottom: '0', paddingTop: '0', margin: '0' }}
    >
      Dodavanjem odgovorne osobe dokument se šalje na ekran &quot;Usaglašavanje dokumenata&quot; i
      nije dostupan na ovom ekranu.
    </Alert>
  );
};
const DocumentsModal = ({
  document,
  setDocument,
  visible,
  setVisible,
  sendSuccessAlertData,
  sendErrorAlertData,
  resetDocument,
  setRerender,
  disabled,
  documentLoading = false,
}) => {
  const { user } = useAuthContext();
  const [loading, setLoading] = useState(false);
  const [errorAlertOpen, setErrorAlertOpen] = useState(false);
  const [requestError, setRequestError] = useState();
  const theme = useTheme();

  // Document Upload
  const [selectedFileName, setSelectedFileName] = useState('');
  const [selectedFile, setSelectedFile] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);

  const defaultData = { nodes: [], totalCount: 0 };

  const { register, handleSubmit, clearErrors, errors, reset, control, setValue } = useForm({
    mode: 'onChange',
    resolver: yupResolver(documentResOrgSchema),
  });
  const responsiblePersonSelected = useWatch({
    control,
    name: 'responsiblePerson',
    defaultValue: {},
  });

  useEffect(() => {
    setLoading(false);
    setRequestError(null);
    clearErrors();
    reset();
  }, []);

  const handleClose = () => {
    clearErrors();
    setVisible(false);
    setSelectedFile(null);
    setIsFilePicked(false);
    setSelectedFileName('');
    resetDocument();
  };

  const handleAlertClose = () => setErrorAlertOpen(false);

  const onSubmit = async ({ responsiblePerson, ...values }) => {
    const fileExtension = mime.extension(mime.contentType(selectedFileName));
    const documentValues = {
      responsiblePerson: responsiblePerson ? responsiblePerson.id : null,
      ...values,
      documentName: selectedFile && selectedFileName.split('.').slice(0, -1).join('.'),
      documentFilesize: selectedFile && selectedFile?.size,
      documentExtension: selectedFile && `.${fileExtension}`,
      screen: 'EKRAN1',
    };

    const requestFormData = new FormData();
    requestFormData.append(
      'documentData',
      JSON.stringify({ id: document ? document?.id : null, documentValues }),
    );
    if (isFilePicked) requestFormData.append('file', selectedFile);
    if (!document && (!isFilePicked || !fileExtension)) {
      sendErrorAlertData('Potrebno je da priložite/učitate dokument.');
      return;
    }

    try {
      setLoading(true);
      const { data } = document
        ? await axios.put(`documents/${document.id}`, requestFormData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
        : await axios.post(`documents`, requestFormData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          });
      if (!data) throw new Error();
      sendSuccessAlertData(`Uspješno ${document ? 'izmijenjen' : 'dodan'} dokument`);
      if (setDocument) setDocument((prevState) => ({ ...prevState, ...data }));
      setRerender((prevState) => !prevState);
    } catch (error) {
      sendErrorAlertData(
        `${
          error?.response?.data?.details?.reason ||
          error?.response?.data?.error?.message ||
          `Došlo je do greške prilikom ${document ? 'uređivanja' : 'unosa'} dokumenta`
        }`,
      );
    } finally {
      setLoading(false);
      handleClose();
    }
  };

  useEffect(() => {
    if (selectedFile) {
      setValue('documentFilesize', selectedFile.size);
      setValue('documentExtension', `.${selectedFileName.split('.').pop()}`);
    } else {
      setValue('documentFilesize', '');
      setValue('documentExtension', '');
    }
  }, [selectedFile]);

  // const [documentTypes, acError, acLoading] = useAPI(() => {
  //   return axios.get(`document-types`).then(({ data }) => data);
  // }, []);

  const [
    acData = {
      documentTypes: defaultData,
      clients: defaultData,
    },
    acError,
    acLoading,
  ] = useAPI(() => {
    return document && visible
      ? Promise.all([
          axios.get(`document-types`, {
            params: {
              conditions: {},
            },
          }),
          axios.get(`workers`, {
            params: {
              limit: 1,
              conditions: { id: document?.responsiblePerson },
            },
          }),
        ]).then(([{ data: documentTypes }, { data: clients }]) => ({
          documentTypes,
          clients,
        }))
      : Promise.resolve(() => defaultData);
  }, [document, visible]);

  return (
    <InputModal
      object={document}
      createTitle="Kreiraj novi dokument"
      updateTitle={`Uredi dokument ${document?.id}`}
      createButtonText="Kreiraj dokument"
      updateButtonText="Uredi dokument"
      visible={visible}
      onSubmit={onSubmit}
      handleSubmit={handleSubmit}
      handleClose={handleClose}
      handleAlertClose={handleAlertClose}
      requestError={requestError}
      errorAlertOpen={errorAlertOpen}
      modalWidth="55vw"
      loading={loading || documentLoading}
      showHeader
      theme={theme}
      disabled={disabled}
      footerInfo={Object.keys(responsiblePersonSelected || {}).length && <FooterInfo />}
    >
      <ContentBox>
        {acError ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height={610}
            minWidth="100%"
          >
            <Box display="flex" justifyContent="center">
              <ErrorOutlineRoundedIcon fontSize="large" />
              <ErrorMessage>
                <Typography variant="h3">
                  {acError?.data?.details?.reason || 'Došlo je do greške'}
                </Typography>
              </ErrorMessage>
            </Box>
          </Box>
        ) : (
          <Grid container>
            <Grid item container spacing={6}>
              <Grid item xs={6}>
                <Card style={{ height: '100%' }} elevation={4}>
                  <CardHeader
                    title="Osnovni podaci"
                    titleTypographyProps={{ variant: 'subtitle2' }}
                  />
                  <Divider />
                  <CardContent>
                    <Box>
                      <FormSelect
                        error={!!errors.documentType}
                        fullWidth
                        required
                        name="documentType"
                        label="Tip dokumenta*"
                        id="documentType"
                        variant="standard"
                        defaultValue={document?.documentType}
                        placeholder="Izaberite tip dokumenta"
                        control={control}
                        loading={acLoading}
                        disabled
                      >
                        {acData?.documentTypes?.nodes?.map((documentType) => (
                          <MenuItem key={documentType?.id} value={documentType?.id}>
                            {documentType?.name}
                          </MenuItem>
                        ))}
                      </FormSelect>
                      {errors.documentType && (
                        <FormHelperText error>{errors.documentType.message}</FormHelperText>
                      )}
                    </Box>
                    <Box mt={1}>
                      <FormAutocomplete
                        error={!!errors.responsiblePerson}
                        fullWidth
                        label="Odgovorna osoba"
                        name="responsiblePerson"
                        required
                        placeholder="Unesite dva ili više karaktera"
                        defaultValue={document?.responsiblePerson}
                        routeName="workers"
                        defaultConditions={{ groupAccordance: false }}
                        initialData={acData?.clients}
                        optionsLabel="fullName"
                        showSide="code"
                        variant="standard"
                        control={control}
                        axios={axios}
                        loading={acLoading}
                        register={register}
                      />
                      {errors.responsiblePerson && (
                        <FormHelperText error>{errors.responsiblePerson.message}</FormHelperText>
                      )}
                    </Box>

                    <Box mt={1}>
                      <TextField
                        error={!!errors.documentNoPages}
                        fullWidth
                        name="documentNoPages"
                        id="documentNoPages"
                        label="Broj stranica"
                        inputRef={register}
                        defaultValue={document?.documentNoPages}
                        placeholder="Unesite broj stranica dokumenta"
                        type="number"
                      />
                      {errors.documentNoPages && (
                        <FormHelperText error>{errors.documentNoPages.message}</FormHelperText>
                      )}
                    </Box>
                    <Box mt={1}>
                      <TextField
                        fullWidth
                        name="statusName"
                        id="statusName"
                        label="Status"
                        inputRef={register}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        defaultValue={document?.statusName}
                        disabled
                      />
                    </Box>
                    <Box mt={1}>
                      <TextField
                        fullWidth
                        name="dateArchiving"
                        id="dateArchiving"
                        label="Datum arhiviranja"
                        inputRef={register}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        defaultValue={
                          document
                            ? dateFormatter(document?.dateArchiving, true)
                            : dateFormatter(dateNow(), true)
                        }
                        disabled
                      />
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card style={{ height: '100%' }} elevation={4}>
                  <CardHeader title="Dokument" titleTypographyProps={{ variant: 'subtitle2' }} />
                  <Divider />
                  <CardContent>
                    {!document && (
                      <>
                        <Box mb={6}>
                          <FormFileUpload
                            fullWidth
                            placeholder="DODAJ DOKUMENT"
                            setSelectedFile={setSelectedFile}
                            selectedFileName={selectedFileName}
                            setSelectedFileName={setSelectedFileName}
                            setIsFilePicked={setIsFilePicked}
                            isFilePicked={isFilePicked}
                          />
                        </Box>
                        <Divider />
                      </>
                    )}
                    <Box mt={1}>
                      <TextField
                        fullWidth
                        name="documentExtension"
                        id="documentExtension"
                        label="Ekstenzija"
                        inputRef={register}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        defaultValue={document?.documentExtension}
                        disabled
                      />
                    </Box>
                    <Box mt={1}>
                      <TextField
                        fullWidth
                        name="documentFilesize"
                        id="documentFilesize"
                        label="Veličina fajla (bajt)"
                        inputRef={register}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        defaultValue={document?.documentFilesize}
                        disabled
                      />
                    </Box>
                    <Box mt={1}>
                      <TextField
                        fullWidth
                        name="dateCreated"
                        id="dateCreated"
                        label="Datum kreiranja"
                        inputRef={register}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        defaultValue={
                          document
                            ? dateFormatter(document?.dateCreated, true)
                            : dateFormatter(dateNow(), true)
                        }
                        disabled
                      />
                    </Box>
                    <Box mt={1}>
                      <TextField
                        fullWidth
                        name="createdBy"
                        id="createdBy"
                        label="Korisnik kreirao"
                        inputRef={register}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        defaultValue={document?.createdBy || user.username}
                        disabled
                      />
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            {/* <Grid xs={6} item container>
              <Grid item xs={12}>
                <Card style={{ height: '100%' }} elevation={4}>
                  <CardHeader
                    title="Pridruživanje odgovorne organizacije / osobe"
                    titleTypographyProps={{ variant: 'subtitle2' }}
                  />
                  <Divider />
                  <CardContent>
                    <TransferList
                      visible
                      toggleFullHeight
                      rightSideList={testLista}
                      setRightSideList={() => { }}
                      listMounted
                      setListMounted={() => { }}
                      cardTitleLeft="Odgovorne osobe"
                      cardTitleRight="Dodijeljene osobe"
                      // routeNameLeft="all-organizations"
                      // routeNameRight="input-documents/other/organization"
                      defaultConditionsRight={{}}
                      defaultSorters={[`id ASC`]}
                      defaultConditionsLeft={{}}
                      labelName="value"
                      keyNameLeft="id"
                      keyNameRight="organizationId"
                    />
                  </CardContent>
                </Card>
              </Grid>
            </Grid> */}
          </Grid>
        )}
      </ContentBox>
    </InputModal>
  );
};

export default DocumentsModal;
