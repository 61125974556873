import React, { useState, useEffect } from 'react';
import {
  Grid,
  Box,
  Card,
  IconButton,
  SvgIcon,
  TextField,
  CardHeader,
  Divider,
  Tooltip,
} from '@material-ui/core';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import { FilterChip, InputModal } from '@shared/components';
import useTheme from '../../../../../hooks/useTheme';
import { handleFilterChange, handleChipDelete } from '../../../../../utils/filterHelpers';
import getChipValue from '../../../../../utils/getChipValue';

const Filter = ({ currentValues, visible, setVisible, setFilters, setPage, getLabelText }) => {
  const [filterValues, setFilterValues] = useState({});
  const theme = useTheme();

  useEffect(() => setFilterValues(currentValues), [currentValues]);

  const handleClose = () => setVisible(false);
  const onSubmit = () => {
    handleFilterChange(filterValues, setFilters, setPage);
    setVisible(false);
  };

  return (
    <InputModal
      theme={theme}
      filterTitle="Pretraga historije predmeta"
      visible={visible}
      onSubmit={onSubmit}
      handleClose={handleClose}
      filter
    >
      <Box padding={`${theme.spacing(5)}px`}>
        <Box pb={2} display="flex" alignItems="center" flexWrap="wrap">
          {Object.keys(filterValues).map(
            (chip) =>
              filterValues[chip] !== undefined && (
                <FilterChip
                  color="primary"
                  variant="outlined"
                  key={chip}
                  label={`${getLabelText(chip)}: ${getChipValue(chip, filterValues)}`}
                  onDelete={() => handleChipDelete(chip, setFilterValues)}
                />
              ),
          )}
        </Box>
        <Card style={{ minWidth: '300px' }}>
          <CardHeader
            title="Podaci o historiji"
            titleTypographyProps={{ variant: 'subtitle2' }}
            action={
              <Tooltip enterDelay={300} placement="top-end" arrow title="Ukloni filtere">
                <IconButton
                  onClick={() => {
                    setFilterValues({});
                  }}
                >
                  <SvgIcon fontSize="small">
                    <ClearAllIcon />
                  </SvgIcon>
                </IconButton>
              </Tooltip>
            }
          />
          <Divider />
          <Box padding={`${theme.spacing(5)}px`} width="100%" boxSizing="border-box">
            <Grid spacing={6} container justifyContent="space-between" alignItems="center">
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  value={filterValues.action || ''}
                  size="small"
                  placeholder="Unesite akciju za pretragu"
                  label="Akcija"
                  onChange={({ target: { value } }) =>
                    setFilterValues((prevState) => ({
                      ...prevState,
                      action: value || value?.length > 0 ? value : undefined,
                    }))
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  value={filterValues.createdBy || ''}
                  size="small"
                  placeholder="Unesite korisnika za pretragu"
                  label="Korisnik"
                  onChange={({ target: { value } }) =>
                    setFilterValues((prevState) => ({
                      ...prevState,
                      createdBy: value || value?.length > 0 ? value : undefined,
                    }))
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  value={filterValues.actionDate || ''}
                  type="date"
                  placeholder="Unesite datum za pretragu"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  size="small"
                  label="Datum akcije"
                  onChange={({ target: { value } }) =>
                    setFilterValues((prevState) => ({
                      ...prevState,
                      actionDate: value || value?.length > 0 ? value : undefined,
                    }))
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  value={filterValues.description || ''}
                  size="small"
                  placeholder="Unesite opis za pretragu"
                  label="Opis"
                  onChange={({ target: { value } }) =>
                    setFilterValues((prevState) => ({
                      ...prevState,
                      description: value || value?.length > 0 ? value : undefined,
                    }))
                  }
                />
              </Grid>
            </Grid>
          </Box>
        </Card>
      </Box>
    </InputModal>
  );
};

export default Filter;
