import * as yup from 'yup';

export const bookTypeSchema = yup.object().shape({
  code: yup
    .string()
    .max(12, 'Polje "Šifra" mora imati manje od 12 karaktera')
    .required('Polje "Šifra" je obavezno'),
  name: yup
    .string()
    .max(200, 'Polje "Naziv" mora imati manje od 200 karaktera')
    .required('Polje "Naziv" je obavezno'),
  isValid: yup.string().oneOf(['D', 'N', ''], 'Morate odabrati vrijednosti DA ili NE.'),
});

export const updateBookTypeSchema = yup.object().shape({
  name: yup
    .string()
    .max(200, 'Polje "Naziv" mora imati manje od 200 karaktera')
    .required('Polje "Naziv" je obavezno'),
  isValid: yup.string().oneOf(['D', 'N', ''], 'Morate odabrati vrijednosti DA ili NE.'),
});
