import React from 'react';
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  Tooltip,
  TablePagination,
  Box,
  Typography,
  SvgIcon,
  IconButton,
} from '@material-ui/core';
import ErrorOutlineRoundedIcon from '@material-ui/icons/ErrorOutlineRounded';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlined from '@material-ui/icons/DeleteOutline';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import { TableSkeleton } from '@shared/components';
import { getSortDirection, handleSortChange } from '../../../utils/sortHelpers';
import useTheme from '../../../hooks/useTheme';
import { ErrorMessage } from './style';

const ArchiveLocationsTable = ({
  archiveLocations,
  page,
  setPage,
  totalCount,
  rowsPerPage,
  setRowsPerPage,
  error,
  loading,
  sorters,
  setSorters,
  setSelectedArchiveLocation,
  setModalVisible,
  setDeleteModalVisible,
}) => {
  const theme = useTheme();
  const handleRowsPerPageChange = ({ target: { value } }) => {
    if (value * (page + 1) >= totalCount) setPage(Math.floor(totalCount / value));
    setRowsPerPage(value);
  };
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell width={80}>
            <Tooltip enterDelay={300} title="Sortiraj prema šifri" placement="bottom-start">
              <TableSortLabel
                active={!!getSortDirection('code', sorters)}
                direction={getSortDirection('code', sorters)}
                onClick={() => handleSortChange('code', sorters, setSorters)}
              >
                Šifra
              </TableSortLabel>
            </Tooltip>
          </TableCell>
          <TableCell width={140}>
            <Tooltip enterDelay={300} title="Sortiraj prema nazivu" placement="bottom-start">
              <TableSortLabel
                active={!!getSortDirection('name', sorters)}
                direction={getSortDirection('name', sorters)}
                onClick={() => handleSortChange('name', sorters, setSorters)}
              >
                Naziv
              </TableSortLabel>
            </Tooltip>
          </TableCell>
          <TableCell width={200}>
            <Tooltip enterDelay={300} title="Sortiraj prema opisu" placement="bottom-start">
              <TableSortLabel
                active={!!getSortDirection('description', sorters)}
                direction={getSortDirection('description', sorters)}
                onClick={() => handleSortChange('description', sorters, setSorters)}
              >
                Opis
              </TableSortLabel>
            </Tooltip>
          </TableCell>
          <TableCell width={80}>
            <Tooltip enterDelay={300} title="Sortiraj prema validnosti" placement="bottom-start">
              <TableSortLabel
                active={!!getSortDirection('isValid', sorters)}
                direction={getSortDirection('isValid', sorters)}
                onClick={() => handleSortChange('isValid', sorters, setSorters)}
              >
                Važi
              </TableSortLabel>
            </Tooltip>
          </TableCell>
          <TableCell width={50} />
        </TableRow>
      </TableHead>
      <TableBody>
        {error ? (
          <TableRow>
            <TableCell align="center" colSpan="100%" height={5 * 70.33}>
              <Box display="flex" justifyContent="center">
                <ErrorOutlineRoundedIcon fontSize="large" />
                <ErrorMessage>{error?.data?.details?.reason || 'Došlo je do greške'}</ErrorMessage>
              </Box>
            </TableCell>
          </TableRow>
        ) : loading || !archiveLocations ? (
          <TableRow>
            <TableCell
              align="center"
              colSpan="100%"
              style={{ paddingTop: '0' }}
              height={rowsPerPage}
            >
              <TableSkeleton size={rowsPerPage} cellHeight={50} />
            </TableCell>
          </TableRow>
        ) : archiveLocations?.length === 0 ? (
          <TableRow>
            <TableCell align="center" colSpan="100%" height={80}>
              <Typography>Nije pronađena niti jedna arhivska lokacija</Typography>
            </TableCell>
          </TableRow>
        ) : (
          archiveLocations?.map((archiveLocation) => (
            <TableRow key={archiveLocation.id}>
              <TableCell width={80}> {archiveLocation.code}</TableCell>
              <TableCell width={140}> {archiveLocation.name}</TableCell>
              <TableCell width={200}> {archiveLocation.description}</TableCell>
              <TableCell width={80}>
                <SvgIcon style={{ verticalAlign: 'middle' }}>
                  {archiveLocation.isValid === 'D' ? (
                    <DoneIcon style={{ color: theme.palette.success.main }} />
                  ) : archiveLocation.isValid === 'N' ? (
                    <CloseIcon style={{ color: theme.palette.error.main }} />
                  ) : (
                    ''
                  )}
                </SvgIcon>
              </TableCell>

              <TableCell width={50} align="right">
                <Tooltip enterDelay={300} placement="top-end" arrow title="Uredi arhivsku lokaciju">
                  <IconButton
                    onClick={() => {
                      setSelectedArchiveLocation(archiveLocation);
                      setModalVisible(true);
                    }}
                  >
                    <SvgIcon fontSize="small">
                      <EditOutlinedIcon />
                    </SvgIcon>
                  </IconButton>
                </Tooltip>
                <Tooltip
                  enterDelay={300}
                  placement="top-end"
                  arrow
                  title="Izbriši arhivsku lokaciju"
                >
                  <IconButton
                    onClick={() => {
                      setSelectedArchiveLocation(archiveLocation);
                      setDeleteModalVisible(true);
                    }}
                  >
                    <SvgIcon fontSize="small">
                      <DeleteOutlined />
                    </SvgIcon>
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))
        )}
        <TableRow>
          <TablePagination
            width="100%"
            count={totalCount}
            page={page}
            labelRowsPerPage="Broj redova po stranici"
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleRowsPerPageChange}
            rowsPerPageOptions={[5, 10, 20, 25]}
            onPageChange={(_, newPage) => setPage(newPage)}
          />
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default ArchiveLocationsTable;
