import React, { useState, useEffect } from 'react';
import {
  Divider,
  Grid,
  Box,
  FormHelperText,
  Card,
  CardHeader,
  CardContent,
  MenuItem,
  TextField,
  Paper,
  Tabs,
  Tab,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import {
  FormTextArea,
  FormAutocomplete,
  FormSelect,
  InputModal,
  useAPI,
  formatDateInput,
  dateFormatter,
  dateNow,
} from '@shared/components';
import { useForm } from 'react-hook-form';

import { subjectSchema } from '@shared/partner-schemas';
import { yupResolver } from '@hookform/resolvers/yup';
import useAuthContext from '../../../hooks/useAuthContext';
import TransferList from '../TransferList';
import axios from '../../../utils/axios';
import useTheme from '../../../hooks/useTheme';

export const useModalStyles = makeStyles(() => ({
  transferListGrid: {
    width: '70%',
  },
}));

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <Typography component="div" role="tabpanel" hidden={value !== index} {...other}>
      <Box p={1}>{children}</Box>
    </Typography>
  );
};

const SubjectsModal = ({
  subject,
  setSubject,
  visible,
  setVisible,
  resetSubject,
  setRerender,
  sendSuccessAlertData,
  sendErrorAlertData,
  disabled = false,
}) => {
  const [rightSideOrgList, setRightSideOrgList] = useState([]);
  const [orgListMounted, setOrgListMounted] = useState(false);
  const [signPeopleList, setSignedPeopleList] = useState([]);
  const [polListMounted, setPolListMounted] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const handleTabChange = (_, newValue) => {
    setTabValue(newValue);
  };

  const defaultData = { nodes: [], totalCount: 0 };
  const theme = useTheme();
  const { user } = useAuthContext();
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit, clearErrors, errors, reset, control, watch, setValue } = useForm({
    resolver: yupResolver(subjectSchema),
  });
  const classes = useModalStyles();
  const watchBookType = watch('bookType');

  const [
    acData = {
      bookTypeNames: [],
      classificationMarkNames: [],
      subjectStatusNames: [],
      organisationNames: [],
      responsiblePersonNames: [],
    },
    acError,
    acLoading,
  ] = useAPI(() => {
    return visible
      ? Promise.all([
          axios.get(`book-types`),
          axios.get(`classification-marks`, {
            params: {
              conditions: { id: subject?.classificationMark },
              orderBy: [],
              limit: subject?.classificationMark ? 1 : 0,
            },
          }),
          axios.get(`subjects-status`),
          axios.get(`workers`, {
            params: {
              conditions: subject?.responsiblePerson
                ? { id: subject?.responsiblePerson, groupAccordance: true }
                : { username: user.username, groupAccordance: true },
              orderBy: [],
              limit: 1,
            },
          }),
          axios.get(`archive-locations`, {
            params: {
              conditions: { id: subject?.archiveLocation },
              orderBy: [],
              limit: subject?.archiveLocation ? 1 : 0,
            },
          }),
        ]).then(
          ([
            { data: bookTypeNames },
            { data: classificationMarkNames },
            { data: subjectStatusNames },
            { data: responsiblePersonNames },
            { data: archiveLocationNames },
          ]) => ({
            bookTypeNames,
            classificationMarkNames,
            subjectStatusNames,
            responsiblePersonNames,
            archiveLocationNames,
          }),
        )
      : Promise.resolve(() => defaultData);
  }, [subject, visible]);

  const selectedBookType =
    acData?.bookTypeNames?.nodes?.filter((i) => i.id === watchBookType) || [];

  useEffect(() => {
    setValue('ownSubject', 'N');
  }, [selectedBookType]);

  const [organisationNames = [], atError, atLoading] = useAPI(
    () =>
      axios
        .get('organisations', {
          params: {
            conditions: {
              id:
                acData.responsiblePersonNames?.nodes?.length === 1
                  ? acData.responsiblePersonNames.nodes[0].organisationId
                  : subject?.organisation,
            },
            orderBy: [],
            limit: acData.responsiblePersonNames?.nodes?.length === 1 ? 1 : 0,
          },
        })
        .then(({ data }) => data),
    [JSON.stringify(acData.responsiblePersonNames)],
  );

  const handleClose = () => {
    setVisible(false);
    resetSubject();
    setRightSideOrgList([]);
    setSignedPeopleList([]);
    setOrgListMounted(false);
    setPolListMounted(false);
    setTabValue(0);
  };

  const onSubmit = async ({
    classificationMark,
    subjectStatus,
    organisation,
    responsiblePerson,
    archiveLocation,
    // ownSubjectAllowed,
    ...values
  }) => {
    if (subject) {
      values.year = subject.year;
    }

    values = {
      classificationMark: classificationMark ? classificationMark.id : null,
      status: subjectStatus,
      organisation: organisation ? organisation.id : null,
      responsiblePerson: responsiblePerson ? responsiblePerson.id : null,
      archiveLocation: archiveLocation ? archiveLocation.id : null,
      // ownSubjectAllowed: ownSubjectAllowed || 'N',
      ...values,
    };

    if (!values.year) {
      values.year = new Date().getFullYear();
    }
    setLoading(true);
    try {
      const { data } = subject
        ? await axios.put(`subjects/${subject.id}`, {
            subject: values,
          })
        : await axios.post(`subjects`, {
            subject: values,
          });
      if (!data) throw new Error();
      if (orgListMounted)
        await axios.post(`/subjects/other/organization`, {
          did: data?.id,
          username: user.username,
          rightSideOrgList,
        });

      if (polListMounted)
        await axios.post(`/subjects/other/people`, {
          did: data?.id,
          username: user.username,
          signPeopleList,
        });
      sendSuccessAlertData(`Uspješno ${subject ? 'izmijenjen' : 'dodan'} predmet`);
      if (setSubject) setSubject({ ...subject, ...values });
      setRerender((prevState) => !prevState);
      handleClose();
    } catch (error) {
      sendErrorAlertData(
        `${
          error?.response?.data?.details?.reason ||
          error?.response?.data?.error?.message ||
          `Došlo je do greške prilikom ${subject ? 'uređivanja' : 'unosa'} predmeta`
        }`,
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(false);
    clearErrors();
    reset();
  }, []);

  return (
    <InputModal
      object={subject}
      createTitle="Kreiraj predmet"
      updateTitle={`Uredi predmet ${subject?.id}`}
      createButtonText="Kreiraj predmet"
      updateButtonText="Uredi predmet"
      visible={visible}
      onSubmit={onSubmit}
      handleSubmit={handleSubmit}
      handleClose={handleClose}
      modalWidth="70%"
      loading={loading}
      theme={theme}
      disabledEdit={disabled}
    >
      <Box
        padding={`${theme.spacing(5)}px`}
        style={{ backgroundColor: theme.palette.background.dark }}
      >
        {/* {dataLoading ? (
          <Box
            flex="1"
            width="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Spinner size={60} />
          </Box>
        ) : */}
        {
          <>
            <Grid item container direction="column" xs={12} style={{ padding: 0 }}>
              <Grid item>
                <Card elevation={4}>
                  <Paper square>
                    <Tabs
                      value={tabValue}
                      variant="fullWidth"
                      indicatorColor="primary"
                      TabIndicatorProps={{
                        style: {
                          display: 'block',
                        },
                      }}
                      textColor="primary"
                      onChange={handleTabChange}
                      aria-label="radnici za uvid u dokument"
                    >
                      <Tab label="Osnovni podaci" />
                      <Tab label="Radnici" />
                      <Tab label="Organizacije" />
                    </Tabs>
                  </Paper>
                  <Divider />
                  <CardContent>
                    <TabPanel value={tabValue} index={0}>
                      <Grid
                        container
                        style={{ minHeight: 500 }}
                        spacing={5}
                        direction="row"
                        alignItems="stretch"
                      >
                        <Grid item sm={4}>
                          <Card elevation={4} style={{ height: '100%' }}>
                            <CardHeader
                              title="Osnovni podaci / Status predmeta"
                              titleTypographyProps={{ variant: 'subtitle2' }}
                            />
                            <Divider />
                            <CardContent>
                              <Grid container direction="column" spacing={5}>
                                <Grid item container direction="row" spacing={6}>
                                  <Grid item sm={6}>
                                    <TextField
                                      error={!!errors.year}
                                      size="small"
                                      fullWidth
                                      label="Godina"
                                      name="year"
                                      id="year"
                                      defaultValue={subject?.year || new Date().getFullYear()}
                                      placeholder="Unesite godinu predmeta"
                                      disabled
                                      inputRef={register}
                                    />
                                    {errors.year && (
                                      <FormHelperText error>{errors.year.message}</FormHelperText>
                                    )}
                                  </Grid>

                                  <Grid item sm={6}>
                                    <TextField
                                      error={!!errors.subjectNumber}
                                      size="small"
                                      fullWidth
                                      label="Broj predmeta"
                                      name="subjectNumber"
                                      id="subjectNumber"
                                      defaultValue={subject?.subjectNumber}
                                      disabled
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      inputRef={register}
                                    />
                                    {errors.subjectNumber && (
                                      <FormHelperText error>
                                        {errors.subjectNumber.message}
                                      </FormHelperText>
                                    )}
                                  </Grid>
                                </Grid>
                                <Grid item>
                                  <TextField
                                    error={!!errors.ordNumber}
                                    size="small"
                                    fullWidth
                                    label="Redni broj"
                                    name="ordNumber"
                                    id="ordNumber"
                                    defaultValue={subject?.ordNumber}
                                    placeholder="Unesite redni broj predmeta"
                                    disabled
                                    inputRef={register}
                                  />
                                  {errors.ordNumber && (
                                    <FormHelperText error>
                                      {errors.ordNumber.message}
                                    </FormHelperText>
                                  )}
                                </Grid>
                                <Grid item>
                                  <TextField
                                    error={!!errors.name}
                                    size="small"
                                    fullWidth
                                    label="Naziv predmeta*"
                                    name="name"
                                    id="name"
                                    defaultValue={subject?.name}
                                    placeholder="Unesite naziv predmeta"
                                    inputRef={register}
                                  />
                                  {errors.name && (
                                    <FormHelperText error>{errors.name.message}</FormHelperText>
                                  )}
                                </Grid>
                                <Grid item>
                                  <FormSelect
                                    error={!!errors.bookType}
                                    fullWidth
                                    loading={atLoading}
                                    label="Vrsta knjige*"
                                    name="bookType"
                                    id="bookType"
                                    control={control}
                                    defaultValue={subject?.bookType}
                                  >
                                    {acError ? (
                                      <MenuItem disabled>Došlo je do greške</MenuItem>
                                    ) : acData.bookTypeNames?.nodes ? (
                                      acData?.bookTypeNames?.nodes.map((bookType) => (
                                        <MenuItem
                                          key={bookType.id}
                                          value={bookType.id}
                                          disabled={bookType.isValid === 'N'}
                                        >
                                          {bookType.name}
                                        </MenuItem>
                                      ))
                                    ) : (
                                      <MenuItem disabled>Nema podataka</MenuItem>
                                    )}
                                  </FormSelect>
                                  {errors?.bookType && (
                                    <FormHelperText error>
                                      {errors?.bookType.message}
                                    </FormHelperText>
                                  )}
                                </Grid>
                                <Grid item>
                                  <FormAutocomplete
                                    fullWidth
                                    control={control}
                                    loading={acLoading}
                                    defaultValue={subject?.classificationMark}
                                    register={register}
                                    error={acError || !!errors.classificationMark}
                                    placeholder="Unesite klasifikacijsku oznaku"
                                    label="Klasifikacijska oznaka*"
                                    name="classificationMark"
                                    routeName="classification-marks"
                                    defaultConditions={{ isValid: 'D' }}
                                    initialData={acData?.classificationMarkNames}
                                    optionsLabel="name"
                                    showSide="id"
                                    axios={axios}
                                  />
                                  {errors?.classificationMark && (
                                    <FormHelperText error>
                                      {errors?.classificationMark.message}
                                    </FormHelperText>
                                  )}
                                  {acError && (
                                    <FormHelperText error>Došlo je do greške.</FormHelperText>
                                  )}
                                </Grid>
                                <Grid item>
                                  <FormSelect
                                    error={!!errors.subjectStatus}
                                    fullWidth
                                    loading={atLoading}
                                    label="Status*"
                                    name="subjectStatus"
                                    id="subjectStatus"
                                    control={control}
                                    defaultValue={subject?.status || 1}
                                    disabled
                                  >
                                    {acError ? (
                                      <MenuItem disabled>Došlo je do greške</MenuItem>
                                    ) : acData.subjectStatusNames?.nodes ? (
                                      acData?.subjectStatusNames?.nodes.map((statusName) => (
                                        <MenuItem
                                          key={statusName.id}
                                          value={statusName.id}
                                          disabled={statusName.isValid === 'N'}
                                        >
                                          {statusName.name}
                                        </MenuItem>
                                      ))
                                    ) : (
                                      <MenuItem disabled>Nema podataka</MenuItem>
                                    )}
                                  </FormSelect>
                                  {errors.subjectStatus && (
                                    <FormHelperText error>
                                      {errors.subjectStatus.message}
                                    </FormHelperText>
                                  )}
                                </Grid>
                              </Grid>
                            </CardContent>
                          </Card>
                        </Grid>
                        <Grid item sm={4}>
                          <Card elevation={4} style={{ height: '100%' }}>
                            <CardHeader
                              title="Dodatni podaci"
                              titleTypographyProps={{ variant: 'subtitle2' }}
                            />
                            <Divider />
                            <CardContent>
                              <Grid container direction="column" spacing={5}>
                                <Grid item container direction="row" spacing={6}>
                                  <Grid item sm={6}>
                                    <TextField
                                      error={!!errors.dateCreated}
                                      size="small"
                                      fullWidth
                                      label="Vrijeme kreiranja"
                                      name="dateCreated"
                                      id="dateCreated"
                                      defaultValue={dateFormatter(
                                        subject?.dateCreated || dateNow(),
                                        true,
                                      )}
                                      inputRef={register}
                                      disabled
                                    />
                                    {errors.dateCreated && (
                                      <FormHelperText error>
                                        {errors.dateCreated.message}
                                      </FormHelperText>
                                    )}
                                  </Grid>
                                  <Grid item sm={6}>
                                    <TextField
                                      error={!!errors.createdBy}
                                      size="small"
                                      fullWidth
                                      label="Korisnik koji je kreirao"
                                      name="createdBy"
                                      id="createdBy"
                                      defaultValue={subject?.createdBy || user?.username}
                                      inputRef={register}
                                      disabled
                                    />
                                    {errors.createdBy && (
                                      <FormHelperText error>
                                        {errors.createdBy.message}
                                      </FormHelperText>
                                    )}
                                  </Grid>
                                </Grid>
                                {subject?.dateModified && (
                                  <Grid item container direction="row" spacing={6}>
                                    <Grid item sm={6}>
                                      <TextField
                                        error={!!errors.dateModified}
                                        size="small"
                                        fullWidth
                                        label="Posljednja izmjena"
                                        name="dateModified"
                                        id="dateModified"
                                        defaultValue={dateFormatter(subject?.dateModified, true)}
                                        inputRef={register}
                                        disabled
                                      />
                                      {errors.dateModified && (
                                        <FormHelperText error>
                                          {errors.dateModified.message}
                                        </FormHelperText>
                                      )}
                                    </Grid>
                                    <Grid item sm={6}>
                                      <TextField
                                        error={!!errors.modifiedBy}
                                        size="small"
                                        fullWidth
                                        label="Korisnik koji je ažurirao"
                                        name="modifiedBy"
                                        id="modifiedBy"
                                        defaultValue={subject?.modifiedBy}
                                        inputRef={register}
                                        disabled
                                      />
                                      {errors.modifiedBy && (
                                        <FormHelperText error>
                                          {errors.modifiedBy.message}
                                        </FormHelperText>
                                      )}
                                    </Grid>
                                  </Grid>
                                )}
                                <Grid item>
                                  <FormAutocomplete
                                    fullWidth
                                    control={control}
                                    loading={acLoading}
                                    defaultValue={subject?.responsiblePerson}
                                    register={register}
                                    error={acError || !!errors.responsiblePerson}
                                    label="Odgovorna osoba*"
                                    name="responsiblePerson"
                                    routeName="workers"
                                    defaultConditions={{ status: 'A' }}
                                    initialData={acData?.responsiblePersonNames}
                                    optionsLabel="fullName"
                                    axios={axios}
                                    acDisabled
                                  />
                                  {errors.responsiblePerson && (
                                    <FormHelperText error>
                                      {errors.responsiblePerson.message}
                                    </FormHelperText>
                                  )}
                                  {acError && (
                                    <FormHelperText error>Došlo je do greške.</FormHelperText>
                                  )}
                                </Grid>
                                <Grid item>
                                  <FormAutocomplete
                                    fullWidth
                                    control={control}
                                    loading={atLoading}
                                    defaultValue={subject?.organisation}
                                    register={register}
                                    error={atError || !!errors.organisation}
                                    label="Organizacija*"
                                    name="organisation"
                                    routeName="organisations"
                                    initialData={organisationNames}
                                    optionsLabel="name"
                                    showSide="code"
                                    axios={axios}
                                    acDisabled
                                  />
                                  {errors.organisation && (
                                    <FormHelperText error>
                                      {errors.organisation.message}
                                    </FormHelperText>
                                  )}
                                  {acError && (
                                    <FormHelperText error>Došlo je do greške.</FormHelperText>
                                  )}
                                </Grid>
                                <Grid item container direction="row" spacing={6}>
                                  <Grid item sm={6}>
                                    <FormSelect
                                      id="ownSubject"
                                      name="ownSubject"
                                      label="Vlastiti predmet"
                                      required
                                      error={!!errors.ownSubject}
                                      defaultValue={subject?.ownSubject || 'N'}
                                      control={control}
                                      disabled={
                                        subject?.ownSubject === 'N' ||
                                        (selectedBookType.length
                                          ? selectedBookType[0]?.ownSubjectAllowed === 'N'
                                          : false)
                                      }
                                      fullWidth
                                    >
                                      <MenuItem value="D">DA</MenuItem>
                                      <MenuItem value="N">NE</MenuItem>
                                    </FormSelect>
                                    {errors.ownSubject && (
                                      <FormHelperText error>
                                        {errors.ownSubject.message}
                                      </FormHelperText>
                                    )}
                                  </Grid>
                                  <Grid item sm={6}>
                                    <FormSelect
                                      id="subjectValid"
                                      name="subjectValid"
                                      label="Važeći predmet"
                                      required
                                      error={!!errors.subjectValid}
                                      defaultValue={subject?.subjectValid || 'D'}
                                      control={control}
                                      fullWidth
                                    >
                                      <MenuItem value="D">DA</MenuItem>
                                      <MenuItem value="N">NE</MenuItem>
                                    </FormSelect>
                                    {errors.subjectValid && (
                                      <FormHelperText error>
                                        {errors.subjectValid.message}
                                      </FormHelperText>
                                    )}
                                  </Grid>
                                </Grid>
                                <Grid item>
                                  <TextField
                                    label="Rok rješavanja predmeta"
                                    name="resolutionDeadline"
                                    id="resolutionDeadline"
                                    type="date"
                                    fullWidth
                                    defaultValue={formatDateInput(subject?.resolutionDeadline)}
                                    placeholder="Unesite datum roka rješavanja predmeta"
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    inputRef={register()}
                                  />
                                  {errors.resolutionDeadline && (
                                    <FormHelperText error>
                                      {errors.resolutionDeadline.message}
                                    </FormHelperText>
                                  )}
                                </Grid>
                              </Grid>
                            </CardContent>
                          </Card>
                        </Grid>
                        <Grid item sm={4}>
                          <Card elevation={4} style={{ height: '100%' }}>
                            <CardHeader
                              title="Opis / Komentar"
                              titleTypographyProps={{ variant: 'subtitle2' }}
                            />
                            <Divider />
                            <CardContent>
                              <Grid container direction="column" spacing={5}>
                                <Grid item>
                                  <FormTextArea
                                    object={subject}
                                    placeholder="Unesite opis predmeta"
                                    label="Opis predmeta"
                                    name="description"
                                    maxLength={2000}
                                    rows={6}
                                    register={register}
                                    errors={errors}
                                  />
                                </Grid>
                                <Grid item>
                                  <FormTextArea
                                    object={subject}
                                    placeholder="Unesite kratak komentar predmeta"
                                    label="Komentar predmeta"
                                    name="comment"
                                    maxLength={2000}
                                    rows={6}
                                    register={register}
                                    errors={errors}
                                  />
                                </Grid>
                              </Grid>
                            </CardContent>
                          </Card>
                        </Grid>
                      </Grid>
                    </TabPanel>
                    <TabPanel value={tabValue} index={1}>
                      <Grid
                        container
                        direction="column"
                        spacing={2}
                        alignItems="center"
                        classes={classes.root}
                      >
                        <Grid item>
                          <Typography variant="body1" color="textSecondary">
                            Dodjela radnika za uvid (FYI)
                          </Typography>
                        </Grid>
                        <Grid item className={classes.transferListGrid}>
                          <TransferList
                            visible={tabValue === 1}
                            toggleFullHeight
                            rightSideList={signPeopleList}
                            setRightSideList={setSignedPeopleList}
                            listMounted={polListMounted}
                            setListMounted={setPolListMounted}
                            cardTitleLeft="Svi radnici"
                            cardTitleRight="Radnici za uvid"
                            routeNameLeft="workers"
                            routeNameRight="subjects/other/people"
                            defaultConditionsRight={{ subjectId: subject ? subject.id : '' }}
                            defaultSorters={[
                              "replace(replace(replace(replace(replace(upper('name'), 'Č', 'C'), 'Ć', 'C'), 'Š', 'S'), 'Ž', 'Z'), 'Đ', 'DJ'), upper('surname') ASC",
                            ]}
                            defaultConditionsLeft={{}}
                            labelName="workerName"
                            keyNameLeft="id"
                            keyNameRight="workerId"
                            height="24.6rem"
                          />
                        </Grid>
                      </Grid>
                    </TabPanel>
                    <TabPanel value={tabValue} index={2}>
                      <Grid container direction="column" spacing={2} alignItems="center">
                        <Grid item>
                          <Typography variant="body1" color="textSecondary">
                            Dodjela organizacija za uvid (FYI)
                          </Typography>
                        </Grid>
                        <Grid item className={classes.transferListGrid}>
                          <TransferList
                            visible={tabValue === 2}
                            toggleFullHeight
                            rightSideList={rightSideOrgList}
                            setRightSideList={setRightSideOrgList}
                            listMounted={orgListMounted}
                            setListMounted={setOrgListMounted}
                            cardTitleLeft="Sve organizacije"
                            cardTitleRight="Organizacije za uvid"
                            routeNameLeft="organisations"
                            routeNameRight="subjects/other/organization"
                            defaultConditionsRight={{ subjectId: subject ? subject.id : '' }}
                            defaultSorters={[`id ASC`]}
                            defaultConditionsLeft={{}}
                            labelName="organizationName"
                            keyNameLeft="id"
                            keyNameRight="organizationId"
                            height="24.6rem"
                          />
                        </Grid>
                      </Grid>
                    </TabPanel>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </>
        }
      </Box>
    </InputModal>
  );
};

export default SubjectsModal;
