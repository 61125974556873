import React, { useState, useEffect } from 'react';
import {
  Divider,
  Grid,
  Box,
  TextField,
  FormHelperText,
  Card,
  CardHeader,
  CardContent,
  MenuItem,
} from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { InputModal, FormSelect, useAPI} from '@shared/components';

import { attributeSchema } from '@shared/partner-schemas';
import axios from '../../../utils/axios';
import useTheme from '../../../hooks/useTheme';

const AttributesModal = ({
  attribute,
  setAttribute,
  visible,
  setVisible,
  sendSuccessAlertData,
  sendErrorAlertData,
  setRerender,
}) => {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [requestError, setRequestError] = useState({});
  const { handleSubmit, errors, reset, clearErrors, register, control } = useForm({
    resolver: yupResolver(attributeSchema),
  });

  const [attributeTypes = [], atError, atLoading] = useAPI(
    () => axios.get('attribute-types').then(({ data }) => data),
    [],
  );

  const onSubmit = async ({ code, name, attributeType }) => {
    setLoading(true);
    try {
      const data = attribute
        ? await axios.put(`attributes/${attribute.id}`, {
            attribute: { code, name, attributeType },
          })
        : await axios.post(`attributes`, {
            attribute: { code, name, attributeType },
          });
      if (!data) throw new Error();
      sendSuccessAlertData(`Uspješno ${attribute ? 'izmijenjen' : 'unesen'} atribut`);
      if (attribute) setAttribute(data.data);
      setRerender((prevState) => !prevState);
      handleClose();
    } catch (error) {
      sendErrorAlertData(
        `${
          error?.response?.data?.details?.reason ||
          error?.response?.data?.error?.message ||
          `Došlo je do greške prilikom ${attribute ? 'uređivanja' : 'unosa'}`
        }`,
      );
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setVisible(false);
  };

  useEffect(() => {
    setLoading(false);
    setRequestError(null);
    clearErrors();
    reset();
  }, []);

  return (
    <InputModal
      object={attribute}
      createTitle="Unos novog atributa"
      updateTitle={`Uredi atribut: ${attribute?.code}`}
      createButtonText="Kreiraj atribut"
      updateButtonText="Uredi atribut"
      visible={visible}
      onSubmit={onSubmit}
      handleSubmit={handleSubmit}
      handleClose={handleClose}
      requestError={requestError}
      modalWidth={600}
      loading={loading}
      theme={theme}
    >
      <Box
        padding={`${theme.spacing(5)}px`}
        style={{ backgroundColor: theme.palette.background.dark }}
      >
        <Grid
          container
          style={{ minHeight: 250 }}
          spacing={6}
          direction="column"
          alignItems="stretch"
        >
          <Grid item>
            <Card elevation={4} style={{ height: '100%' }}>
              <CardHeader title="Osnovni podaci" titleTypographyProps={{ variant: 'subtitle2' }} />
              <Divider />
              <CardContent>
                <Grid container direction="column" spacing={6}>
                  <Grid item>
                    <TextField
                      fullWidth
                      error={!!errors.code}
                      label="Šifra*"
                      defaultValue={attribute?.code}
                      name="code"
                      id="code"
                      placeholder="Unesite šifru"
                      inputRef={register}
                    />
                    {errors.code && <FormHelperText error>{errors.code.message}</FormHelperText>}
                  </Grid>
                  <Grid item>
                    <TextField
                      fullWidth
                      error={!!errors.name}
                      label="Naziv*"
                      defaultValue={attribute?.name}
                      name="name"
                      id="name"
                      placeholder="Unesite naziv"
                      inputRef={register}
                    />
                    {errors.name && <FormHelperText error>{errors.name.message}</FormHelperText>}
                  </Grid>
                  <Grid item>
                    <FormSelect
                      error={!!errors.attributeType}
                      fullWidth
                      loading={atLoading}
                      label="Tip atributa*"
                      name="attributeType"
                      id="attributeType"
                      control={control}
                      defaultValue={attribute?.attributeType}
                    >
                      {atError ? (
                        <MenuItem disabled>Došlo je do greške</MenuItem>
                      ) : attributeTypes.nodes ? (
                        attributeTypes.nodes.map((attributeType) => (
                          <MenuItem
                            key={attributeType.id}
                            value={attributeType.id}
                            name={attributeType.id}
                          >
                            {attributeType.name}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem disabled>Nema podataka</MenuItem>
                      )}
                    </FormSelect>
                    {errors.attributeType && (
                      <FormHelperText error>{errors.attributeType.message}</FormHelperText>
                    )}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </InputModal>
  );
};

export default AttributesModal;
