import React, { useState, useEffect } from 'react';
import {
  Grid,
  IconButton,
  SvgIcon,
  Box,
  Divider,
  Tooltip,
  TextField,
  Card,
  CardHeader,
  MenuItem
} from '@material-ui/core';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import { FilterChip, InputModal } from '@shared/components';
import { handleFilterChange, handleChipDelete } from '../../../../../utils/filterHelpers';
import useTheme from '../../../../../hooks/useTheme';
import { ContentBox } from './style';

const Filter = ({
  currentValues,
  visible,
  setVisible,
  getLabelText,
  getChipValue,
  setFilters,
  setPage,
}) => {
  const theme = useTheme();

  const [filterValues, setFilterValues] = useState({});
  const handleClose = () => setVisible(false);

  const onSubmit = () => {
    handleFilterChange(filterValues, setFilters, setPage);
    setVisible(false);
  };

  useEffect(() => setFilterValues(currentValues), [currentValues]);
  return (
    <InputModal
      filterTitle="Pretraga dokumenata"
      visible={visible}
      handleClose={handleClose}
      onSubmit={onSubmit}
      modalWidth="35%"
      theme={theme}
      filter
    >
      <Box padding={`${theme.spacing(5)}px`}>
        <Box p={2} display="flex" alignItems="center" flexWrap="wrap">
          {Object.keys(filterValues).map(
            (chip) =>
              filterValues[chip] !== undefined && (
                <FilterChip
                  color="primary"
                  variant="outlined"
                  key={chip}
                  label={`${getLabelText(chip)}: ${getChipValue(chip, filterValues)}`}
                  onDelete={() => handleChipDelete(chip, setFilterValues)}
                />
              ),
          )}
        </Box>
        <Box>
          <Card elevation={4}>
            <CardHeader
              titleTypographyProps={{ variant: 'subtitle2' }}
              title="Podaci o dokumentu"
              action={
                <Tooltip enterDelay={300} placement="top-end" arrow title="Ukloni filtere">
                  <IconButton
                    onClick={() => {
                      setFilterValues({});
                    }}
                  >
                    <SvgIcon fontSize="small">
                      <ClearAllIcon />
                    </SvgIcon>
                  </IconButton>
                </Tooltip>
              }
            />
            <Divider />
            <ContentBox elevation={5}>
              <Grid
                container
                direction="row"
                spacing={5}
                style={{ maxHeight: 500, overflowY: 'auto' }}
              >
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="ID"
                    name="id"
                    type="number"
                    placeholder="Unesite id za pretragu"
                    value={filterValues.id || ''}
                    onChange={({ target: { value } }) =>
                      setFilterValues((prevState) => ({
                        ...prevState,
                        id: value?.length === 0 ? undefined : value,
                      }))
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Tip dokumenta"
                    name="documentTypeName"
                    placeholder="Unesite tip dokumenta za pretragu"
                    value={filterValues.documentTypeName || ''}
                    onChange={({ target: { value } }) =>
                      setFilterValues((prevState) => ({
                        ...prevState,
                        documentTypeName: value?.length === 0 ? undefined : value,
                      }))
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Broj stranica"
                    name="documentNoPages"
                    type="number"
                    placeholder="Unesite broj stranica za pretragu"
                    value={filterValues.documentNoPages || ''}
                    onChange={({ target: { value } }) =>
                      setFilterValues((prevState) => ({
                        ...prevState,
                        documentNoPages: value?.length === 0 ? undefined : value,
                      }))
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Ekstenzija"
                    name="documentExtension"
                    placeholder="Unesite ektenziju dokumenta za pretragu"
                    value={filterValues.documentExtension || ''}
                    onChange={({ target: { value } }) =>
                      setFilterValues((prevState) => ({
                        ...prevState,
                        documentExtension: value?.length === 0 ? undefined : value,
                      }))
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Veličina fajla (bajt)"
                    name="documentFilesize"
                    type="number"
                    placeholder="Unesite veličinu fajla za pretragu"
                    value={filterValues.documentFilesize || ''}
                    onChange={({ target: { value } }) =>
                      setFilterValues((prevState) => ({
                        ...prevState,
                        documentFilesize: value?.length === 0 ? undefined : value,
                      }))
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Datum kreiranja"
                    name="dateCreated"
                    type="date"
                    fullWidth
                    value={filterValues.dateCreated || ''}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={({ target: { value } }) =>
                      setFilterValues((prevState) => ({
                        ...prevState,
                        dateCreated: value?.length === 0 ? undefined : value,
                      }))
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Status"
                    name="statusName"
                    placeholder="Unesite status za pretragu"
                    value={filterValues.statusName || ''}
                    onChange={({ target: { value } }) =>
                      setFilterValues((prevState) => ({
                        ...prevState,
                        statusName: value?.length === 0 ? undefined : value,
                      }))
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Vrsta dokumenta"
                    name="documentSort"
                    select
                    placeholder="Unesite vrstu dokumenta za pretragu"
                    value={filterValues.documentSort || ''}
                    onChange={({ target: { value } }) =>
                      setFilterValues((prevState) => ({
                        ...prevState,
                        documentSort: value?.length === 0 ? undefined : value,
                      }))
                    }
                  >
                    <MenuItem value="U">Ulazni</MenuItem>
                    <MenuItem value="I">Izlazni</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Korisnik kreirao"
                    name="createdBy"
                    placeholder="Unesite naziv korisnika za pretragu"
                    value={filterValues.createdBy || ''}
                    onChange={({ target: { value } }) =>
                      setFilterValues((prevState) => ({
                        ...prevState,
                        createdBy: value?.length === 0 ? undefined : value,
                      }))
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Odgovorna osoba"
                    name="responsiblePersonName"
                    placeholder="Unesite naziv odgovorne osobe"
                    value={filterValues.responsiblePersonName || ''}
                    onChange={({ target: { value } }) =>
                      setFilterValues((prevState) => ({
                        ...prevState,
                        responsiblePersonName: value?.length === 0 ? undefined : value,
                      }))
                    }
                  />
                </Grid>
              </Grid>
            </ContentBox>
          </Card>
        </Box>
      </Box>
    </InputModal>
  );
};

export default Filter;
