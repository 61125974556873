import React from 'react';
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  Tooltip,
  IconButton,
  TablePagination,
  Box,
  SvgIcon,
  Typography,
} from '@material-ui/core';
import ErrorOutlineRoundedIcon from '@material-ui/icons/ErrorOutlineRounded';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import { TableSkeleton } from '@shared/components';
import { getSortDirection, handleSortChange } from '../../../utils/sortHelpers';
import useTheme from '../../../hooks/useTheme';
import { ErrorMessage } from './style';

const classMarksTable = ({
  classMarks,
  page,
  setPage,
  totalCount,
  rowsPerPage,
  setRowsPerPage,
  error,
  loading,
  sorters,
  setSorters,
  setSelectedUnit,
  setDeleteModalVisible,
  setClassMarkModalVisible,
}) => {
  const theme = useTheme();
  const handleRowsPerPageChange = ({ target: { value } }) => {
    if (value * (page + 1) >= totalCount) setPage(Math.floor(totalCount / value));
    setRowsPerPage(value);
  };

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell width={50}>
            <Tooltip enterDelay={300} title="Sortiraj prema id-u" placement="bottom-start">
              <TableSortLabel
                active={!!getSortDirection('id', sorters)}
                direction={getSortDirection('id', sorters)}
                onClick={() => handleSortChange('id', sorters, setSorters)}
              >
                ID
              </TableSortLabel>
            </Tooltip>
          </TableCell>
          <TableCell width={50}>
            <Tooltip enterDelay={300} title="Sortiraj prema šifri" placement="bottom-start">
              <TableSortLabel
                active={!!getSortDirection('code', sorters)}
                direction={getSortDirection('code', sorters)}
                onClick={() => handleSortChange('code', sorters, setSorters)}
              >
                Šifra
              </TableSortLabel>
            </Tooltip>
          </TableCell>
          <TableCell width={250}>
            <Tooltip enterDelay={300} title="Sortiraj prema nazivu" placement="bottom-start">
              <TableSortLabel
                active={!!getSortDirection('name', sorters)}
                direction={getSortDirection('name', sorters)}
                onClick={() => handleSortChange('name', sorters, setSorters)}
              >
                Naziv
              </TableSortLabel>
            </Tooltip>
          </TableCell>
          <TableCell width={80}>
            <Tooltip
              enterDelay={300}
              title="Sortiraj prema opciji važi"
              placement="bottom-start"
            >
              <TableSortLabel
                active={!!getSortDirection('isValid', sorters)}
                direction={getSortDirection('isValid', sorters)}
                onClick={() => handleSortChange('isValid', sorters, setSorters)}
              >
                Važi
              </TableSortLabel>
            </Tooltip>
          </TableCell>
          <TableCell width={150}>
            <Tooltip
              enterDelay={300}
              title="Sortiraj prema opciji rok čuvanja"
              placement="bottom-start"
            >
              <TableSortLabel
                active={!!getSortDirection('retentionPeriod', sorters)}
                direction={getSortDirection('retentionPeriod', sorters)}
                onClick={() => handleSortChange('retentionPeriod', sorters, setSorters)}
              >
                Rok čuvanja
              </TableSortLabel>
            </Tooltip>
          </TableCell>
          <TableCell width={150}>
            <Tooltip
              enterDelay={300}
              title="Sortiraj prema opisu"
              placement="bottom-start"
            >
              <TableSortLabel
                active={!!getSortDirection('description', sorters)}
                direction={getSortDirection('description', sorters)}
                onClick={() => handleSortChange('description', sorters, setSorters)}
              >
                Opis
              </TableSortLabel>
            </Tooltip>
          </TableCell>

          <TableCell width={150} />
        </TableRow>
      </TableHead>
      <TableBody>
        {error ? (
          <TableRow>
            <TableCell align="center" colSpan="100%" height={5 * 70.33}>
              <Box display="flex" justifyContent="center">
                <ErrorOutlineRoundedIcon fontSize="large" />
                <ErrorMessage>{error?.data?.details?.reason || 'Došlo je do greške'}</ErrorMessage>
              </Box>
            </TableCell>
          </TableRow>
        ) : loading || !classMarks ? (
          <TableRow>
            <TableCell align="center" colSpan="100%">
              <TableSkeleton size={rowsPerPage} />
            </TableCell>
          </TableRow>
        ) : classMarks?.length === 0 ? (
          <TableRow>
            <TableCell align="center" colSpan="100%" height={80}>
              <Typography>Nije pronađena niti jedna klasifikaciona oznaka</Typography>
            </TableCell>
          </TableRow>
        ) : (
          classMarks?.map((classMark) => (
            <TableRow key={classMark.id}>
              <TableCell width={100}> {classMark.id}</TableCell>
              <TableCell width={100}> {classMark.code}</TableCell>
              <TableCell width={250}> {classMark.name}</TableCell>
              <TableCell width={80}>
                <SvgIcon style={{ verticalAlign: 'middle' }}>
                  {classMark.isValid === 'D' ? (
                    <DoneIcon style={{ color: theme.palette.success.main }} />
                  ) : classMark.isValid === 'N' ? (
                    <CloseIcon style={{ color: theme.palette.error.main }} />
                  ) : (
                    ''
                  )}
                </SvgIcon>
              </TableCell>
              <TableCell width={150}> {classMark.retentionPeriod}</TableCell>
              <TableCell width={150}> {classMark.description}</TableCell>
              <TableCell align="right">
                <>
                  <Tooltip enterDelay={300} placement="top-end" arrow title="Uredi klasifikacionu oznaku">
                    <IconButton
                      onClick={() => {
                        setSelectedUnit(classMark);
                        setClassMarkModalVisible(true);
                      }}
                    >
                      <SvgIcon fontSize="small">
                        <EditOutlinedIcon />
                      </SvgIcon>
                    </IconButton>
                  </Tooltip>
                  <Tooltip enterDelay={300} placement="top-end" arrow title="Ukloni klasifikacionu oznaku">
                    <IconButton
                      onClick={() => {
                        setSelectedUnit(classMark);
                        setDeleteModalVisible(true);
                      }}
                    >
                      <SvgIcon fontSize="small">
                        <DeleteOutlinedIcon />
                      </SvgIcon>
                    </IconButton>
                  </Tooltip>
                </>
              </TableCell>
            </TableRow>
          ))
        )}
        <TableRow>
          <TablePagination
            width="100%"
            count={totalCount}
            page={page}
            labelRowsPerPage="Broj redova po stranici"
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleRowsPerPageChange}
            rowsPerPageOptions={[5, 10, 20, 25]}
            onPageChange={(_, newPage) => setPage(newPage)}
          />
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default classMarksTable;
