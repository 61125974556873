import styled from 'styled-components';
import { Box as MuiBox, TextField as MuiTextField } from '@material-ui/core';

export const ContentBox = styled(MuiBox)`
  padding: ${({ theme }) => theme.spacing(5)}px;
  min-height: 40vh;
`;

export const ErrorMessage = styled.span`
  font-size: 24px;
  padding-left: ${({ theme }) => theme.spacing(2)}px;
  padding-top: ${({ theme }) => theme.spacing(2)}px;
`;

export const TextField = styled(MuiTextField)`
  padding: ${({ theme }) => theme.spacing(1)}px;
  & div {
    padding-top: 5px;
    padding-bottom: 5px;
  }
`;
