import React, { useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Grid,
  Divider,
  Breadcrumbs,
  Typography,
  Link,
} from '@material-ui/core';

import { Link as RouterLink } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';
import FilterListIcon from '@material-ui/icons/FilterList';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import {
  FilterChip,
  QuickSearch,
  useAPI,
  DeleteModal,
  VerticalBoxContainer,
  PageContentWrapper,
} from '@shared/components';
import PageHeader from '../../components/PageHeader';
import ExcelExportButton from '../../components/ExcelExportButton';
import { handleChipDelete } from '../../utils/filterHelpers';
import useTheme from '../../hooks/useTheme';
import axios from '../../utils/axios';
import Table from './Table';
import Filter from './Filter';
import Modal from './Modal';
import useGlobalAlertContext from '../../hooks/useGlobalAlertContext';

const fields = [
  {
    label: 'Šifra',
    value: 'code',
  },
  {
    label: 'Naziv',
    value: 'name',
  },
  {
    label: 'Važi',
    value: 'isValid',
  },
  {
    label: 'Dozvoljen vlastiti predmet',
    value: 'ownSubjectAllowed',
  },
];

const getLabelText = (field) => {
  const map = {
    name: 'Naziv',
    code: 'Šifra',
    isValid: 'Važi',
    ownSubjectAllowed: 'Dozvoljen vlastiti predmet',
  };
  return map[field];
};

const getChipValue = (chip, filters) => {
  if (chip === 'isValid' || chip === 'ownSubjectAllowed')
    return filters[chip] === 'D' ? 'DA' : 'NE';
  return filters[chip];
};

const BookTypes = ({ history }) => {
  const theme = useTheme();
  const [selectedBookType, setSelectedBookType] = useState(null);
  const [filters, setFilters] = useState({});
  const [sorters, setSorters] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filterModalVisible, setFilterModalVisible] = useState(false);
  const [triggerRerender, setRerender] = useState(false);

  const [modalVisible, setModalVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const { sendSuccessAlertData, sendErrorAlertData } = useGlobalAlertContext();

  const [bookTypes = { nodes: [], totalCount: 0 }, error, loading] = useAPI(
    () =>
      axios
        .get(`book-types`, {
          params: {
            limit: rowsPerPage,
            offset: page * rowsPerPage,
            conditions: filters,
            orderBy: [...sorters, '"id" DESC'],
          },
        })
        .then(({ data }) => data),
    [page, rowsPerPage, filters, sorters, triggerRerender],
  );

  const handleFilterChange = (filterValues) => {
    setPage(0);
    setFilters({ ...filterValues });
  };

  return (
    <VerticalBoxContainer>
      <Filter
        currentValues={filters}
        visible={filterModalVisible}
        setVisible={setFilterModalVisible}
        handleFilterChange={handleFilterChange}
        getLabelText={getLabelText}
        getChipValue={getChipValue}
        setFilters={setFilters}
        setPage={setPage}
      />
      <Modal
        bookType={selectedBookType}
        setBookType={setSelectedBookType}
        visible={modalVisible}
        setVisible={setModalVisible}
        setRerender={setRerender}
        sendSuccessAlertData={sendSuccessAlertData}
        sendErrorAlertData={sendErrorAlertData}
        resetBookType={() => setSelectedBookType(undefined)}
      />
      <DeleteModal
        buttonText="Obriši vrstu knjige"
        dataLength={bookTypes.totalCount}
        message="Uspješno obrisana vrsta knjige"
        page={page}
        setPage={setPage}
        objectId={selectedBookType?.id}
        setObject={setSelectedBookType}
        path="book-types"
        promptText={`Da li ste sigurni da želite obrisati vrstu knjige naziva ${selectedBookType?.name}?`}
        resetObject={() => setSelectedBookType(undefined)}
        sendSuccessAlertData={sendSuccessAlertData}
        sendErrorAlertData={sendErrorAlertData}
        setRerender={setRerender}
        visible={deleteModalVisible}
        setVisible={setDeleteModalVisible}
        title={`Obriši vrstu knjige sa šifrom ${selectedBookType?.code}`}
        theme={theme}
        axios={axios}
      />
      <PageHeader
        title="Vrste knjiga"
        extra={
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddCircleOutlineOutlinedIcon />}
            onClick={() => setModalVisible(true)}
          >
            <Box fontSize="small">Nova vrsta knjige</Box>
          </Button>
        }
      />
      <PageContentWrapper>
        <Breadcrumbs style={{ paddingBottom: `${theme.spacing(5)}px` }}>
          <Link variant="body1" color="inherit" to="/" component={RouterLink}>
            Početna
          </Link>
          <Typography variant="body1" color="textPrimary">
            Šifarnici
          </Typography>
          <Typography variant="body1" color="textPrimary">
            Vrste knjiga
          </Typography>
        </Breadcrumbs>
        <Card>
          <CardHeader
            title={
              <QuickSearch
                name="code"
                placeholder="Brza pretraga po šifri"
                setFilters={setFilters}
                mainFilter={filters}
                setPage={setPage}
              />
            }
            action={
              <Grid container spacing={4}>
                <Grid item>
                  <Button
                    color="primary"
                    disabled={Object.keys(filters).filter((k) => filters[k])?.length === 0}
                    startIcon={<CloseIcon fontSize="small" />}
                    onClick={() => setFilters({})}
                  >
                    <Box fontSize="small">Ukloni sve filtere</Box>
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<FilterListIcon fontSize="small" />}
                    onClick={() => setFilterModalVisible(true)}
                  >
                    <Box fontSize="small">Filteri</Box>
                  </Button>
                </Grid>
                <Grid item>
                  <ExcelExportButton
                    totalCount={bookTypes.totalCount}
                    fields={fields}
                    sorters={[...sorters, '"id" DESC']}
                    filters={filters}
                    variant="contained"
                    routeName="book-types"
                    fileName="vrste-knjiga"
                  />
                </Grid>
              </Grid>
            }
          />
          <Divider />
          {Object.keys(filters).filter((k) => filters[k])?.length !== 0 && (
            <>
              <Box ml={2} p={2} display="flex" alignItems="center" flexWrap="wrap">
                {Object.keys(filters).map(
                  (chip) =>
                    filters[chip] !== undefined && (
                      <FilterChip
                        color="primary"
                        variant="outlined"
                        key={chip}
                        label={`${getLabelText(chip)}: ${getChipValue(chip, filters)}`}
                        onDelete={() => handleChipDelete(chip, setFilters, setPage)}
                      />
                    ),
                )}
              </Box>
            </>
          )}
          <Table
            loading={loading}
            error={error}
            bookTypes={bookTypes.nodes}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            totalCount={bookTypes.totalCount}
            sorters={sorters}
            setSorters={setSorters}
            history={history}
            setSelectedBookType={setSelectedBookType}
            setModalVisible={setModalVisible}
            setDeleteModalVisible={setDeleteModalVisible}
          />
        </Card>
      </PageContentWrapper>
    </VerticalBoxContainer>
  );
};

export default BookTypes;
