import React, { useState } from 'react';
import { Card, CardHeader, Box, Divider, Grid, Button } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import CloseIcon from '@material-ui/icons/Close';
import {
  FilterChip,
  QuickSearch,
  useAPI,
  VerticalBoxContainer,
  dateFormatter,
} from '@shared/components';
import useGlobalAlertContext from 'hooks/useGlobalAlertContext';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import PostAddIcon from '@material-ui/icons/PostAdd';
import ExcelExportButton from '../../../../components/ExcelExportButton';
import axios from '../../../../utils/axios';
import useAuthContext from '../../../../hooks/useAuthContext';
import { handleChipDelete } from '../../../../utils/filterHelpers';
import { handleSortChange, getSortDirection } from '../../../../utils/sortHelpers';
import AddDocumentsModal from './Modal';
import List from './Table';
import Filter from './Filter';
import NewDocumentModal from './DocumentModal';

const exportFields = [
  {
    label: 'ID',
    value: 'id',
  },
  {
    label: 'Broj protokola',
    value: 'docProtocolNumber',
  },
  {
    label: 'Tip dokumenta',
    value: 'documentTypeName',
  },
  {
    label: 'Vrsta dokumenta',
    value: 'documentSort',
  },
  {
    label: 'Broj stranica',
    value: 'documentNoPages',
  },
  {
    label: 'Ekstenzija',
    value: 'documentExtension',
  },
  {
    label: 'Veličina fajla (bajt)',
    value: 'documentFilesize',
  },
  {
    label: 'Status',
    value: 'statusName',
  },
  {
    label: 'Datum kreiranja',
    value: 'dateCreated',
    type: 'dateTime',
  },
  {
    label: 'Korisnik kreirao',
    value: 'createdBy',
  },
  {
    label: 'Odgovorna osoba',
    value: 'responsiblePersonName',
  },
];

const getLabelText = (field) => {
  const map = {
    id: 'ID',
    documentTypeName: 'Tip dokumenta',
    documentName: 'Naziv dokumenta',
    documentNoPages: 'Broj stranica',
    documentExtension: 'Ekstenzija',
    documentFilesize: 'Veličina fajla (bajt)',
    dateCreated: 'Datum kreiranja',
    dateModified: 'Datum posljednje izmjene',
    createdBy: 'Korisnik kreirao',
    statusName: 'Status',
    organizationName: 'Organizacija',
    documentSort: 'Vrsta dokumenta',
    responsiblePersonName: 'Odgovorna osoba',
  };
  return map[field];
};

const getChipValue = (chip, filters) => {
  if (chip === 'dateCreated') return dateFormatter(filters[chip]);
  else if (chip === 'documentSort') return filters[chip] === 'U' ? 'Ulazni' : 'Izlazni';
  return filters[chip];
};

const Documents = ({ subjectId, editable, insertAllowed }) => {
  const { user } = useAuthContext();
  const [page, setPage] = useState(0);
  const [filters, setFilters] = useState({});
  const [triggerRerender, setRerender] = useState(false);
  const [newDocumentModalVisible, setNewDocumentModalVisible] = useState(false);
  const { sendSuccessAlertData, sendErrorAlertData } = useGlobalAlertContext();
  const [filterModalVisible, setFilterModalVisible] = useState(false);
  const [documentModalVisible, setDocumentModalVisible] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sorters, setSorters] = useState([]);

  const [documents = { nodes: [], totalCount: 0 }, error, loading] = useAPI(
    () =>
      axios
        .get(`subject/${subjectId}/documents`, {
          params: {
            limit: rowsPerPage,
            offset: page * rowsPerPage,
            conditions: filters,
            orderBy: [...sorters, '"docProtocolNumber" asc'],
          },
        })
        .then(({ data }) => data),
    [page, rowsPerPage, filters, sorters, triggerRerender],
  );

  const [workers] = useAPI(() => {
    return documents
      ? axios
          .get(`workers`, {
            params: {
              conditions: { username: user.username, groupAccordance: true },
              orderBy: [],
              limit: 1,
            },
          })
          .then(({ data }) => data)
      : Promise.resolve(() => ({ nodes: [], totalCount: 0 }));
  }, [documents]);

  return (
    <VerticalBoxContainer>
      <Filter
        currentValues={filters}
        visible={filterModalVisible}
        setVisible={setFilterModalVisible}
        setFilters={setFilters}
        setPage={setPage}
        getLabelText={getLabelText}
        getChipValue={getChipValue}
      />
      <NewDocumentModal
        workerId={workers?.nodes[0]?.id}
        subject={subjectId}
        document={selectedDocument}
        resetDocument={() => setSelectedDocument(undefined)}
        visible={newDocumentModalVisible}
        setVisible={setNewDocumentModalVisible}
        setRerender={setRerender}
        sendSuccessAlertData={sendSuccessAlertData}
        sendErrorAlertData={sendErrorAlertData}
      />
      <AddDocumentsModal
        visible={documentModalVisible}
        setPage={setPage}
        setVisible={setDocumentModalVisible}
        subjectId={subjectId}
        sendSuccessAlertData={sendSuccessAlertData}
        sendErrorAlertData={sendErrorAlertData}
        setRerender={setRerender}
        triggerRerender={triggerRerender}
        getLabelText={getLabelText}
      />

      <Box width="100%">
        <Card>
          <CardHeader
            title={
              <QuickSearch
                name="id"
                placeholder="Brza pretraga po ID-u"
                setFilters={setFilters}
                mainFilter={filters}
                setPage={setPage}
              />
            }
            action={
              <Grid container spacing={4}>
                <Grid item>
                  <Button
                    color="primary"
                    disabled={Object.keys(filters).filter((k) => filters[k])?.length === 0}
                    startIcon={<CloseIcon fontSize="small" />}
                    onClick={() => setFilters({})}
                  >
                    <Box fontSize="small">Ukloni sve filtere</Box>
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<FilterListIcon fontSize="small" />}
                    onClick={() => setFilterModalVisible(true)}
                  >
                    <Box fontSize="small">Filteri</Box>
                  </Button>
                </Grid>
                <Grid item>
                  <ExcelExportButton
                    totalCount={documents.totalCount}
                    sortById={false}
                    variant="outlined"
                    fields={exportFields}
                    sorters={[...sorters, '"docProtocolNumber" asc']}
                    filters={filters}
                    routeName={`subject/${subjectId}/documents`}
                    fileName={`dokumenti-predmeta-${subjectId}`}
                  />
                </Grid>
                {insertAllowed && (
                  <>
                    <Grid item>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => setDocumentModalVisible(true)}
                        startIcon={<PostAddIcon />}
                      >
                        <Box fontSize="small">Dodaj dokument</Box>
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        startIcon={<AddCircleOutlineOutlinedIcon />}
                        onClick={() => setNewDocumentModalVisible(true)}
                      >
                        <Box fontSize="small">Novi izlazni dokument</Box>
                      </Button>
                    </Grid>
                  </>
                )}
              </Grid>
            }
          />
          <Divider />
          {Object.keys(filters).filter((k) => filters[k])?.length !== 0 && (
            <>
              <Box ml={2} p={2} display="flex" alignItems="center" flexWrap="wrap">
                {Object.keys(filters).map(
                  (chip) =>
                    filters[chip] !== undefined && (
                      <FilterChip
                        color="primary"
                        variant="outlined"
                        key={chip}
                        label={`${getLabelText(chip)}: ${getChipValue(chip, filters)}`}
                        onDelete={() => handleChipDelete(chip, setFilters, setPage)}
                      />
                    ),
                )}
              </Box>
            </>
          )}
          <List
            workerId={workers?.nodes[0]?.id}
            loading={loading}
            error={error}
            documents={documents.nodes}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            totalCount={documents.totalCount}
            handleSortChange={handleSortChange}
            getSortDirection={getSortDirection}
            setSorters={setSorters}
            setSelectedDocumentFile={setSelectedDocument}
            setDocumentModalVisible={setNewDocumentModalVisible}
            sorters={sorters}
            sendSuccessAlertData={sendSuccessAlertData}
            editable={editable}
          />
        </Card>
      </Box>
    </VerticalBoxContainer>
  );
};

export default Documents;
