import React, { useState, useEffect } from 'react';
import {
  Grid,
  IconButton,
  SvgIcon,
  Box,
  Divider,
  Tooltip,
  TextField,
  Card,
  CardHeader,
  CardContent,
  MenuItem,
} from '@material-ui/core';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import { InputModal, FilterChip } from '@shared/components';
import { handleFilterChange, handleChipDelete } from '../../../utils/filterHelpers';
import useTheme from '../../../hooks/useTheme';

const Filter = ({
  currentValues,
  visible,
  setVisible,
  getLabelText,
  getChipValue,
  setFilters,
  setPage,
}) => {
  const theme = useTheme();

  const [filterValues, setFilterValues] = useState({});
  const handleClose = () => setVisible(false);

  const onSubmit = () => {
    handleFilterChange(filterValues, setFilters, setPage);
    setVisible(false);
  };

  useEffect(() => setFilterValues(currentValues), [currentValues]);
  return (
    <InputModal
      filterTitle="Pretraga predmeta"
      visible={visible}
      handleClose={handleClose}
      onSubmit={onSubmit}
      modalWidth="90%"
      theme={theme}
      filter
    >
      <Box padding={`${theme.spacing(5)}px`}>
        <Box p={2} display="flex" alignItems="center" flexWrap="wrap">
          {Object.keys(filterValues).map(
            (chip) =>
              filterValues[chip] !== undefined && (
                <FilterChip
                  color="primary"
                  variant="outlined"
                  key={chip}
                  label={`${getLabelText(chip)}: ${getChipValue(chip, filterValues)}`}
                  onDelete={() => handleChipDelete(chip, setFilterValues)}
                />
              ),
          )}
        </Box>
        <Grid spacing={6} container justifyContent="space-evenly">
          <Grid item xs={12} sm={4}>
            <Card style={{ height: '100%' }} elevation={3}>
              <CardHeader
                title="Osnovni podaci"
                titleTypographyProps={{ variant: 'subtitle2' }}
                action={
                  <Tooltip enterDelay={300} placement="top-end" arrow title="Ukloni filtere">
                    <IconButton
                      onClick={() => {
                        setFilterValues((prevState) => ({
                          ...prevState,
                          subjectNumber: undefined,
                          year: undefined,
                          ordNumber: undefined,
                          classificationMarkName: undefined,
                          bookTypeName: undefined,
                          name: undefined,
                        }));
                      }}
                    >
                      <SvgIcon fontSize="small">
                        <ClearAllIcon />
                      </SvgIcon>
                    </IconButton>
                  </Tooltip>
                }
              />
              <Divider />
              <CardContent>
                <Grid container spacing={5} direction="column">
                  <Grid item>
                    <TextField
                      label="Broj predmeta"
                      name="subjectNumber"
                      fullWidth
                      placeholder="Unesite broj predmeta za pretragu"
                      value={filterValues.subjectNumber || ''}
                      onChange={({ target: { value } }) =>
                        setFilterValues((prevState) => ({
                          ...prevState,
                          subjectNumber: value?.length === 0 ? undefined : value,
                        }))
                      }
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      fullWidth
                      label="Naziv predmeta"
                      name="name"
                      placeholder="Unesite naziv predmeta za pretragu"
                      value={filterValues.name || ''}
                      onChange={({ target: { value } }) =>
                        setFilterValues((prevState) => ({
                          ...prevState,
                          name: value?.length === 0 ? undefined : value,
                        }))
                      }
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      fullWidth
                      label="Klasifikaciona oznaka"
                      name="classificationMarkName"
                      placeholder="Unesite naziv klasifikacione oznake za pretragu"
                      value={filterValues.classificationMarkName || ''}
                      onChange={({ target: { value } }) =>
                        setFilterValues((prevState) => ({
                          ...prevState,
                          classificationMarkName: value?.length === 0 ? undefined : value,
                        }))
                      }
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      fullWidth
                      label="Vrsta knjige"
                      name="bookTypeName"
                      placeholder="Unesite naziv vrste knjige za pretragu"
                      value={filterValues.bookTypeName || ''}
                      onChange={({ target: { value } }) =>
                        setFilterValues((prevState) => ({
                          ...prevState,
                          bookTypeName: value?.length === 0 ? undefined : value,
                        }))
                      }
                    />
                  </Grid>
                  <Grid item container direction="row" spacing={6}>
                    <Grid item sm={6}>
                      <TextField
                        fullWidth
                        label="Godina"
                        name="year"
                        placeholder="Unesite godinu predmeta za pretragu"
                        value={filterValues.year || ''}
                        onChange={({ target: { value } }) =>
                          setFilterValues((prevState) => ({
                            ...prevState,
                            year: value?.length === 0 ? undefined : value,
                          }))
                        }
                      />
                    </Grid>
                    <Grid item sm={6}>
                      <TextField
                        fullWidth
                        label="Redni broj"
                        name="ordNumber"
                        type="number"
                        placeholder="Unesite redni broj predmeta za pretragu"
                        value={filterValues.ordNumber || ''}
                        onChange={({ target: { value } }) =>
                          setFilterValues((prevState) => ({
                            ...prevState,
                            ordNumber: value?.length === 0 ? undefined : value,
                          }))
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Card style={{ height: '100%' }} elevation={3}>
              <CardHeader
                title="Metapodaci"
                titleTypographyProps={{ variant: 'subtitle2' }}
                action={
                  <Tooltip enterDelay={300} placement="top-end" arrow title="Ukloni filtere">
                    <IconButton
                      onClick={() => {
                        setFilterValues((prevState) => ({
                          ...prevState,
                          organisationName: undefined,
                          responsiblePersonName: undefined,
                          archiveLocationName: undefined,
                          description: undefined,
                          subjectValid: undefined,
                          statusName: undefined,
                          ownSubject: undefined,
                        }));
                      }}
                    >
                      <SvgIcon fontSize="small">
                        <ClearAllIcon />
                      </SvgIcon>
                    </IconButton>
                  </Tooltip>
                }
              />
              <Divider />
              <CardContent>
                <Grid container spacing={5}>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Odgovorna osoba"
                      name="responsiblePersonName"
                      placeholder="Unesite odgovornu osobu za pretragu"
                      value={filterValues.responsiblePersonName || ''}
                      onChange={({ target: { value } }) =>
                        setFilterValues((prevState) => ({
                          ...prevState,
                          responsiblePersonName: value?.length === 0 ? undefined : value,
                        }))
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Organizacija"
                      name="organisationName"
                      placeholder="Unesite naziv organizacije za pretragu"
                      value={filterValues.organisationName || ''}
                      onChange={({ target: { value } }) =>
                        setFilterValues((prevState) => ({
                          ...prevState,
                          organisationName: value?.length === 0 ? undefined : value,
                        }))
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Arhivska lokacija predmeta"
                      name="archiveLocationName"
                      placeholder="Unesite arhivsku lokaciju predmeta za pretragu"
                      value={filterValues.archiveLocationName || ''}
                      onChange={({ target: { value } }) =>
                        setFilterValues((prevState) => ({
                          ...prevState,
                          archiveLocationName: value?.length === 0 ? undefined : value,
                        }))
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Opis"
                      name="description"
                      placeholder="Unesite opis predmeta za pretragu"
                      value={filterValues.description || ''}
                      onChange={({ target: { value } }) =>
                        setFilterValues((prevState) => ({
                          ...prevState,
                          description: value?.length === 0 ? undefined : value,
                        }))
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Status predmeta"
                      name="statusName"
                      placeholder="Unesite naziv statusa predmeta za pretragu"
                      value={filterValues.statusName || ''}
                      onChange={({ target: { value } }) =>
                        setFilterValues((prevState) => ({
                          ...prevState,
                          statusName: value?.length === 0 ? undefined : value,
                        }))
                      }
                    />
                  </Grid>
                  <Grid item container direction="row" spacing={6}>
                    <Grid item sm={6}>
                      <TextField
                        fullWidth
                        label="Važeći predmet"
                        select
                        name="subjectValid"
                        value={filterValues.subjectValid || ''}
                        variant="standard"
                        onChange={({ target: { value } }) =>
                          setFilterValues((prevState) => ({
                            ...prevState,
                            subjectValid: value || value?.length > 0 ? value : undefined,
                          }))
                        }
                      >
                        <MenuItem value="D">DA</MenuItem>
                        <MenuItem value="N">NE</MenuItem>
                      </TextField>
                    </Grid>
                    <Grid item sm={6}>
                      <TextField
                        fullWidth
                        label="Vlastiti predmet"
                        select
                        name="ownSubject"
                        value={filterValues.ownSubject || ''}
                        variant="standard"
                        onChange={({ target: { value } }) =>
                          setFilterValues((prevState) => ({
                            ...prevState,
                            ownSubject: value || value?.length > 0 ? value : undefined,
                          }))
                        }
                      >
                        <MenuItem value="D">DA</MenuItem>
                        <MenuItem value="N">NE</MenuItem>
                      </TextField>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Card style={{ height: '100%' }} elevation={3}>
              <CardHeader
                title="Datumi"
                titleTypographyProps={{ variant: 'subtitle2' }}
                action={
                  <Tooltip enterDelay={300} placement="top-end" arrow title="Ukloni filtere">
                    <IconButton
                      onClick={() => {
                        setFilterValues((prevState) => ({
                          ...prevState,
                          dateModified: undefined,
                          dateCreated: undefined,
                          archiveDate: undefined,
                          resolutionDeadline: undefined,
                          resolutionDate: undefined,
                        }));
                      }}
                    >
                      <SvgIcon fontSize="small">
                        <ClearAllIcon />
                      </SvgIcon>
                    </IconButton>
                  </Tooltip>
                }
              />
              <Divider />
              <CardContent>
                <Grid container spacing={5} direction="column">
                  <Grid item>
                    <TextField
                      label="Datum kreiranja"
                      name="dateCreated"
                      type="date"
                      fullWidth
                      placeholder="Unesite datum kreiranja za pretragu"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={filterValues.dateCreated || ''}
                      onChange={({ target: { value } }) =>
                        setFilterValues((prevState) => ({
                          ...prevState,
                          dateCreated: value?.length === 0 ? undefined : value,
                        }))
                      }
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      label="Datum ažuriranja"
                      name="dateModified"
                      type="date"
                      fullWidth
                      placeholder="Unesite datum ažuriranja za pretragu"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={filterValues.dateModified || ''}
                      onChange={({ target: { value } }) =>
                        setFilterValues((prevState) => ({
                          ...prevState,
                          dateModified: value?.length === 0 ? undefined : value,
                        }))
                      }
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      label="Rok za rješavanje predmeta"
                      name="resolutionDeadline"
                      type="date"
                      fullWidth
                      placeholder="Unesite rok za rješavanje predmeta za pretragu"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={filterValues.resolutionDeadline || ''}
                      onChange={({ target: { value } }) =>
                        setFilterValues((prevState) => ({
                          ...prevState,
                          resolutionDeadline: value?.length === 0 ? undefined : value,
                        }))
                      }
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      label="Datum rješavanja predmeta"
                      name="resolutionDate"
                      type="date"
                      fullWidth
                      placeholder="Unesite datum rješavanja predmeta za pretragu"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={filterValues.resolutionDate || ''}
                      onChange={({ target: { value } }) =>
                        setFilterValues((prevState) => ({
                          ...prevState,
                          resolutionDate: value?.length === 0 ? undefined : value,
                        }))
                      }
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      label="Datum arhiviranja predmeta"
                      name="archiveDate"
                      type="date"
                      fullWidth
                      placeholder="Unesite datum arhiviranja predmeta za pretragu"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={filterValues.archiveDate || ''}
                      onChange={({ target: { value } }) =>
                        setFilterValues((prevState) => ({
                          ...prevState,
                          archiveDate: value?.length === 0 ? undefined : value,
                        }))
                      }
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </InputModal>
  );
};

export default Filter;
