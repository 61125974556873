import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  Tooltip,
  TablePagination,
  Box,
  Typography,
  IconButton,
  SvgIcon,
} from '@material-ui/core';
import { useHistory, useRouteMatch } from 'react-router-dom';
import mime from 'mime-types';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import SendOutlinedIcon from '@material-ui/icons/SendOutlined';
import ErrorOutlineRoundedIcon from '@material-ui/icons/ErrorOutlineRounded';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { TableSkeleton, Spinner, dateFormatterTime, dateFormatterDate } from '@shared/components';
import { getSortDirection, handleSortChange } from '../../../utils/sortHelpers';
import useGlobalAlertContext from '../../../hooks/useGlobalAlertContext';
import axios from '../../../utils/axios';
import { ErrorMessage } from './style';

const DocumentsTable = ({
  documents,
  page,
  setPage,
  totalCount,
  rowsPerPage,
  setRowsPerPage,
  error,
  loading,
  sorters,
  setSorters,
  setSelectedDocument,
  selectedDocument,
  setStatusModalVisible,
  setDocumentModalVisible,
}) => {
  const history = useHistory();
  const { url } = useRouteMatch();
  const [downloadLoading, setDownloadLoading] = useState(false);
  const { sendErrorAlertData } = useGlobalAlertContext();

  const handleRowsPerPageChange = ({ target: { value } }) => {
    if (value * (page + 1) >= totalCount) setPage(Math.floor(totalCount / value));
    setRowsPerPage(value);
  };

  const getDocumentFile = async (id, extension) => {
    setDownloadLoading(true);
    try {
      const { data } = await axios.get(`get-document/${id}`);
      const { documentFile } = data;
      const urlDoc = window.URL.createObjectURL(
        new Blob([new Uint8Array(documentFile.data).buffer], {
          type: mime.lookup(extension) || 'application/pdf',
        }),
      );

      const link = document.createElement('a');
      link.href = urlDoc;

      // link.setAttribute('download', `dokument-${id}${extension}`);
      link.setAttribute('target', '_blank');

      link.click();
    } catch (documentError) {
      sendErrorAlertData(
        `${
          documentError?.response?.data?.details?.reason ||
          documentError?.response?.data?.error?.message ||
          `Došlo je do greške prilikom preuzimanja dokumenta`
        }`,
      );
    } finally {
      setDownloadLoading(false);
      setSelectedDocument(undefined);
    }
  };

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell width={50}>
            <Tooltip enterDelay={300} title="Sortiraj prema ID-u" placement="bottom-start">
              <TableSortLabel
                active={!!getSortDirection('id', sorters)}
                direction={getSortDirection('id', sorters)}
                onClick={() => handleSortChange('id', sorters, setSorters)}
              >
                ID
              </TableSortLabel>
            </Tooltip>
          </TableCell>
          <TableCell width={150}>
            <Tooltip
              enterDelay={300}
              title="Sortiraj prema tipu dokumenta"
              placement="bottom-start"
            >
              <TableSortLabel
                active={!!getSortDirection('documentTypeName', sorters)}
                direction={getSortDirection('documentTypeName', sorters)}
                onClick={() => handleSortChange('documentTypeName', sorters, setSorters)}
              >
                Tip dokumenta
              </TableSortLabel>
            </Tooltip>
          </TableCell>
          <TableCell width={50}>
            <Tooltip
              enterDelay={300}
              title="Sortiraj prema broju stranica"
              placement="bottom-start"
            >
              <TableSortLabel
                active={!!getSortDirection('documentNoPages', sorters)}
                direction={getSortDirection('documentNoPages', sorters)}
                onClick={() => handleSortChange('documentNoPages', sorters, setSorters)}
              >
                Broj stranica
              </TableSortLabel>
            </Tooltip>
          </TableCell>
          <TableCell width={50}>
            <Tooltip enterDelay={300} title="Sortiraj prema ekstenziji" placement="bottom-start">
              <TableSortLabel
                active={!!getSortDirection('documentExtension', sorters)}
                direction={getSortDirection('documentExtension', sorters)}
                onClick={() => handleSortChange('documentExtension', sorters, setSorters)}
              >
                Ekstenzija
              </TableSortLabel>
            </Tooltip>
          </TableCell>
          <TableCell width={100}>
            <Tooltip
              enterDelay={300}
              title="Sortiraj prema veličini fajla"
              placement="bottom-start"
            >
              <TableSortLabel
                active={!!getSortDirection('documentFilesize', sorters)}
                direction={getSortDirection('documentFilesize', sorters)}
                onClick={() => handleSortChange('documentFilesize', sorters, setSorters)}
              >
                Veličina fajla (bajt)
              </TableSortLabel>
            </Tooltip>
          </TableCell>
          <TableCell width={100}>
            <Tooltip
              enterDelay={300}
              title="Sortiraj prema datumu kreiranja"
              placement="bottom-start"
            >
              <TableSortLabel
                active={!!getSortDirection('dateCreated', sorters)}
                direction={getSortDirection('dateCreated', sorters)}
                onClick={() => handleSortChange('dateCreated', sorters, setSorters)}
              >
                Datum kreiranja
              </TableSortLabel>
            </Tooltip>
          </TableCell>
          <TableCell width={150}>
            <Tooltip enterDelay={300} title="Sortiraj prema korisniku" placement="bottom-start">
              <TableSortLabel
                active={!!getSortDirection('createdBy', sorters)}
                direction={getSortDirection('createdBy', sorters)}
                onClick={() => handleSortChange('createdBy', sorters, setSorters)}
              >
                Korisnik kreirao
              </TableSortLabel>
            </Tooltip>
          </TableCell>
          <TableCell width={140} />
        </TableRow>
      </TableHead>
      <TableBody>
        {error ? (
          <TableRow>
            <TableCell align="center" colSpan="100%" height={5 * 70.33}>
              <Box display="flex" justifyContent="center">
                <ErrorOutlineRoundedIcon fontSize="large" />
                <ErrorMessage>{error?.data?.details?.reason || 'Došlo je do greške'}</ErrorMessage>
              </Box>
            </TableCell>
          </TableRow>
        ) : loading || !documents ? (
          <TableRow>
            <TableCell align="center" colSpan="100%" style={{ paddingTop: '0' }}>
              <TableSkeleton size={rowsPerPage} />
            </TableCell>
          </TableRow>
        ) : documents?.length === 0 ? (
          <TableRow>
            <TableCell align="center" colSpan="100%" height={80}>
              <Typography>Nije pronađen niti jedan dokument</Typography>
            </TableCell>
          </TableRow>
        ) : (
          documents?.map((document) => (
            <TableRow key={document.id}>
              <TableCell width={50}> {document.id}</TableCell>
              <TableCell width={150}> {document.documentTypeName}</TableCell>
              <TableCell width={50}> {document.documentNoPages}</TableCell>
              <TableCell width={50}> {document.documentExtension}</TableCell>
              <TableCell width={100}> {document.documentFilesize}</TableCell>
              <TableCell width={100}>
                {dateFormatterDate(document.dateCreated)}
                <Typography variant="h6" color="textSecondary">
                  {dateFormatterTime(document.dateCreated)}
                </Typography>
              </TableCell>
              <TableCell width={120}> {document.createdBy}</TableCell>
              <TableCell width={180} align="right">
                <>
                  <Tooltip enterDelay={300} placement="top-end" arrow title="Promjena statusa">
                    <IconButton
                      onClick={() => {
                        setSelectedDocument(document);
                        setStatusModalVisible(true);
                      }}
                    >
                      <SvgIcon fontSize="small">
                        <SendOutlinedIcon />
                      </SvgIcon>
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    enterDelay={300}
                    placement="top-end"
                    arrow
                    title={
                      downloadLoading && document?.id === selectedDocument?.id
                        ? ''
                        : 'Prikaži dokument'
                    }
                  >
                    {downloadLoading && document?.id === selectedDocument?.id ? (
                      <IconButton size="small" disabled>
                        <Box pr={3}>
                          <Spinner color="primary" size={20} />
                        </Box>
                      </IconButton>
                    ) : (
                      <IconButton
                        onClick={() => {
                          setSelectedDocument(document);
                          getDocumentFile(document.id, document.documentExtension);
                        }}
                      >
                        <SvgIcon fontSize="small">
                          <InsertDriveFileOutlinedIcon />
                        </SvgIcon>
                      </IconButton>
                    )}
                  </Tooltip>
                  <Tooltip enterDelay={300} placement="top-end" arrow title="Uredi dokument">
                    <IconButton
                      onClick={() => {
                        setSelectedDocument(document);
                        setDocumentModalVisible(true);
                      }}
                    >
                      <SvgIcon fontSize="small">
                        <EditOutlinedIcon />
                      </SvgIcon>
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    enterDelay={300}
                    placement="top-end"
                    arrow
                    title="Detaljni pregled dokumenta"
                  >
                    <span>
                      <IconButton
                        onClick={() =>
                          history.push({
                            pathname: `${url}/${document.id}`,
                          })
                        }
                      >
                        <SvgIcon fontSize="small">
                          <ArrowForwardIcon />
                        </SvgIcon>
                      </IconButton>
                    </span>
                  </Tooltip>
                </>
              </TableCell>
            </TableRow>
          ))
        )}
        <TableRow>
          <TablePagination
            width="100%"
            count={totalCount}
            page={page}
            labelRowsPerPage="Broj redova po stranici"
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleRowsPerPageChange}
            rowsPerPageOptions={[5, 10, 20, 25]}
            onPageChange={(_, newPage) => setPage(newPage)}
          />
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default DocumentsTable;
