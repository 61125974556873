import React, { useState } from 'react';
import {
  CardHeader,
  Divider,
  Grid,
  Button,
  Box,
  Card,
  Breadcrumbs,
  Typography,
  Link,
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import FilterListIcon from '@material-ui/icons/FilterList';
import {
  VerticalBoxContainer,
  PageContentWrapper,
  QuickSearch,
  FilterChip,
  useAPI,
  DeleteModal,
} from '@shared/components';
import PageHeader from '../../components/PageHeader';
import axios from '../../utils/axios';
import useTheme from '../../hooks/useTheme';
import ExcelExportButton from '../../components/ExcelExportButton';
import useGlobalAlertContext from '../../hooks/useGlobalAlertContext';
import { handleChipDelete } from '../../utils/filterHelpers';
import ClassificationMarkTable from './Table';
import Filter from './Filter';
import ClassMarkModal from './Modal';

const fields = [
  { label: 'ID', value: 'id' },
  { label: 'Šifra', value: 'code' },
  { label: 'Naziv', value: 'name' },
  { label: 'Važi', value: 'isValid' },
  { label: 'Rok čuvanja', value: 'retentionPeriod' },
  { label: 'Opis', value: 'description' },
];

const getLabelText = (field) => {
  const map = {
    id: 'ID',
    code: 'Šifra',
    name: 'Naziv',
    isValid: 'Važi',
    retentionPeriod: 'Rok čuvanja',
    description: 'Opis',
  };
  return map[field];
};

const getChipValue = (chip, filters) => {
  if (chip === 'isValid') return filters[chip] === 'D' ? 'DA' : 'NE';
  return filters[chip];
};

const ClassificationMark = () => {
  const theme = useTheme();
  const [sorters, setSorters] = useState([]);
  const [filters, setFilters] = useState({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [triggerRerender, setRerender] = useState(false);
  const [filterModalVisible, setFilterModalVisible] = useState(false);
  const [classMarkModalVisible, setClassMarkModalVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [selectedUnit, setSelectedUnit] = useState();

  const { sendSuccessAlertData, sendErrorAlertData } = useGlobalAlertContext();

  const [classMarks = { nodes: [], totalCount: 0 }, error, loading] = useAPI(
    () =>
      axios
        .get(`classification-marks`, {
          params: {
            limit: rowsPerPage,
            offset: page * rowsPerPage,
            conditions: filters,
            orderBy: [...sorters, "'id' desc"],
          },
        })
        .then(({ data }) => data),
    [page, rowsPerPage, filters, sorters, triggerRerender],
  );

  const handleFilterChange = (filterValues) => {
    setPage(0);
    setFilters({ ...filterValues });
  };
  return (
    <VerticalBoxContainer>
      <Filter
        currentValues={filters}
        visible={filterModalVisible}
        setVisible={setFilterModalVisible}
        handleFilterChange={handleFilterChange}
        getLabelText={getLabelText}
        getChipValue={getChipValue}
        setFilters={setFilters}
        setPage={setPage}
      />
      <ClassMarkModal
        classMark={selectedUnit}
        setClassMark={setSelectedUnit}
        resetClassMark={() => setSelectedUnit(undefined)}
        visible={classMarkModalVisible}
        setVisible={setClassMarkModalVisible}
        setRerender={setRerender}
        sendSuccessAlertData={sendSuccessAlertData}
        sendErrorAlertData={sendErrorAlertData}
      />
      <DeleteModal
        objectId={selectedUnit?.id}
        title={`Ukloni klasifikacionu oznaku ${
          selectedUnit?.name ? `sa šifrom ${selectedUnit?.code}` : ''
        }`}
        path="classification-marks"
        message="Uspješno izbrisana klasifikaciona oznaka"
        buttonText="Ukloni klasifikacionu oznaku"
        promptText={`Da li ste sigurni da želite izbrisati klasifikacionu oznku ${
          selectedUnit?.name ? `sa šifrom ${selectedUnit?.code}` : ''
        }?`}
        resetObject={() => setSelectedUnit(undefined)}
        visible={deleteModalVisible}
        setVisible={setDeleteModalVisible}
        sendSuccessAlertData={sendSuccessAlertData}
        sendErrorAlertData={sendErrorAlertData}
        setRerender={setRerender}
        setPage={setPage}
        page={page}
        dataLength={classMarks.nodes.length}
        axios={axios}
        theme={theme}
      />
      <PageHeader
        title="Klasifikacione oznake"
        extra={
          <Grid container spacing={4}>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddCircleOutlineOutlinedIcon />}
                onClick={() => setClassMarkModalVisible(true)}
              >
                <Box fontSize="small">Nova oznaka</Box>
              </Button>
            </Grid>
          </Grid>
        }
      />
      <PageContentWrapper>
        <Breadcrumbs style={{ paddingBottom: `${theme.spacing(5)}px` }}>
          <Link variant="body1" color="inherit" to="/" component={RouterLink}>
            Početna
          </Link>
          <Typography variant="body1" color="textPrimary">
            Šifarnik: Klasifikacione oznake
          </Typography>
        </Breadcrumbs>
        <Card>
          <CardHeader
            title={
              <>
                <QuickSearch
                  name="name"
                  placeholder="Brza pretraga po nazivu"
                  setFilters={setFilters}
                  mainFilter={filters}
                  setPage={setPage}
                />
              </>
            }
            action={
              <Grid container spacing={4}>
                <Grid item>
                  <Button
                    color="primary"
                    disabled={Object.keys(filters).filter((k) => filters[k])?.length === 0}
                    startIcon={<CloseIcon fontSize="small" />}
                    onClick={() => setFilters({})}
                  >
                    <Box fontSize="small">Ukloni sve filtere</Box>
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<FilterListIcon fontSize="small" />}
                    onClick={() => setFilterModalVisible(true)}
                  >
                    <Box fontSize="small">Filteri</Box>
                  </Button>
                </Grid>
                <Grid item>
                  <ExcelExportButton
                    fields={fields}
                    sorters={[...sorters, "'id' desc"]}
                    filters={{}}
                    routeName="classification-marks"
                    fileName="sifarnik-klasifikacionih-oznaka"
                    totalCount={classMarks.totalCount}
                  />
                </Grid>
              </Grid>
            }
          />
          <Divider />
          {Object.keys(filters).filter((k) => filters[k])?.length !== 0 && (
            <>
              <Box ml={2} p={2} display="flex" alignItems="center" flexWrap="wrap">
                {Object.keys(filters).map(
                  (chip) =>
                    filters[chip] !== undefined && (
                      <FilterChip
                        color="primary"
                        variant="outlined"
                        key={chip}
                        label={`${getLabelText(chip)}: ${getChipValue(chip, filters)}`}
                        onDelete={() => handleChipDelete(chip, setFilters, setPage)}
                      />
                    ),
                )}
              </Box>
            </>
          )}
          <ClassificationMarkTable
            loading={loading}
            error={error}
            classMarks={classMarks.nodes}
            page={page}
            setPage={setPage}
            sorters={sorters}
            setSorters={setSorters}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            totalCount={classMarks.totalCount}
            setSelectedUnit={setSelectedUnit}
            setDeleteModalVisible={setDeleteModalVisible}
            setClassMarkModalVisible={setClassMarkModalVisible}
          />
        </Card>
      </PageContentWrapper>
    </VerticalBoxContainer>
  );
};

export default ClassificationMark;
