import React from 'react';
import { FormControlLabel, Checkbox, Tooltip } from '@material-ui/core';
import { Controller } from 'react-hook-form';
import { useFormControlLabelStyles } from './style';

const FormCheckbox = ({
  defaultChecked,
  labelPlacement = 'top',
  disabled,
  name,
  label,
  reff,
  backgroundColor,
  checkedColor,
  uncheckedColor,
  showTooltip = true,
  tooltipPlacement = 'top',
  onKeyDown,
  checkboxSize,
  removePadding,
  control, // required
  theme, // required
  ...props
}) => {
  const formControlLabelClass = useFormControlLabelStyles({
    backgroundColor,
    theme,
  });

  return (
    <FormControlLabel
      {...props}
      classes={formControlLabelClass}
      labelPlacement={labelPlacement}
      control={
        <Controller
          defaultValue={defaultChecked}
          control={control}
          name={name}
          render={(controllerProps) => (
            <Tooltip
              placement={tooltipPlacement}
              enterDelay={300}
              enterNextDelay={300}
              title={showTooltip ? (controllerProps.value ? 'DA' : 'NE') : ''}
            >
              <span>
                <Checkbox
                  {...controllerProps}
                  inputRef={(e) => {
                    controllerProps.ref.current = e;
                    if (reff) reff.current = e;
                  }}
                  onKeyDown={onKeyDown}
                  checked={controllerProps.value}
                  inputProps={{
                    'aria-label': `${name}-${controllerProps.value ? 'checked' : 'unchecked'}`,
                  }}
                  onChange={({ target: { checked } }) => controllerProps.onChange(checked)}
                  classes={removePadding ? { root: formControlLabelClass.root } : {}}
                  disabled={disabled}
                  size={checkboxSize}
                />
              </span>
            </Tooltip>
          )}
        />
      }
      label={label}
    />
  );
};

export default FormCheckbox;
