import React, { useState, useEffect } from 'react';
import {
  Box,
  CardContent,
  Divider,
  Grid,
  TextField,
  FormHelperText,
  Typography,
  CardHeader,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormLabel,
  MenuItem,
  Card,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Checkbox,
  Tooltip,
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { Alert } from '@material-ui/lab';
import ErrorOutlineRoundedIcon from '@material-ui/icons/ErrorOutlineRounded';
import { useParams } from 'react-router';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { documentConsentSchema, documentConsentUpdateSchema } from '@shared/partner-schemas';
import { InputModal, FormAutocomplete, FormSelect, useAPI } from '@shared/components'
import axios from '../../../../../utils/axios';
import useTheme from '../../../../../hooks/useTheme';
import { ContentBox, ErrorMessage } from './style';

const FooterInfo = () => {
  return (
    <Alert
      severity="warning"
      style={{ fontSize: '.8em', paddingBottom: '0', paddingTop: '0', margin: '0' }}
    >
      {`Promjenom saglasnosti na 'Da' ili 'Ne' više nećete biti u mogućnosti ažurirati
      saglasnost.`}
    </Alert>
  );
};

const ConsentsModal = ({
  document,
  selectedConsent,
  user,
  setSelectedConsent,
  visible,
  setVisible,
  sendSuccessAlertData,
  sendErrorAlertData,
  resetConsent,
  setRerender,
  documentLoading,
  setDocumentRerender,
  userIsResponsiblePerson = false,
}) => {
  const documentId = useParams().id;
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState([]);
  const [errorAlertOpen, setErrorAlertOpen] = useState(false);
  const [requestError, setRequestError] = useState();
  const theme = useTheme();
  const [workerInsert, setWorkerInsert] = useState(true);
  const defaultData = { nodes: [], totalCount: 0 };
  const [reRenderWorker, setReRenderWorker] = useState(false);
  const [reRenderOrganisation, setReRenderOrganisation] = useState(false);

  //  deleted register, errors, control --- const { register, handleSubmit, clearErrors, errors, reset, control }
  const { register, handleSubmit, clearErrors, errors, reset, control, watch, setValue } = useForm(
    !(!!selectedConsent && userIsResponsiblePerson) && {
      mode: 'onChange',
      resolver: yupResolver(selectedConsent ? documentConsentUpdateSchema : documentConsentSchema),
    },
  );
  useEffect(() => {
    setLoading(false);
    setRequestError(null);
    clearErrors();
    reset();
  }, []);

  const handleClose = () => {
    clearErrors();
    setVisible(false);
    resetConsent();
  };
  const handleAlertClose = () => setErrorAlertOpen(false);
  const workerData = watch('worker', { organizationName: '' });
  const consentField = watch('consent', selectedConsent?.consent || 'I');

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  useEffect(() => {
    if (workerData?.organizationName) {
      setValue('organisationName', workerData?.organizationName || '');
    } else {
      setValue('organisationName', '');
    }
  }, [workerData?.organizationName]);

  const onSubmit = async (values) => {
    setLoading(true);
    let object;
    if (workerInsert) {
      object = {
        comment: values?.comment,
        worker: values?.worker?.id,
        consentComment: values?.consentComment,
        organisation: null,
        consent: 'I',
        documentId: document?.id,
      };
    } else {
      object = {
        comment: values?.comment,
        organisation: values?.organisation?.id,
        consentComment: values?.consentComment,
        worker: null,
        consent: 'I',
        documentId: document?.id,
      };
    }

    if (selectedConsent) {
      object = {
        ...selectedConsent,
        consent: values?.consent,
        consentComment: values?.consentComment,
      };
    }
    // const values = { id, test };
    const attributesAllowed =
      attributes.nodes?.length &&
      attributes.nodes.map((attribute) => {
        if (checked.includes(attribute.id)) return { id: attribute.id, allowed: true };
        else return { id: attribute.id, allowed: false };
      });

    try {
      const { data } = selectedConsent
        ? await axios.put(`document-consents/${selectedConsent.id}`, {
            documentConsent: { ...object },
            attributesAllowed, // TODO UPSERT ???
          })
        : await axios.post(`document-consents`, {
            documentConsent: { ...object },
            attributesAllowed, // TODO UPSERT ???
          });
      if (!data) throw new Error();
      sendSuccessAlertData(
        `Uspješno ${selectedConsent ? 'izmijenjena' : 'dodana'} saglasnost na dokumentu`,
      );
      if (setSelectedConsent) setSelectedConsent((prevState) => ({ ...prevState, ...data }));
      setRerender((prevState) => !prevState);
      setDocumentRerender((prevState) => !prevState);
      handleClose();
    } catch (error) {
      sendErrorAlertData(
        `${
          error?.response?.data?.details?.reason ||
          error?.response?.data?.error?.message ||
          `Došlo je do greške prilikom ${
            selectedConsent ? 'uređivanja' : 'unosa'
          } saglasnosti na dokumentu`
        }`,
      );
    } finally {
      setLoading(false);
    }
  };

  const [
    acData = {
      organisationName: [],
      workerName: [],
    },
    acError,
    acLoading,
  ] = useAPI(() => {
    return selectedConsent && visible
      ? Promise.all([
          selectedConsent.organisation
            ? axios.get(`organisations`, {
                params: {
                  limit: 10,
                  conditions: selectedConsent
                    ? {
                        id: selectedConsent.organisation,
                      }
                    : {},
                },
              })
            : Promise.resolve(() => defaultData),
          selectedConsent.worker
            ? axios.get(`workers`, {
                params: {
                  limit: 10,
                  conditions: selectedConsent
                    ? {
                        id: selectedConsent.worker,
                        groupAccordance: true,
                      }
                    : {},
                },
              })
            : Promise.resolve(() => defaultData),
        ]).then(([{ data: organisationName }, { data: workerName }]) => ({
          organisationName,
          workerName,
        }))
      : Promise.resolve(() => defaultData);
  }, [selectedConsent]);

  const [attributes = { nodes: [], totalCount: 0 }, attributesError, attributesLoading] = useAPI(
    () =>
      visible
        ? axios
            .get(`document-user-attributes`, {
              params: {
                conditions: { document: document.id, workerId: selectedConsent?.worker },
                orderBy: ['"id" asc'],
              },
            })
            .then(({ data }) => {
              setChecked(
                data.nodes.map(
                  (node) => (node.allowedToEdit === 'D' || !selectedConsent) && node.id,
                ),
              );
              return data;
            })
        : Promise.resolve(() => {}),
    [visible],
  );

  return (
    <InputModal
      object={selectedConsent}
      createTitle="Kreiraj novu saglasnost na dokumentu"
      updateTitle={`Uredi saglasnost na dokumentu ${selectedConsent?.id}`}
      createButtonText="Kreiraj saglasnost"
      updateButtonText="Uredi saglasnost"
      visible={visible}
      onSubmit={onSubmit}
      handleSubmit={handleSubmit}
      handleClose={handleClose}
      handleAlertClose={handleAlertClose}
      requestError={requestError}
      errorAlertOpen={errorAlertOpen}
      modalWidth="50%"
      loading={loading || documentLoading}
      showHeader
      theme={theme}
      footerInfo={(consentField === 'D' || consentField === 'N') && <FooterInfo />}
    >
      <ContentBox>
        {acError ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height={265}
            minWidth="100%"
          >
            <Box display="flex" justifyContent="center">
              <ErrorOutlineRoundedIcon fontSize="large" />
              <ErrorMessage>
                <Typography variant="h3">
                  {acError?.data?.details?.reason || 'Došlo je do greške'}
                </Typography>
              </ErrorMessage>
            </Box>
          </Box>
        ) : (
          <Grid container spacing={6}>
            <Grid item xs={6}>
              <Card style={{ height: '100%' }} elevation={3}>
                <CardHeader
                  title="Osnovni podaci"
                  titleTypographyProps={{ variant: 'subtitle2' }}
                />
                <Divider />
                <CardContent>
                  {!selectedConsent ? (
                    <Box>
                      <FormLabel id="worker-organisation-select">Kreiraj saglasnost:</FormLabel>
                      <RadioGroup
                        aria-labelledby="worker-organisation-btnGrp"
                        defaultValue="worker"
                        name="w-o-radio-group"
                        disabled
                      >
                        <FormControlLabel
                          disabled
                          value="worker"
                          control={<Radio />}
                          label="Radnika"
                          onChange={() => {
                            setWorkerInsert(true);
                            setReRenderWorker(!reRenderWorker);
                            setReRenderOrganisation(!reRenderOrganisation);
                          }}
                        />
                        <FormControlLabel
                          disabled
                          value="organisation"
                          control={<Radio />}
                          label="Organizacije"
                          onChange={() => {
                            setWorkerInsert(false);
                            setReRenderWorker(!reRenderWorker);
                            setReRenderOrganisation(!reRenderOrganisation);
                          }}
                        />
                      </RadioGroup>
                    </Box>
                  ) : null}
                  <Box>
                    <FormAutocomplete
                      error={acError || !!errors.worker}
                      fullWidth
                      label="Radnik"
                      name="worker"
                      placeholder="Unesite dva ili više karaktera"
                      routeName="workers"
                      initialData={acData.workerName}
                      key={reRenderWorker}
                      optionsLabel="fullName"
                      showSide="code"
                      variant="standard"
                      control={control}
                      axios={axios}
                      register={register}
                      defaultConditions={{ removeAdded: true, documentId }}
                      acDisabled={!workerInsert || !!selectedConsent || !userIsResponsiblePerson}
                      loading={acLoading}
                    />
                    {errors.worker && (
                      <FormHelperText error>{errors.worker.message}</FormHelperText>
                    )}
                  </Box>
                  <Box mt={5}>
                    {/* <FormAutocomplete
                      error={acError || !!errors.organisationName}
                      fullWidth
                      label="Organizacija"
                      name="organisation"
                      key={reRenderOrganisation}
                      placeholder="Unesite dva ili više karaktera"
                      routeName="organisations"
                      initialData={acData.organisationName}
                      optionsLabel="name"
                      showSide="code"
                      variant="standard"
                      control={control}
                      axios={axios}
                      register={register}
                      acDisabled={workerInsert || !!selectedConsent}
                      loading={acLoading}
                    />
                    {errors.organisationName && (
                      <FormHelperText error>{errors.organisationName.message}</FormHelperText>
                    )} */}
                    <TextField
                      fullWidth
                      name="organisationName"
                      disabled
                      label="Organizacija"
                      inputRef={register}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Box>
                  <Box mt={5}>
                    <TextField
                      error={!!errors.comment}
                      fullWidth
                      name="comment"
                      id="comment"
                      label="Komentar"
                      inputRef={register}
                      defaultValue={selectedConsent?.comment}
                      placeholder="Unesite komentar"
                      disabled={!!selectedConsent || !userIsResponsiblePerson}
                    />
                    {errors.comment && (
                      <FormHelperText error>{errors.comment.message}</FormHelperText>
                    )}
                  </Box>
                  {selectedConsent &&
                  user?.username?.toLowerCase() ===
                    selectedConsent?.workerUsername?.toLowerCase() &&
                  selectedConsent?.consent === 'I' ? (
                    <Box mt={5}>
                      <FormSelect
                        id="consent"
                        name="consent"
                        label="Saglasan"
                        required
                        error={!!errors.consent}
                        defaultValue={selectedConsent?.consent}
                        control={control}
                        fullWidth
                      >
                        <MenuItem value="D">DA</MenuItem>
                        <MenuItem value="N">NE</MenuItem>
                        <MenuItem value="I">U TOKU</MenuItem>
                      </FormSelect>
                      {errors.consent && (
                        <FormHelperText error>{errors.consent.message}</FormHelperText>
                      )}
                    </Box>
                  ) : null}
                  <Box mt={5}>
                    <TextField
                      error={!!errors.consentComment}
                      fullWidth
                      name="consentComment"
                      id="consentComment"
                      label="Komentar saglasnost"
                      inputRef={register}
                      defaultValue={selectedConsent?.consentComment}
                      placeholder="Unesite komentar saglasnost"
                      disabled={
                        !(
                          user?.username?.toLowerCase() ===
                            selectedConsent?.workerUsername?.toLowerCase() &&
                          selectedConsent?.consent === 'I'
                        )
                      }
                    />
                    {errors.consentComment && (
                      <FormHelperText error>{errors.consentComment.message}</FormHelperText>
                    )}
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={6}>
              <Card elevation={3} style={{ height: '100%' }}>
                <CardHeader
                  title={
                    userIsResponsiblePerson
                      ? 'Postavljanje privilegija ažuriranja atributa za radnika'
                      : `Pregled privilegija ažuriranja atributa za radnika ${selectedConsent?.workerUsername}`
                  }
                  titleTypographyProps={{ variant: 'subtitle2' }}
                />
                <CardContent
                  style={{ padding: 0, paddingBottom: `${theme.spacing(5)}px`, height: '100%' }}
                >
                  {attributesLoading ? (
                    <Box height={300} style={{ padding: `${theme.spacing(4)}px` }}>
                      {[...Array(8).keys()].map((i) => (
                        <Skeleton animation="wave" key={i} height={35} />
                      ))}
                    </Box>
                  ) : attributesError ? (
                    <Box height={300} style={{ padding: `${theme.spacing(4)}px` }}>
                      <Typography
                        variant="h3"
                        style={{ padding: `${theme.spacing(4)}px 0 0` }}
                        align="center"
                      >
                        {attributesError?.data?.details?.reason || 'Došlo je do greške'}
                      </Typography>
                    </Box>
                  ) : (
                    <List
                      dense
                      style={{
                        maxHeight: 400,
                        overflow: 'auto',
                        height: '100%',
                        // padding: `${theme.spacing(4)}px`,
                      }}
                    >
                      <ListItem divider>
                        <ListItemText
                          primary="Atribut"
                          primaryTypographyProps={{ style: { fontWeight: 700 } }}
                        />
                        <ListItemSecondaryAction>
                          <Tooltip
                            enterDelay={300}
                            title={
                              checked.length !== attributes?.nodes?.length
                                ? 'Uključi sve'
                                : 'Isključi sve'
                            }
                          >
                            <Checkbox
                              edge="end"
                              checked={checked.length === attributes?.nodes?.length}
                              indeterminate={
                                checked.length > 0 && checked.length < attributes?.nodes?.length
                              }
                              onClick={() => {
                                if (checked.length === attributes?.nodes?.length) {
                                  setChecked([]);
                                } else {
                                  setChecked(attributes?.nodes?.map((node) => node.id));
                                }
                              }}
                              disableRipple
                            />
                          </Tooltip>
                        </ListItemSecondaryAction>
                      </ListItem>
                      {attributes?.nodes?.map((value) => {
                        const labelId = `checkbox-lista-atributa-${value}`;
                        return (
                          <ListItem
                            key={value.id}
                            button
                            disabled={!userIsResponsiblePerson}
                            onClick={handleToggle(value.id)}
                          >
                            <ListItemText id={labelId} primary={value.attributeName} />
                            <ListItemSecondaryAction>
                              <Checkbox
                                edge="end"
                                checked={checked.indexOf(value.id) !== -1}
                                onClick={handleToggle(value.id)}
                                disableRipple
                                disabled={!userIsResponsiblePerson}
                                inputProps={{ 'aria-labelledby': labelId }}
                              />
                            </ListItemSecondaryAction>
                          </ListItem>
                        );
                      })}
                    </List>
                  )}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        )}
      </ContentBox>
    </InputModal>
  );
};

export default ConsentsModal;
