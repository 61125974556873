import React, { useState } from 'react';
import {
  Card,
  CardHeader,
  Box,
  Divider,
  Grid,
  Button,
  Tabs,
  Tab,
  Avatar,
  useTheme,
} from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import CloseIcon from '@material-ui/icons/Close';
import {
  FilterChip,
  QuickSearch,
  useAPI,
  dateFormatter,
  VerticalBoxContainer,
  PageContentWrapper,
  ConfirmDialog,
  UnsavedChangesDialog,
} from '@shared/components';
import { useForm } from 'react-hook-form';
import axios from '../../../../utils/axios';
import attributeFieldValues from '../../../../utils/attributeFieldValues';
import { handleChipDelete } from '../../../../utils/filterHelpers';
import ExcelExportButton from '../../../../components/ExcelExportButton';
import useGlobalAlertContext from '../../../../hooks/useGlobalAlertContext';
import { handleSortChange, getSortDirection } from '../../../../utils/sortHelpers';
import Filter from './Filter';
import Modal from './Modal';
import AttributeListModal from './TableModal';
import Table from './Table';

const fields = [
  {
    label: 'ID',
    value: 'id',
  },
  {
    label: 'Atribut (Metapodatak)',
    value: 'attributeName',
  },
  {
    label: 'Vrijednost',
    value: 'valueDisplayed',
  },
  {
    label: 'Datum kreiranja',
    value: 'dateCreated',
    type: 'dateTime',
  },
  {
    label: 'Korisnik kreirao',
    value: 'createdBy',
  },
];

const getLabelText = (value) => {
  const type = {
    id: 'ID',
    attributeName: 'Atribut (Metpodatak)',
    valueDisplayed: 'Vrijednost',
    dateCreated: 'Datum kreiranja',
    createdBy: 'Korisnik kreirao',
  };
  return type[value];
};

const getChipValue = (chip, filters) => {
  if (chip === 'dateCreated') return dateFormatter(filters[chip]);
  return filters[chip];
};

const DocumentAttributes = ({ documentId, documentLoading }) => {
  const [page, setPage] = useState(0);
  const theme = useTheme();
  const [selectedGroup, setSelectedGroup] = useState('all');
  const [filters, setFilters] = useState({});
  const [triggerRerender, setRerender] = useState(false);
  const [filterModalVisible, setFilterModalVisible] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [sorters, setSorters] = useState([]);
  const [selectedAttribute, setSelectedAttribute] = useState();
  const [journalModalVisible, setJournalModalVisible] = useState(false);
  const [dialogVisible, setDialogVisible] = useState(false);
  const { sendSuccessAlertData, sendErrorAlertData } = useGlobalAlertContext();

  const [attributes = { nodes: [], totalCount: 0 }, error, loading] = useAPI(
    () =>
      !documentLoading
        ? axios
          .get(`document-attributes`, {
            params: {
              limit: rowsPerPage,
              offset: page * rowsPerPage,
              conditions: {
                ...filters,
                document: documentId,
                ...(selectedGroup !== 'all' && { attributeGroupId: selectedGroup }),
              },
              orderBy: [...sorters, '"ordNumber" asc'],
            },
          })
          .then(({ data }) => {
            if (data.nodes) methods.reset();
            return data;
          })
        : Promise.resolve(() => { }),
    [page, rowsPerPage, filters, sorters, triggerRerender, documentLoading, selectedGroup],
  );

  const [attributeGroups = { nodes: [] }] = useAPI(
    () =>
      !documentLoading
        ? axios.get(`attribute-groups/${documentId}`).then(({ data }) => data)
        : Promise.resolve(() => { }),
    [triggerRerender, documentLoading],
  );

  const getRandomColorById = (id) => {
    const colors = theme.palette.chart_colors;
    return colors[id % colors.length];
  };

  // attributes table update
  const methods = useForm();

  const onSubmit = async (values) => {
    const outputArray = Object.entries(values).map(([key, value]) => {
      const objId = key.split('-')[1];
      const attributeObject = attributes.nodes.find((obj) => obj.id.toString() === objId);
      return {
        id: attributeObject.id,
        attribute: attributeObject.attribute,
        document: attributeObject.document,
        documentType: attributeObject.documentType,
        ...attributeFieldValues({ valueDisplayed: value }, attributeObject),
      };
    });
    const { data } = await axios.put(`document-attributes/update/many`, {
      documentAttribute: [...outputArray],
    });
    if (!data) throw new Error();
    setRerender(!triggerRerender);
    methods.reset(values);
    setDialogVisible(false);
    sendSuccessAlertData(`Uspješno izmijenjenjeni atributi dokumenta`);
  };

  const handleTabelReset = () => {
    const values = attributes.nodes.reduce((acc, attribute) => {
      if (attribute.attributeType === 1) {
        acc[`valueDisplayed-${attribute.id}`] = attribute.valueDisplayed;
      }
      return acc;
    }, {});
    methods.reset(values);
    sendSuccessAlertData(`Izmjene uspješno izbrisane`);
  };


  return (
    <VerticalBoxContainer>
      <UnsavedChangesDialog methods={methods} attributes={attributes.nodes} loading={loading} />
      <ConfirmDialog
        open={dialogVisible}
        setOpen={setDialogVisible}
        title="Izmjena atributa dokumenta"
        message="Da li ste sigurni da želite izmijeniti atribute dokumenta?"
        onSubmit={methods.handleSubmit(onSubmit)}
        loading={loading}
        confirmText="Potvrdi"
        denyText="Odustani"
      />
      <Filter
        currentValues={filters}
        visible={filterModalVisible}
        setVisible={setFilterModalVisible}
        setFilters={setFilters}
        setPage={setPage}
        getLabelText={getLabelText}
        getChipValue={getChipValue}
      />
      <Modal
        documentId={documentId}
        attribute={selectedAttribute}
        setSelectedAttribute={setSelectedAttribute}
        visible={modalVisible}
        setVisible={setModalVisible}
        setRerender={setRerender}
        resetAttribute={() => setSelectedAttribute(null)}
        sendSuccessAlertData={sendSuccessAlertData}
        sendErrorAlertData={sendErrorAlertData}
      />
      <AttributeListModal
        documentId={documentId}
        visible={journalModalVisible}
        setVisible={setJournalModalVisible}
        title="Journal promjena atributa"
        buttonText="Odustani"
      />
      <PageContentWrapper style={{ paddingTop: '4px', paddingRight: '2px', paddingLeft: '2px' }}>
        <Box
          width="100%"
          bgcolor="background.paper"
          borderRadius="2px 2px 0"
          border={`1px solid ${theme.palette.divider}`}
          borderBottom={0}
          m="-1px"
        >
          <Tabs
            value={selectedGroup}
            onChange={(e, value) => setSelectedGroup(value)}
            indicatorColor="primary"
            aria-label="attribute group tabs"
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab
              style={{
                minWidth: '0',
              }}
              value="all"
              label={
                <Box
                  display="flex"
                  alignItems="center"
                  flexDirection="row"
                  justifyContent="center"
                  gridGap={5}
                >
                  <Avatar
                    variant="rounded"
                    style={{
                      backgroundColor: getRandomColorById('all'),
                      width: '1.5rem',
                      height: '1.5rem',
                      fontSize: '0.75rem',
                      cursor: 'pointer',
                    }}
                  >
                    {attributeGroups.nodes.reduce(
                      (acc, group) => acc + Number(group.attributeCount),
                      0,
                    )}
                  </Avatar>
                  Svi atributi
                </Box>
              }
            />
            {attributeGroups.nodes.map((group) => (
              <Tab
                key={group.id}
                value={group.id}
                style={
                  attributeGroups.nodes.length < 8
                    ? {
                      minWidth: '0',
                    }
                    : {}
                }
                label={
                  <Box
                    display="flex"
                    alignItems="center"
                    flexDirection="row"
                    justifyContent="center"
                    gridGap={5}
                  >
                    <Avatar
                      variant="rounded"
                      style={{
                        backgroundColor: getRandomColorById(group.id),
                        width: '1.5rem',
                        height: '1.5rem',
                        fontSize: '0.75rem',
                      }}
                    >
                      {group.attributeCount || 0}
                    </Avatar>
                    {group.name}
                  </Box>
                }
              />
            ))}
          </Tabs>
        </Box>
        <Card
          style={{
            borderTopLeftRadius: '0',
            borderTopRightRadius: '0',
          }}
        >
          <CardHeader
            title={
              <QuickSearch
                name="attributeName"
                placeholder="Brza pretraga po nazivu atributa"
                setFilters={setFilters}
                mainFilter={filters}
                setPage={setPage}
              />
            }
            action={
              <Grid container spacing={4}>
                <Grid item>
                  <Button
                    color="primary"
                    disabled={Object.keys(filters).filter((k) => filters[k])?.length === 0}
                    startIcon={<CloseIcon fontSize="small" />}
                    onClick={() => setFilters({})}
                  >
                    <Box fontSize="small">Ukloni sve filtere</Box>
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<FilterListIcon fontSize="small" />}
                    onClick={() => setFilterModalVisible(true)}
                  >
                    <Box fontSize="small">Filteri</Box>
                  </Button>
                </Grid>
                <Grid item>
                  <ExcelExportButton
                    totalCount={attributes.totalCount}
                    fields={fields}
                    sorters={[...sorters, '"ordNumber" asc']}
                    filters={{ ...filters, document: documentId }}
                    routeName="document-attributes"
                    variant="outlined"
                    fileName={`atributi-dokumenta-${documentId}`}
                  />
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<LibraryBooksIcon fontSize="small" />}
                    onClick={() => setJournalModalVisible(true)}
                  >
                    <Box fontSize="small">Journal promjena</Box>
                  </Button>
                </Grid>
              </Grid>
            }
          />
          <Divider />
          {Object.keys(filters).filter((k) => filters[k])?.length !== 0 && (
            <>
              <Box ml={2} p={2} display="flex" alignItems="center" flexWrap="wrap">
                {Object.keys(filters).map(
                  (chip) =>
                    filters[chip] !== undefined && (
                      <FilterChip
                        color="primary"
                        variant="outlined"
                        key={chip}
                        label={`${getLabelText(chip)}: ${getChipValue(chip, filters)}`}
                        onDelete={() => handleChipDelete(chip, setFilters, setPage)}
                      />
                    ),
                )}
              </Box>
            </>
          )}
          <Table
            loading={loading || documentLoading}
            error={error}
            attributes={attributes.nodes}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            totalCount={attributes.totalCount}
            handleSortChange={handleSortChange}
            getSortDirection={getSortDirection}
            setSorters={setSorters}
            sorters={sorters}
            setSelectedAttribute={setSelectedAttribute}
            setEditModalVisible={setModalVisible}
            // update
            triggerRerender={triggerRerender}
            methods={methods}
            onSubmit={onSubmit}
            setDialogVisible={setDialogVisible}
            handleTabelReset={handleTabelReset}
            // setDeleteModalVisiblez={setDeleteModalVisible}
            setRerender={setRerender}
          />
        </Card>
      </PageContentWrapper>
    </VerticalBoxContainer>
  );
};

export default DocumentAttributes;
