import React, { useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Grid,
  Divider,
  Breadcrumbs,
  Typography,
  Link,
} from '@material-ui/core';

import { Link as RouterLink } from 'react-router-dom';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import CloseIcon from '@material-ui/icons/Close';
import FilterListIcon from '@material-ui/icons/FilterList';
import {
  VerticalBoxContainer,
  PageContentWrapper,
  FilterChip,
  useAPI,
  QuickSearch,
  dateFormatter,
} from '@shared/components';
import PageHeader from '../../components/PageHeader';
import ExcelExportButton from '../../components/ExcelExportButton';
import { handleChipDelete } from '../../utils/filterHelpers';
import useTheme from '../../hooks/useTheme';
import useGlobalAlertContext from '../../hooks/useGlobalAlertContext';
import axios from '../../utils/axios';
import useAuthContext from '../../hooks/useAuthContext';
import SubjectsTable from './Table';
import SubjectsModal from './Modal';
import Filter from './Filter';
import ActionModal from './ActionModal';
import ArchiveModal from './ArchiveModal';

const getLabelText = (field) => {
  const map = {
    subjectNumber: 'Broj predmeta',
    name: 'Naziv predmeta',
    createdBy: 'Korisnik koji je kreirao',
    organisationName: 'Organizacija',
    responsiblePersonName: 'Odgovorna osoba',
    statusName: 'Status predmeta',
    year: 'Godina',
    ordNumber: 'Redni broj',
    classificationMarkName: 'Klasifikaciona oznaka',
    bookTypeName: 'Vrsta knjige',
    archiveLocationName: 'Arhivska lokacija',
    subjectValid: 'Važeći predmet',
    dateCreated: 'Datum kreiranja',
    resolutionDeadline: 'Rok za rješavanje',
    resolutionDate: 'Datum rješavanja',
    archiveDate: 'Datum arhiviranja',
    dateModified: 'Datum ažuriranja',
    description: 'Opis',
    ownSubject: 'Vlastiti predmet',
  };
  return map[field];
};

const exportFields = [
  {
    value: 'subjectNumber',
    label: 'Broj predmeta',
  },
  {
    value: 'name',
    label: 'Naziv predmeta',
  },
  {
    value: 'subjectValid',
    label: 'važeći predmet',
  },
  {
    value: 'ownSubject',
    label: 'Vlastiti predmet',
  },
  {
    value: 'createdBy',
    label: 'Korisnik koji je kreirao',
  },
  {
    value: 'classificationMark',
    label: 'Klasifikaciona oznaka',
  },
  {
    value: 'classificationMarkName',
    label: 'Naziv klasifikacione oznake',
  },
  {
    value: 'statusName',
    label: 'Status predmeta',
  },
  {
    value: 'organisationName',
    label: 'Naziv organizacija',
  },
  {
    value: 'organisation',
    label: 'Organizacija',
  },
  {
    value: 'responsiblePersonName',
    label: 'Odgovorna osoba',
  },
  {
    value: 'dateCreated',
    label: 'Datum kreiranja',
    type: 'dateTime',
  },
  {
    value: 'dateModified',
    label: 'Datum ažuriranja',
    type: 'dateTime',
  },
  {
    value: 'resolutionDeadline',
    label: 'Rok rješavanja',
    type: 'dateTime',
  },
  {
    value: 'resolutionDate',
    label: 'Datum rješavanja',
    type: 'dateTime',
  },
  {
    value: 'archiveDate',
    label: 'Datum arhiviranja',
    type: 'dateTime',
  },
  {
    value: 'archiveLocationName',
    label: 'Arhivska lokacija',
  },
];

const getChipValue = (chip, filters) => {
  if (
    chip === 'dateCreated' ||
    chip === 'dateModified' ||
    chip === 'archiveDate' ||
    chip === 'resolutionDeadline' ||
    chip === 'resolutionDate'
  )
    return dateFormatter(filters[chip]);
  else if (chip === 'subjectValid' || chip === 'ownSubject')
    return filters[chip] === 'D' ? 'DA' : 'NE';
  return filters[chip];
};

const Subjects = () => {
  const theme = useTheme();
  const [filters, setFilters] = useState({});
  const [sorters, setSorters] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [triggerRerender, setRerender] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [filterModalVisible, setFilterModalVisible] = useState(false);
  const [selectedSubject, setSelectedSubject] = useState();
  const { sendSuccessAlertData, sendErrorAlertData } = useGlobalAlertContext();
  const [loadingReturn, setLoadingReturn] = useState(false);
  const [actionModalVisible, setActionModalVisible] = useState(false);
  const [archiveModalVisible, setArchiveModalVisible] = useState(false);
  const { user } = useAuthContext();

  const SCREEN = 'EKRAN1';

  const [subjects = { nodes: [], totalCount: 0 }, error, loading] = useAPI(
    () =>
      axios
        .get(`subjects`, {
          params: {
            limit: rowsPerPage,
            offset: page * rowsPerPage,
            conditions: { ...filters, screen: SCREEN },
            orderBy: [...sorters, '"dateCreated" DESC'],
          },
        })
        .then(({ data }) => data),
    [page, rowsPerPage, filters, sorters, triggerRerender],
  );

  const handleFilterChange = (filterValues) => {
    setPage(0);
    setFilters({ ...filterValues });
  };

  return (
    <VerticalBoxContainer>
      <Filter
        currentValues={filters}
        visible={filterModalVisible}
        setVisible={setFilterModalVisible}
        getLabelText={getLabelText}
        handleFilterChange={handleFilterChange}
        getChipValue={getChipValue}
        setFilters={setFilters}
        setPage={setPage}
      />
      <SubjectsModal
        subject={selectedSubject}
        setSubject={setSelectedSubject}
        resetSubject={() => setSelectedSubject(undefined)}
        visible={modalVisible}
        setVisible={setModalVisible}
        sendSuccessAlertData={sendSuccessAlertData}
        sendErrorAlertData={sendErrorAlertData}
        setRerender={setRerender}
        disabled={selectedSubject && (selectedSubject.status !== 5 && selectedSubject.status !== 1 || user?.username !== selectedSubject.responsiblePersonUsername)}
      />
      <ArchiveModal
        subject={selectedSubject}
        setSubject={setSelectedSubject}
        resetSubject={() => setSelectedSubject(undefined)}
        visible={archiveModalVisible}
        setVisible={setArchiveModalVisible}
        sendSuccessAlertData={sendSuccessAlertData}
        sendErrorAlertData={sendErrorAlertData}
        setRerender={setRerender}
        disabled={selectedSubject?.status !== 2 && selectedSubject?.status !== 4}
      />
      <ActionModal
        subjectId={selectedSubject?.id}
        visible={actionModalVisible}
        setVisible={setActionModalVisible}
        sendSuccessAlertData={sendSuccessAlertData}
        sendErrorAlertData={sendErrorAlertData}
        setRerender={setRerender}
        setLoadingReturn={setLoadingReturn}
        actionType="resolve"
        // disabled={!userHasAnyRoles(['DMS_PD_EDIT'])}
      />
      <PageHeader
        title="Predmeti"
        extra={
          user.roles.includes('DMS_PREDMETI') && 
          <Grid container spacing={4}>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddCircleOutlineOutlinedIcon />}
                onClick={() => setModalVisible(true)}
              >
                <Box fontSize="small">Novi predmet</Box>
              </Button>
            </Grid>
          </Grid>
        }
      />
      <PageContentWrapper>
        <Breadcrumbs style={{ paddingBottom: `${theme.spacing(5)}px` }}>
          <Link variant="body1" color="inherit" to="/" component={RouterLink}>
            Početna
          </Link>
          <Typography variant="body1" color="textPrimary">
            Predmeti
          </Typography>
        </Breadcrumbs>
        <Card>
          <CardHeader
            title={
              <QuickSearch
                name="subjectNumber"
                placeholder="Brza pretraga po broju predmeta"
                setFilters={setFilters}
                mainFilter={filters}
                setPage={setPage}
              />
            }
            action={
              <Grid container spacing={4}>
                <Grid item>
                  <Button
                    color="primary"
                    disabled={Object.keys(filters).filter((k) => filters[k])?.length === 0}
                    startIcon={<CloseIcon fontSize="small" />}
                    onClick={() => setFilters({})}
                  >
                    <Box fontSize="small">Ukloni sve filtere</Box>
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<FilterListIcon fontSize="small" />}
                    onClick={() => setFilterModalVisible(true)}
                  >
                    <Box fontSize="small">Filteri</Box>
                  </Button>
                </Grid>
                <Grid item>
                  <ExcelExportButton
                    totalCount={subjects?.totalCount}
                    sortById={false}
                    fields={exportFields}
                    sorters={[...sorters, '"dateCreated" DESC']}
                    filters={{ ...filters, screen: SCREEN }}
                    routeName="subjects"
                    fileName="predmeti"
                  />
                </Grid>
              </Grid>
            }
          />
          <Divider />
          {Object.keys(filters).filter((k) => filters[k])?.length !== 0 && (
            <>
              <Box ml={2} p={2} display="flex" alignItems="center" flexWrap="wrap">
                {Object.keys(filters).map(
                  (chip) =>
                    filters[chip] !== undefined && (
                      <FilterChip
                        color="primary"
                        variant="outlined"
                        key={chip}
                        label={`${getLabelText(chip)}: ${getChipValue(chip, filters)}`}
                        onDelete={() => handleChipDelete(chip, setFilters, setPage)}
                      />
                    ),
                )}
              </Box>
            </>
          )}
          <SubjectsTable
            loading={loading}
            error={error}
            subjects={subjects.nodes}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            totalCount={subjects.totalCount}
            sorters={sorters}
            setSorters={setSorters}
            setSelectedSubject={setSelectedSubject}
            setModalVisible={setModalVisible}
            sendSuccessAlertData={sendSuccessAlertData}
            loadingReturn={loadingReturn}
            setActionModalVisible={setActionModalVisible}
            setArchiveModalVisible={setArchiveModalVisible}
          />
        </Card>
      </PageContentWrapper>
    </VerticalBoxContainer>
  );
};

export default Subjects;
