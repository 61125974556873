import { formatDateAttributeField } from '@shared/components';

export default (values, attributeObject) => {
  switch (attributeObject?.attributeType) {
    case 2: {
      const date = formatDateAttributeField(values.valueDisplayed);
      return { valueDisplayed: date, value: date };
    }
    case 5: {
      const date = formatDateAttributeField(values.valueDisplayed, true);
      return { valueDisplayed: date, value: date };
    }
    case 3:
      return { valueDisplayed: values.valueDisplayed?.NAZIV, value: values.valueDisplayed?.ID };
    case 4: {
      const { NAZIV, ID } = JSON.parse(values.valueDisplayed || `{"NAZIV": "","ID": ""}`);
      return { valueDisplayed: NAZIV, value: ID };
    }
    default:
      return { valueDisplayed: values.valueDisplayed, value: values.valueDisplayed };
  }
};
