import React, { useState, useEffect } from 'react';
import {
  Box,
  CardContent,
  Divider,
  Grid,
  TextField,
  FormHelperText,
  Typography,
  CardHeader,
  Card,
} from '@material-ui/core';
import ErrorOutlineRoundedIcon from '@material-ui/icons/ErrorOutlineRounded';
import { useForm } from 'react-hook-form';
import {
  Spinner,
  FormAutocomplete,
  InputModal,
  AttributeField,
  useAPI,
  dateFormatter,
  dateNow,
} from '@shared/components';
import attributeFieldValues from 'utils/attributeFieldValues';
import axios from '../../../../../utils/axios';
import useTheme from '../../../../../hooks/useTheme';
import { ContentBox, ErrorMessage } from './style';

const AttributesModal = ({
  documentId,
  attribute,
  setAttribute,
  visible,
  setVisible,
  sendSuccessAlertData,
  sendErrorAlertData,
  resetAttribute,
  setRerender,
}) => {
  const [loading, setLoading] = useState(false);
  const [errorAlertOpen, setErrorAlertOpen] = useState(false);
  const [requestError, setRequestError] = useState();
  const theme = useTheme();

  const { register, handleSubmit, clearErrors, errors, reset, control } = useForm();
  useEffect(() => {
    setLoading(false);
    setRequestError(null);
    clearErrors();
    reset();
  }, []);

  const handleClose = () => {
    clearErrors();
    setVisible(false);
    resetAttribute();
  };

  const handleAlertClose = () => setErrorAlertOpen(false);

  const onSubmit = async ({ id, ...attributeObject }) => {
    setLoading(true);
    const values = {
      attribute: attributeObject.attribute.id,
      document: documentId,
      ...attributeFieldValues(attributeObject, attribute),
    };

    try {
      const { data } = attribute
        ? await axios.put(`document-attributes/${attribute.id}`, {
            documentAttribute: { ...values },
          })
        : await axios.post(`document-attributes`, {
            documentAttribute: { ...values },
          });
      if (!data) throw new Error();
      sendSuccessAlertData(`Uspješno ${attribute ? 'izmijenjen' : 'dodan'} atribut dokumenta`);
      if (setAttribute) setAttribute((prevState) => ({ ...prevState, ...data }));
      setRerender((prevState) => !prevState);
      handleClose();
    } catch (error) {
      sendErrorAlertData(
        `${
          error?.response?.data?.details?.reason ||
          error?.response?.data?.error?.message ||
          `Došlo je do greške prilikom ${attribute ? 'uređivanja' : 'unosa'} atributa dokumenta`
        }`,
      );
    } finally {
      setLoading(false);
    }
  };

  const [documentAttributes, acError, acLoading] = useAPI(() => {
    return attribute && visible
      ? axios
          .get(`dms-attributes`, {
            params: {
              conditions: { id: attribute?.attribute },
              orderBy: [],
              limit: 1,
            },
          })
          .then(({ data }) => data)
      : Promise.resolve(() => ({ nodes: [], totalCount: 0 }));
  }, [attribute]);

  return (
    <InputModal
      object={attribute}
      createTitle="Kreiraj novi atribut dokumenta"
      updateTitle={`Uredi atribut dokumenta ${attribute?.id}`}
      createButtonText="Kreiraj atribut"
      updateButtonText="Uredi atribut"
      visible={visible}
      onSubmit={onSubmit}
      handleSubmit={handleSubmit}
      handleClose={handleClose}
      handleAlertClose={handleAlertClose}
      requestError={requestError}
      errorAlertOpen={errorAlertOpen}
      modalWidth="35%"
      loading={loading}
      showHeader
      theme={theme}
    >
      <ContentBox>
        {acError ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height={265}
            minWidth="100%"
          >
            <Box display="flex" justifyContent="center">
              <ErrorOutlineRoundedIcon fontSize="large" />
              <ErrorMessage>
                <Typography variant="h3">
                  {acError?.data?.details?.reason || 'Došlo je do greške'}
                </Typography>
              </ErrorMessage>
            </Box>
          </Box>
        ) : acLoading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height={265}
            minWidth="100%"
          >
            <Box display="flex" justifyContent="center">
              <Spinner size={60} />
            </Box>
          </Box>
        ) : (
          <Grid container spacing={6}>
            <Grid item xs={12} sm={12}>
              <Card style={{ height: '100%' }} elevation={4}>
                <CardHeader
                  title="Osnovni podaci"
                  titleTypographyProps={{ variant: 'subtitle2' }}
                />
                <Divider />
                <CardContent>
                  <Box>
                    <FormAutocomplete
                      error={!!errors.attribute}
                      fullWidth
                      label="Atribut (Metapodatak)*"
                      name="attribute"
                      placeholder="Unesite dva ili više karaktera"
                      defaultValue={attribute?.id}
                      routeName="dms-attributes"
                      initialData={documentAttributes}
                      optionsLabel="attributeName"
                      showSide="attributeIdCode"
                      variant="standard"
                      control={control}
                      axios={axios}
                      register={register}
                      acDisabled
                    />
                    {errors.attribute && (
                      <FormHelperText error>{errors.attribute.message}</FormHelperText>
                    )}
                  </Box>
                  <Box mt={5}>
                    <AttributeField
                      name="valueDisplayed"
                      id="valueDisplayed"
                      label="Vrijednost"
                      defaultValue={attribute?.valueDisplayed}
                      placeholder={attribute?.hint}
                      attributeObject={attribute}
                      isUpdating={attribute}
                      errors={errors}
                      register={register}
                      control={control}
                      axios={axios}
                    />
                  </Box>
                  <Box mt={5}>
                    <TextField
                      fullWidth
                      name="dateCreated"
                      id="dateCreated"
                      label="Datum kreiranja"
                      inputRef={register}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      defaultValue={
                        attribute
                          ? dateFormatter(attribute?.dateCreated, true)
                          : dateFormatter(dateNow(), true)
                      }
                      disabled
                    />
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        )}
      </ContentBox>
    </InputModal>
  );
};

export default AttributesModal;
