import React, { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormHelperText,
  Grid,
  TextField,
} from '@material-ui/core';
import { FormTextArea, InputModal } from '@shared/components';
import { useForm } from 'react-hook-form';

import { attributeGroupSchema } from '@shared/partner-schemas';
import useTheme from '../../../hooks/useTheme';
import axios from '../../../utils/axios';

const AttributeGroupModal = ({
  attributeGroup,
  setAttributeGroup,
  visible,
  setVisible,
  sendSuccessAlertData,
  sendErrorAlertData,
  setRerender,
  resetAttributeGroup,
}) => {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [requestError, setRequestError] = useState({});
  const { handleSubmit, errors, reset, clearErrors, register } = useForm({
    resolver: yupResolver(attributeGroupSchema),
  });

  const onSubmit = async ({ code, name, ordNumber, description }) => {
    setLoading(true);
    try {
      const data = attributeGroup
        ? await axios.put(`group-attributes/${attributeGroup.id}`, {
            attributeGroup: { code, name, ordNumber, description },
          })
        : await axios.post(`group-attributes`, {
            attributeGroup: { code, name, ordNumber, description },
          });
      if (!data) throw new Error();
      sendSuccessAlertData(`Uspješno ${attributeGroup ? 'izmijenjena' : 'unesena'} grupe atributa`);
      if (attributeGroup) setAttributeGroup(data.data);
      setRerender((prevState) => !prevState);
      handleClose();
    } catch (error) {
      sendErrorAlertData(
        `${
          error?.response?.data?.details?.reason ||
          error?.response?.data?.error?.message ||
          `Došlo je do greške prilikom ${attributeGroup ? 'uređivanja' : 'unosa'} grupe atributa`
        }`,
      );
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setVisible(false);
    resetAttributeGroup();
  };

  useEffect(() => {
    setLoading(false);
    setRequestError(null);
    clearErrors();
    reset();
  }, []);

  return (
    <InputModal
      object={attributeGroup}
      createTitle="Unos nove grupe atributa"
      updateTitle={`Uredi grupu atributa: ${attributeGroup?.code}`}
      createButtonText="Kreiraj grupu atributa"
      updateButtonText="Uredi grupu atributa"
      visible={visible}
      onSubmit={onSubmit}
      handleSubmit={handleSubmit}
      handleClose={handleClose}
      requestError={requestError}
      modalWidth={600}
      loading={loading}
      theme={theme}
    >
      <Box
        padding={`${theme.spacing(5)}px`}
        style={{ backgroundColor: theme.palette.background.dark }}
      >
        <Grid
          container
          style={{ minHeight: 250 }}
          spacing={6}
          direction="column"
          alignItems="stretch"
        >
          <Grid item>
            <Card elevation={4} style={{ height: '100%' }}>
              <CardHeader title="Osnovni podaci" titleTypographyProps={{ variant: 'subtitle2' }} />
              <Divider />
              <CardContent>
                <Grid container direction="column" spacing={6}>
                  <Grid item>
                    <TextField
                      fullWidth
                      error={!!errors.ordNumber}
                      label="Redni broj*"
                      defaultValue={attributeGroup?.ordNumber}
                      name="ordNumber"
                      id="ordNumber"
                      type="number"
                      placeholder="Unesite redni broj"
                      inputRef={register}
                    />
                    {errors.ordNumber && (
                      <FormHelperText error>{errors.ordNumber.message}</FormHelperText>
                    )}
                  </Grid>
                  <Grid item>
                    <TextField
                      fullWidth
                      error={!!errors.code}
                      label="Šifra*"
                      defaultValue={attributeGroup?.code}
                      name="code"
                      id="code"
                      placeholder="Unesite šifru"
                      inputRef={register}
                    />
                    {errors.code && <FormHelperText error>{errors.code.message}</FormHelperText>}
                  </Grid>
                  <Grid item>
                    <TextField
                      fullWidth
                      error={!!errors.name}
                      label="Naziv*"
                      defaultValue={attributeGroup?.name}
                      name="name"
                      id="name"
                      placeholder="Unesite naziv"
                      inputRef={register}
                    />
                    {errors.name && <FormHelperText error>{errors.name.message}</FormHelperText>}
                  </Grid>
                  <Grid item>
                    <FormTextArea
                      label="Opis"
                      object={attributeGroup}
                      register={register}
                      name="description"
                      errors={errors}
                      maxLength={2000}
                      rows={4}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </InputModal>
  );
};

export default AttributeGroupModal;
