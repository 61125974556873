import React, { useState, useEffect } from 'react';
import {
  // Divider,
  Grid,
  Box,
  Typography,
  FormHelperText,
  Card,
  CardContent,
  MenuItem,
} from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { documentStatusChangeSchema } from '@shared/partner-schemas';
import { InputModal, FormTextArea, FormSelect } from '@shared/components';
import axios from '../../../utils/axios';
import useTheme from '../../../hooks/useTheme';

const StatusModal = ({
  document,
  setDocument,
  visible,
  setVisible,
  sendSuccessAlertData,
  sendErrorAlertData,
  setRerender,
  documentLoading = false,
  detailScreen = false,
}) => {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [requestError, setRequestError] = useState({});
  const { handleSubmit, errors, reset, clearErrors, register, control } = useForm({
    resolver: yupResolver(documentStatusChangeSchema),
  });
  const [allowedStatuses, setAllowedStatuses] = useState([]);

  const allowedStatusTransitions = {
    1: [
      {
        key: 6,
        name: 'Dokument vraćen/poništen',
      },
    ],
    2: [
      {
        key: 1,
        name: 'Dokument na protokolu',
      },
    ],
    3: [
      {
        key: 5,
        name: 'Dokument nije usaglašen',
      },
      {
        key: 6,
        name: 'Dokument vraćen/poništen',
      },
    ],
  };

  const documentApproved = {
    key: 4,
    name: 'Dokument finalno usaglašen',
  };

  const onSubmit = async ({ documentStatus, documentComment }) => {
    setLoading(true);

    try {
      const requestFormData = new FormData();
      requestFormData.append(
        'documentData',
        JSON.stringify({ id: document?.id, documentValues: { documentStatus, statusComment: documentComment } }),
      );
      const data = await axios.put(`documents/${document.id}`, requestFormData);
      if (!data) throw new Error();
      sendSuccessAlertData('Uspješno izmijenjen status dokumenta');
      if (document) setDocument(data.data);
      setRerender((prevState) => !prevState);
      handleClose();
    } catch (error) {
      sendErrorAlertData(
        `${error?.response?.data?.details?.reason ||
        error?.response?.data?.error?.message ||
        'Došlo je do greške prilikom izmjene statusa dokumenta'
        }`,
      );
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    if (!detailScreen) setDocument(undefined);
    setVisible(false);
  };

  useEffect(() => {
    setLoading(false);
    setRequestError(null);
    clearErrors();
    reset();
  }, []);

  useEffect(() => {
    if (document?.status) {
      setAllowedStatuses([...(allowedStatusTransitions[document?.status] || '')]);
      if (document?.status === 3 && document.currentPercentage >= document.accordancePercentage) {
        setAllowedStatuses((value) => [documentApproved, ...value]);
      }
    } else setAllowedStatuses([]);
  }, [document]);

  return (
    <InputModal
      object={document}
      updateTitle={`Promijeni status dokumenta ${document?.id}`}
      updateButtonText="Potvrdi"
      visible={visible}
      onSubmit={onSubmit}
      handleSubmit={handleSubmit}
      handleClose={handleClose}
      requestError={requestError}
      modalWidth={700}
      loading={loading || documentLoading}
      theme={theme}
    >
      <Box
        padding={`${theme.spacing(5)}px`}
        style={{ backgroundColor: theme.palette.background.dark }}
      >
        <Card elevation={4} style={{ height: 'auto' }}>
          <CardContent>
            <Grid
              container
              style={{ minHeight: 200 }}
              spacing={6}
              direction="column"
              justifyContent="center"
            // alignItems="stretch"
            >
              <Grid item>
                <Box
                  minHeight={10}
                  padding={`${theme.spacing(5)}px`}
                  paddingBottom={`${theme.spacing(5)}px`}
                >
                  <Typography
                    variant="h4"
                    style={{ padding: `${theme.spacing(4)}px 0 0` }}
                    align="center"
                  >
                    {`Da li ste sigurni da želite promijeniti status dokumenta ${document?.id}?`}
                  </Typography>
                </Box>
              </Grid>
              <Grid item>
                <FormSelect
                  error={!!errors.documentStatus}
                  fullWidth
                  label="Status dokumenta*"
                  name="documentStatus"
                  id="documentStatus"
                  placeholder="Status dokumenta"
                  control={control}
                >
                  {allowedStatuses.map(({ key, name }) => (
                    <MenuItem key={key} value={key} name={key}>
                      {name}
                    </MenuItem>
                  ))}
                </FormSelect>
                {errors.documentStatus && (
                  <FormHelperText error>{errors.documentStatus.message}</FormHelperText>
                )}
              </Grid>
              <Grid item>
                <FormTextArea
                  register={register}
                  object={document}
                  name="documentComment"
                  errors={errors}
                  maxLength={4000}
                  rows={7}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </InputModal>
  );
};

export default StatusModal;
