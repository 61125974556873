import React, { useState, useEffect } from 'react';
import { Grid, Box, FormHelperText, Card, CardContent } from '@material-ui/core';

import { FormAutocomplete, InputModal, useAPI } from '@shared/components';
import { useForm } from 'react-hook-form';

import { subjectArchiveLocationSchema } from '@shared/partner-schemas';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from '../../../utils/axios';
import useTheme from '../../../hooks/useTheme';

const ArchiveModal = ({
  subject,
  setSubject,
  visible,
  setVisible,
  resetSubject,
  setRerender,
  sendSuccessAlertData,
  sendErrorAlertData,
  disabled = false,
}) => {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit, clearErrors, errors, reset, control } = useForm({
    resolver: yupResolver(subjectArchiveLocationSchema),
  });

  const [arhiveLocations = [], atError, atLoading] = useAPI(
    () =>
      axios
        .get(`archive-locations`, {
          params: {
            conditions: { id: subject?.archiveLocation },
            orderBy: [],
            limit: subject?.archiveLocation ? 1 : 0,
          },
        })
        .then(({ data }) => data),
    [],
  );

  const handleClose = () => {
    setVisible(false);
    resetSubject();
  };

  const onSubmit = async ({ archiveLocation, ...values }) => {
    values = {
      archiveLocation: archiveLocation ? archiveLocation.id : null,
      ...values,
    };

    setLoading(true);
    try {
      const { data } = await axios.post(`subjects/${subject.id}/archive`, {
        archiveData: values,
      });

      if (!data) throw new Error();
      sendSuccessAlertData(`Uspješno arhiviran predmet`);
      if (setSubject) setSubject({ ...subject, ...values });
      setRerender((prevState) => !prevState);
      handleClose();
    } catch (error) {
      sendErrorAlertData(
        `${
          error?.response?.data?.details?.reason ||
          error?.response?.data?.error?.message ||
          'Došlo je do greške prilikom arhiviranja predmeta'
        }`,
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(false);
    clearErrors();
    reset();
  }, []);

  return (
    <InputModal
      object={subject}
      updateTitle={`Da li ste sigurni da želite arhivirati predmet ${subject?.id}`}
      updateButtonText="DA"
      visible={visible}
      onSubmit={onSubmit}
      handleSubmit={handleSubmit}
      handleClose={handleClose}
      modalWidth="30%"
      loading={loading}
      theme={theme}
      disabledEdit={disabled}
    >
      <Box
        padding={`${theme.spacing(5)}px`}
        style={{ backgroundColor: theme.palette.background.dark }}
      >
        <Card elevation={4} style={{ height: '100%' }}>
          <CardContent>
            <Grid container direction="column" spacing={5}>
              <Grid item>
                <FormAutocomplete
                  fullWidth
                  control={control}
                  loading={atLoading}
                  defaultValue={subject?.archiveLocation}
                  register={register}
                  error={atError || !!errors.archiveLocation}
                  label="Arhivska lokacija"
                  name="archiveLocation"
                  routeName="archive-locations"
                  defaultConditions={{ isValid: 'D' }}
                  initialData={arhiveLocations}
                  optionsLabel="name"
                  showSide="code"
                  axios={axios}
                />
                {errors.archiveLocation && (
                  <FormHelperText error>{errors.archiveLocation.message}</FormHelperText>
                )}
                {atError && <FormHelperText error>Došlo je do greške.</FormHelperText>}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </InputModal>
  );
};

export default ArchiveModal;
