import React, { useState, useEffect } from 'react';
import {
  Divider,
  Grid,
  Box,
  Typography,
  FormHelperText,
  Card,
  CardContent,
  MenuItem,
  TextField,
} from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { documentStatusChangeSubjectSchema } from '@shared/partner-schemas';
import { Alert } from '@material-ui/lab';
import {
  InputModal,
  FormTextArea,
  FormSelect,
  FormAutocomplete,
  FormCheckBox,
  useAPI,
} from '@shared/components';
import axios from '../../../utils/axios';
import useAuthContext from '../../../hooks/useAuthContext';

import useTheme from '../../../hooks/useTheme';

import { CardHeader } from './style';

const FooterInfo = ({ content }) => {
  return (
    <Alert
      severity="warning"
      style={{ fontSize: '.8em', paddingBottom: '0', paddingTop: '0', margin: '0' }}
    >
      {content}
    </Alert>
  );
};

const StatusModal = ({
  document,
  setDocument,
  visible,
  setVisible,
  sendSuccessAlertData,
  sendErrorAlertData,
  setRerender,
  documentLoading = false,
  detailScreen = false,
}) => {
  const theme = useTheme();
  const defaultData = { nodes: [], totalCount: 0 };
  const { user } = useAuthContext();
  const [loading, setLoading] = useState(false);
  const [requestError, setRequestError] = useState({});
  const { handleSubmit, errors, reset, clearErrors, control, register, watch } = useForm({
    resolver: yupResolver(documentStatusChangeSubjectSchema),
  });
  const watchSelectedStatus = watch('documentStatus');
  const watchCheckbox = watch('existingSubject', true);
  const displaySubjectCard = document?.documentSort === 'U';

  const [allowedStatuses, setAllowedStatuses] = useState([]);

  const allowedStatusTransitions = {
    1: [
      {
        key: 6,
        name: 'Dokument vraćen/poništen',
      },
    ],
    2: [
      {
        key: 1,
        name: 'Dokument na protokolu',
      },
      {
        key: 4,
        name: 'Dokument finalno usaglašen',
      },
      {
        key: 6,
        name: 'Dokument vraćen/poništen',
      },
    ],
    3: [
      {
        key: 5,
        name: 'Dokument nije usaglašen',
      },
      {
        key: 6,
        name: 'Dokument vraćen/poništen',
      },
    ],
  };

  const documentApproved = {
    key: 4,
    name: 'Dokument finalno usaglašen',
  };

  const onSubmit = async ({
    documentStatus,
    statusComment,
    subject,
    existingSubject,
    name,
    bookType,
    classificationMark,
    resolutionDeadline,
  }) => {
    setLoading(true);
    let newSubject = {};
    const responsiblePerson = acData?.responsiblePersonNames?.nodes?.length
      ? acData.responsiblePersonNames.nodes[0]
      : null;
    const subjectValues = {
      name,
      bookType,
      resolutionDeadline,
      classificationMark: classificationMark ? classificationMark.id : null,
      organisation: responsiblePerson ? responsiblePerson.organisationId : null,
      responsiblePerson: responsiblePerson ? responsiblePerson.id : null,
    };

    const requestFormData = new FormData();

    try {
      if (!existingSubject && displaySubjectCard) {
        newSubject = await axios.post(`subjects`, {
          subject: subjectValues,
        });
        if (!newSubject) throw new Error();
      }

      const chosenSubj = displaySubjectCard ? (existingSubject ? subject : newSubject.data) : null;
      requestFormData.append(
        'documentData',
        JSON.stringify({
          id: document?.id,
          documentValues: {
            documentStatus,
            statusComment,
            subject: chosenSubj ? chosenSubj.id : null,
          },
        }),
      );
      const data = await axios.put(`documents/${document.id}`, requestFormData);
      if (!data) throw new Error();
      sendSuccessAlertData('Uspješno izmijenjen status dokumenta');
      if (document) setDocument(data.data);
      setRerender((prevState) => !prevState);
      handleClose();
    } catch (error) {
      sendErrorAlertData(
        `${
          error?.response?.data?.details?.reason ||
          error?.response?.data?.error?.message ||
          'Došlo je do greške prilikom izmjene statusa dokumenta'
        }`,
      );
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    if (!detailScreen) setDocument(undefined);
    setVisible(false);
  };

  useEffect(() => {
    setLoading(false);
    setRequestError(null);
    clearErrors();
    reset();
  }, []);

  useEffect(() => {
    if (document?.status) {
      setAllowedStatuses([...(allowedStatusTransitions[document?.status] || '')]);
      if (document?.status === 3 && document.currentPercentage >= document.accordancePercentage) {
        setAllowedStatuses((value) => [documentApproved, ...value]);
      }
    } else setAllowedStatuses([]);
  }, [document]);

  const [
    acData = {
      bookTypeNames: [],
      responsiblePersonNames: [],
    },
    acError,
    acLoading,
  ] = useAPI(() => {
    return visible
      ? Promise.all([
          axios.get(`book-types`),
          axios.get(`workers`, {
            params: {
              conditions: { username: user.username, groupAccordance: true },
              orderBy: [],
              limit: 1,
            },
          }),
        ]).then(([{ data: bookTypeNames }, { data: responsiblePersonNames }]) => ({
          bookTypeNames,
          responsiblePersonNames,
        }))
      : Promise.resolve(() => defaultData);
  }, [visible]);

  // acData.responsiblePersonNames.nodes[0].organisationId

  return (
    <InputModal
      object={document}
      updateTitle={`Promijeni status dokumenta ${document?.id}`}
      updateButtonText="Potvrdi"
      visible={visible}
      onSubmit={onSubmit}
      handleSubmit={handleSubmit}
      handleClose={handleClose}
      requestError={requestError}
      modalWidth={displaySubjectCard ? '55%' : '30%'}
      loading={loading || documentLoading}
      theme={theme}
      footerInfo={
        displaySubjectCard && (
          <FooterInfo
            content={
              documentApproved.key === watchSelectedStatus ? (
                'Nakon usaglašavanja predmet se više ne može mijenjati'
              ) : (
                <>
                  Naknadno dodjeljivanje predmeta se vrši na ekranu{' '}
                  <span
                    style={{
                      fontWeight: 'bold',
                    }}
                  >
                    Predmeti
                  </span>
                </>
              )
            }
          />
        )
      }
    >
      <Box
        padding={`${theme.spacing(5)}px`}
        style={{ backgroundColor: theme.palette.background.dark }}
      >
        <Grid
          container
          // style={{ minHeight: 400 }}
          spacing={6}
          direction="row"
          // justifyContent="center"
        >
          <Grid item xs={displaySubjectCard ? 6 : 12}>
            <Card elevation={4} style={{ height: 'auto', minHeight: 460 }}>
              <CardContent>
                <Grid
                  container
                  spacing={6}
                  direction="column"
                  justifyContent="center"
                  // alignItems="stretch"
                >
                  <Grid item>
                    <Box
                      minHeight={10}
                      padding={`${theme.spacing(5)}px`}
                      paddingBottom={`${theme.spacing(5)}px`}
                    >
                      <Typography
                        variant="h4"
                        style={{ padding: `${theme.spacing(4)}px 0 0` }}
                        align="center"
                      >
                        {`Da li ste sigurni da želite promijeniti status dokumenta ${document?.id}?`}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item>
                    <FormSelect
                      error={!!errors.documentStatus}
                      fullWidth
                      label="Status dokumenta*"
                      name="documentStatus"
                      id="documentStatus"
                      placeholder="Status dokumenta"
                      control={control}
                    >
                      {allowedStatuses.map(({ key, name }) => (
                        <MenuItem key={key} value={key} name={key}>
                          {name}
                        </MenuItem>
                      ))}
                    </FormSelect>
                    {errors.documentStatus && (
                      <FormHelperText error>{errors.documentStatus.message}</FormHelperText>
                    )}
                  </Grid>
                  <Grid item>
                    <FormTextArea
                      label="Komentar"
                      register={register}
                      name="statusComment"
                      errors={errors}
                      maxLength={4000}
                      rows={7}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          {displaySubjectCard && (
            <Grid item xs={6}>
              <Card elevation={4} style={{ minHeight: 460 }}>
                {/* <CardHeader
                  title="Dodjela dokumenta na predmet"
                  // titleTypographyProps={{ variant: 'subtitle2' }}
                  // style={{
                  //   padding: '8px',
                  //   color: watchCheckbox && theme.palette.text.secondary,
                  //   backgroundColor: watchCheckbox && theme.palette.background.dark,
                  // }}
                  // disabled={watchCheckbox}
                /> */}
                <CardContent>
                  <Grid
                    container
                    style={{ minHeight: 200 }}
                    spacing={6}
                    direction="column"
                    justifyContent="center"
                    // alignItems="stretch"
                  >
                    <Grid item>
                      <FormCheckBox
                        defaultChecked
                        labelPlacement="end"
                        name="existingSubject"
                        // backgroundColor="transparent"
                        control={control}
                        label="Postojeći predmet"
                        theme={theme}
                        style={{ bakckgroundColor: 'red' }}
                        disabled={documentApproved.key !== watchSelectedStatus}
                      />
                    </Grid>
                    <Grid item>
                      <FormAutocomplete
                        error={!!errors.subject}
                        fullWidth
                        label="Predmet"
                        name="subject"
                        placeholder="Unesite dva ili više karaktera"
                        routeName="subjects"
                        optionsLabel="name"
                        acDisabled={!watchCheckbox || documentApproved.key !== watchSelectedStatus}
                        showSide="subjectNumber"
                        variant="standard"
                        control={control}
                        defaultConditions={{ canAddDocuments: 'D', screen: 'EKRAN1' }}
                        axios={axios}
                        register={register}
                      />
                      {errors.subject && (
                        <FormHelperText error>{errors.subject.message}</FormHelperText>
                      )}
                    </Grid>
                    <Grid item>
                      <Card style={{ height: '100%', marginBottom: '0px' }} elevation={3}>
                        <CardHeader
                          title="Novi predmet"
                          // titleTypographyProps={{ variant: 'subtitle2' }}
                          style={{
                            padding: '8px',
                            color: watchCheckbox && theme.palette.text.secondary,
                            backgroundColor: watchCheckbox && theme.palette.background.dark,
                          }}
                          disabled={watchCheckbox}
                        />
                        <Divider />
                        <CardContent>
                          <Grid container spacing={4} direction="column">
                            <Grid item>
                              <TextField
                                error={!!errors.name}
                                size="small"
                                fullWidth
                                label="Naziv predmeta*"
                                name="name"
                                disabled={watchCheckbox}
                                id="name"
                                placeholder="Unesite naziv predmeta"
                                inputRef={register}
                              />
                              {errors.name && (
                                <FormHelperText error>{errors.name.message}</FormHelperText>
                              )}
                            </Grid>
                            <Grid item>
                              <FormSelect
                                error={!!errors.bookType}
                                fullWidth
                                disabled={watchCheckbox}
                                loading={acLoading}
                                label="Vrsta knjige*"
                                name="bookType"
                                id="bookType"
                                control={control}
                              >
                                {acError ? (
                                  <MenuItem disabled>Došlo je do greške</MenuItem>
                                ) : acData.bookTypeNames?.nodes ? (
                                  acData?.bookTypeNames?.nodes.map((bookType) => (
                                    <MenuItem
                                      key={bookType.id}
                                      value={bookType.id}
                                      disabled={bookType.isValid === 'N'}
                                    >
                                      {bookType.name}
                                    </MenuItem>
                                  ))
                                ) : (
                                  <MenuItem disabled>Nema podataka</MenuItem>
                                )}
                              </FormSelect>
                              {errors?.bookType && (
                                <FormHelperText error>{errors?.bookType.message}</FormHelperText>
                              )}
                            </Grid>
                            <Grid item>
                              <FormAutocomplete
                                fullWidth
                                control={control}
                                loading={acLoading}
                                register={register}
                                error={acError || !!errors.classificationMark}
                                placeholder="Unesite klasifikacijsku oznaku"
                                label="Klasifikacijska oznaka*"
                                name="classificationMark"
                                routeName="classification-marks"
                                defaultConditions={{ isValid: 'D' }}
                                optionsLabel="name"
                                showSide="id"
                                axios={axios}
                                acDisabled={watchCheckbox}
                              />
                              {errors?.classificationMark && (
                                <FormHelperText error>
                                  {errors?.classificationMark.message}
                                </FormHelperText>
                              )}
                              {acError && (
                                <FormHelperText error>Došlo je do greške.</FormHelperText>
                              )}
                            </Grid>
                            <Grid item>
                              <TextField
                                label="Rok rješavanja predmeta"
                                name="resolutionDeadline"
                                id="resolutionDeadline"
                                type="date"
                                disabled={watchCheckbox}
                                fullWidth
                                placeholder="Unesite datum roka rješavanja predmeta"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                inputRef={register()}
                              />
                              {errors.resolutionDeadline && (
                                <FormHelperText error>
                                  {errors.resolutionDeadline.message}
                                </FormHelperText>
                              )}
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          )}
        </Grid>
      </Box>
    </InputModal>
  );
};

export default StatusModal;
