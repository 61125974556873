import * as yup from 'yup';

export const documentStatusChangeSchema = yup.object().shape({
  documentStatus: yup
    .number()
    .typeError('Status dokumenta je obavezno polje')
    .required('Status dokumenta je obavezno polje'),
  documentComment: yup.string().max(4000, 'Polje "Komentar" mora imati manje od 4000 karaktera.'),
});

export const documentStatusChangeSubjectSchema = yup.object().shape({
  documentStatus: yup
    .number()
    .typeError('Status dokumenta je obavezno polje')
    .required('Status dokumenta je obavezno polje'),
  documentComment: yup.string().max(4000, 'Polje "Komentar" mora imati manje od 4000 karaktera.'),
  bookType: yup
    .number()
    .transform((value, originalValue) => {
      if (!value) {
        return null;
      }
      return originalValue;
    })
    .nullable()
    .optional()
    .typeError('Izaberite vrstu knjige')
    .when('existingSubject', {
      is: false,
      then: yup
        .number()
        .positive()
        .required('Polje Vrsta knjige je obavezno kada se želi kreirati novi predmet')
        .typeError('Polje Vrsta knjige je obavezno kada se želi kreirati novi predmet'),
    }),
  classificationMark: yup
    .object()
    .nullable()
    .shape({
      id: yup.number().positive(),
    })
    .when('existingSubject', {
      is: false,
      then: yup
        .object()
        .required('Klasifikaciona oznaka je obavezno polje kada se kreira novi predmet')
        .typeError('Klasifikaciona oznaka je obavezno polje kada se kreira novi predmet'),
    }),
  name: yup
    .string()
    .nullable()
    .when('existingSubject', {
      is: false,
      then: yup
        .string()
        .max(200, 'Naziv mora biti manji od 200 znakova')
        .typeError('Naziv je obavezno polje')
        .required('Naziv je obavezno polje'),
    }),

  resolutionDeadline: yup
    .string()
    .transform((value) => (!value ? undefined : value))
    .matches(
      /^\d{4}\-(0?[1-9]|1[012])\-(0?[1-9]|[12][0-9]|3[01])$/,
      'Pogrešno unesena forma datuma (yyyy-mm-dd)',
    )
    .nullable(),
});

export const documentSubjectAssign = yup.object().shape({
  subject: yup
    .object()
    .nullable()
    .shape({
      id: yup.number().positive(),
    })
    .when('existingSubject', {
      is: true,
      then: yup
        .object()
        .shape({
          id: yup
            .number()
            .positive()
            .typeError('Za dodjeljivanje postojećem predmetu potrebno je izabrati predmet'),
        })
        .required('Za dodjeljivanje postojećem predmetu potrebno je izabrati predmet')
        .typeError('Za dodjeljivanje postojećem predmetu potrebno je izabrati predmet'),
    }),
  bookType: yup
    .number()
    .transform((value, originalValue) => {
      if (!value) {
        return null;
      }
      return originalValue;
    })
    .nullable()
    .optional()
    .typeError('Izaberite vrstu knjige')
    .when('existingSubject', {
      is: false,
      then: yup
        .number()
        .positive()
        .required('Polje Vrsta knjige je obavezno kada se želi kreirati novi predmet')
        .typeError('Polje Vrsta knjige je obavezno kada se želi kreirati novi predmet'),
    }),
  classificationMark: yup
    .object()
    .nullable()
    .shape({
      id: yup.number().positive(),
    })
    .when('existingSubject', {
      is: false,
      then: yup
        .object()
        .required('Klasifikaciona oznaka je obavezno polje kada se kreira novi predmet')
        .typeError('Klasifikaciona oznaka je obavezno polje kada se kreira novi predmet'),
    }),
  name: yup
    .string()
    .nullable()
    .when('existingSubject', {
      is: false,
      then: yup
        .string()
        .max(200, 'Naziv mora biti manji od 200 znakova')
        .typeError('Naziv je obavezno polje')
        .required('Naziv je obavezno polje'),
    }),

  resolutionDeadline: yup
    .string()
    .transform((value) => (!value ? undefined : value))
    .matches(
      /^\d{4}\-(0?[1-9]|1[012])\-(0?[1-9]|[12][0-9]|3[01])$/,
      'Pogrešno unesena forma datuma (yyyy-mm-dd)',
    )
    .nullable(),
});
