import styled from 'styled-components';
import { TableCell as MuiTableCell, Box as MuiBox, Chip as MuiChip } from '@material-ui/core';

export const ErrorMessage = styled.span`
  font-size: 24px;
  padding-left: ${({ theme }) => theme.spacing(2)}px;
`;

export const ExpandableRowCell = styled(MuiTableCell)`
  border-bottom: 0;
`;

export const ExpandedRow = styled(MuiBox)`
  padding: ${({ theme }) => theme.spacing(5)}px 0;
  margin: ${({ theme }) => theme.spacing(5)}px 0;
  background: ${({ theme }) => theme.palette.background.dark};
  background: ${({ theme }) =>
    `linear-gradient(90deg, ${theme.palette.background.default} .5%, ${theme.palette.background.dark} .5%, ${theme.palette.background.dark} 99.5%, ${theme.palette.background.default} 99.5%);`};
`;

export const Chip = styled(MuiChip)`
  min-width: 160px;
`;

