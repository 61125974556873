import React, { useState, useEffect } from 'react';
import mime from 'mime-types';
import { Divider, Grid, Box, CardHeader, Card, CardContent, TextField } from '@material-ui/core';
import { useForm } from 'react-hook-form';
// import ErrorOutlineRoundedIcon from '@material-ui/icons/ErrorOutlineRounded';
import { yupResolver } from '@hookform/resolvers/yup';
import { documentAttSchema } from '@shared/partner-schemas';
import {
  InputModal,
  dateFormatter,
  FormTextArea,
  FormFileUpload,
  dateNow,
} from '@shared/components';
import axios from '../../../../../utils/axios';
import useTheme from '../../../../../hooks/useTheme';
import { ContentBox } from './style';

const Modal = ({
  selectedDocumentAttachment,
  setSelectedDocumentAttachment,
  visible,
  setVisible,
  sendSuccessAlertData,
  sendErrorAlertData,
  resetSelectedDocument,
  setRerender,
  documentId,
  disabled,
}) => {
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const { register, handleSubmit, clearErrors, errors, reset, setValue } = useForm({
    mode: 'onChange',
    resolver: yupResolver(documentAttSchema),
  });

  // Attachment Upload
  const [selectedFileName, setSelectedFileName] = useState('');
  const [selectedFile, setSelectedFile] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);

  const handleClose = () => {
    clearErrors();
    setVisible(false);
    setSelectedFile(null);
    setIsFilePicked(false);
    setSelectedFileName('');
    reset();
    resetSelectedDocument();
  };

  const onSubmit = async (values) => {
    setLoading(true);
    const fileExtension = mime.extension(mime.contentType(selectedFileName));

    const attachmentValues = {
      ...values,
      // attachmentType: values?.attachmentType?.id,
      // attachmentTypeCode: values?.attachmentType?.typeCode,
      name: selectedFileName,
      attachmentFilesize: selectedFile && selectedFile?.size,
      attachmentExtension: selectedFile && `.${fileExtension}`,
    };
    const requestFormData = new FormData();
    requestFormData.append(
      'attachmentData',
      JSON.stringify({
        documentId,
        attachmentValues,
      }),
    );
    if (isFilePicked) requestFormData.append('file', selectedFile);
    if (!document && (!isFilePicked || !fileExtension)) {
      sendErrorAlertData('Potrebno je da priložite/učitate dokument.');
      return;
    }

    try {
      const { data } = selectedDocumentAttachment
        ? await axios.put(
            `document-attachments/${selectedDocumentAttachment.id}`,
            requestFormData,
            {
              headers: { 'Content-Type': 'multipart/form-data' },
            },
          )
        : await axios.post(`/document-attachments`, requestFormData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          });
      if (!data) throw new Error();
      sendSuccessAlertData(
        `Uspješno ${selectedDocumentAttachment ? 'izmijenjen' : 'unesen'} prilog dokumenta`,
      );
      if (setSelectedDocumentAttachment) setSelectedDocumentAttachment({ ...values });
      setRerender((prevState) => !prevState);
      handleClose();
    } catch (error) {
      sendErrorAlertData(
        `${
          error?.response?.data?.details?.reason ||
          error?.response?.data?.error?.message ||
          `Došlo je do greške prilikom ${
            selectedDocumentAttachment ? 'izmjene' : 'unosa'
          } priloga dokumenta`
        }`,
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedFile) {
      setValue('fileSize', selectedFile.size);
      const fileExtension = mime.extension(mime.contentType(selectedFileName));
      setValue('extension', `.${fileExtension}`);
    } else {
      setValue('fileSize', '');
      setValue('extension', '');
    }
  }, [selectedFile]);

  useEffect(() => {
    setLoading(false);
    clearErrors();
    reset();
  }, []);

  return (
    <InputModal
      object={selectedDocumentAttachment}
      updateTitle={`Uredi prilog dokumenta ${selectedDocumentAttachment?.id}`}
      updateButtonText="Uredi prilog dokumenta"
      createTitle="Kreiraj prilog dokumenta"
      createButtonText="Kreiraj prilog dokumenta"
      visible={visible}
      onSubmit={onSubmit}
      handleSubmit={handleSubmit}
      handleClose={handleClose}
      modalWidth="50vw"
      loading={loading}
      theme={theme}
      disabledEdit={disabled}
    >
      <ContentBox>
        <Grid container spacing={6}>
          <Grid item xs={6}>
            <Card style={{ height: '100%' }} elevation={4}>
              <CardHeader title="Prilog" titleTypographyProps={{ variant: 'subtitle2' }} />
              <Divider />
              <CardContent>
                <Box mb={4}>
                  <FormFileUpload
                    fullWidth
                    placeholder={selectedDocumentAttachment ? 'IZMIJENI PRILOG' : 'DODAJ PRILOG'}
                    setSelectedFile={setSelectedFile}
                    selectedFileName={selectedFileName}
                    setSelectedFileName={setSelectedFileName}
                    setIsFilePicked={setIsFilePicked}
                    isFilePicked={isFilePicked}
                  />
                </Box>
                <Divider />
                <Box mt={4}>
                  <TextField
                    fullWidth
                    name="extension"
                    id="extension"
                    label="Ekstenzija"
                    inputRef={register}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    defaultValue={selectedDocumentAttachment?.extension}
                    disabled
                  />
                </Box>
                <Box mt={1}>
                  <TextField
                    fullWidth
                    name="fileSize"
                    id="fileSize"
                    label="Veličina fajla (bajt)"
                    inputRef={register}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    defaultValue={selectedDocumentAttachment?.fileSize}
                    disabled
                  />
                </Box>
                <Box mt={1}>
                  <TextField
                    fullWidth
                    name="dateCreated"
                    id="dateCreated"
                    label="Datum kreiranja"
                    inputRef={register}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    defaultValue={
                      selectedDocumentAttachment
                        ? dateFormatter(selectedDocumentAttachment?.timeCreated, true)
                        : dateFormatter(dateNow(), true)
                    }
                    disabled
                  />
                </Box>
                <Box mt={1}>
                  <TextField
                    fullWidth
                    name="createdBy"
                    id="createdBy"
                    label="Korisnik kreirao"
                    inputRef={register}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    defaultValue={selectedDocumentAttachment?.userCreated}
                    disabled
                  />
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Card style={{ height: '100%' }} elevation={4}>
              <CardHeader titleTypographyProps={{ variant: 'subtitle2' }} title="Opis priloga" />
              <Divider />
              <CardContent>
                <Grid container direction="column" spacing={5}>
                  <Grid item>
                    <FormTextArea
                      register={register}
                      object={selectedDocumentAttachment}
                      name="description"
                      errors={errors}
                      maxLength={4000}
                      rows={7}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </ContentBox>
    </InputModal>
  );
};

export default Modal;
