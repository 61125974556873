import * as yup from 'yup';

export const subjectSchema = yup.object().shape({
  ordNumber: yup
    .number()
    .typeError('Redni broj mora biti broj')
    .min(0, 'Redni broj mora biti pozitivan broj')
    .integer('Redni broj mora biti cijeli broj')
    .transform((value, original) => (original === '' ? null : value))
    .nullable(),
  bookType: yup.lazy((value) => {
    return typeof value === 'object'
      ? yup
          .object()
          .shape({
            id: yup.number().positive(),
          })
          .typeError('Vrsta knjige je obavezno polje')
      : yup
          .number()
          .positive()
          .transform((v, o) => (o === '' ? null : v))
          .typeError('Vrsta knjige je obavezno polje');
  }),
  year: yup
    .number()
    .typeError('Godina mora biti broj')
    .min(0, 'Godina mora biti pozitivan broj')
    .integer('Godina mora biti cijeli broj')
    .transform((value, original) => (original === '' ? null : value))
    .nullable(),
  classificationMark: yup.lazy((value) => {
    return typeof value === 'object'
      ? yup
          .object()
          .shape({
            id: yup.number().positive(),
          })
          .typeError('Klasifikacona oznaka je obavezno polje')
      : yup
          .number()
          .positive()
          .transform((v, o) => (o === '' ? null : v))
          .typeError('Klasifikaciona oznaka je obavezno polje');
  }),
  name: yup
    .string()
    .max(200, 'Naziv mora biti manji od 200 znakova')
    .typeError('Naziv je obavezno polje')
    .required('Naziv je obavezno polje'),
  subjectStatus: yup.string().required('Status je obavezno polje'),
  description: yup.string().max(2000, 'Opis mora biti manji od 2000 znakova').nullable(),
  organisation: yup.lazy((value) => {
    return typeof value === 'object'
      ? yup
          .object()
          .shape({
            id: yup.number().positive(),
          })
          .typeError('Organizacija je obavezno polje')
      : yup
          .number()
          .positive()
          .transform((v, o) => (o === '' ? null : v))
          .typeError('Organizacija je obavezno polje');
  }),
  responsiblePerson: yup.lazy((value) => {
    return typeof value === 'object'
      ? yup
          .object()
          .shape({
            id: yup.number().positive(),
          })
          .typeError('Odgovorna osoba je obavezno polje')
      : yup
          .number()
          .positive()
          .transform((v, o) => (o === '' ? null : v))
          .typeError('Odgovorna osoba je obavezno polje');
  }),
  resolutionDeadline: yup
    .string()
    .transform((value) => (!value ? undefined : value))
    .matches(
      /^\d{4}\-(0?[1-9]|1[012])\-(0?[1-9]|[12][0-9]|3[01])$/,
      'Pogrešno unesena forma datuma (yyyy-mm-dd)',
    )
    .nullable(),
  archiveLocation: yup.lazy((value) => {
    return typeof value === 'object'
      ? yup
          .object()
          .shape({
            id: yup.number().positive(),
          })
          .nullable()
      : yup
          .number()
          .positive()
          .transform((v, o) => (o === '' ? null : v))
          .nullable();
  }),
  // subjectComment: yup.string().max(2000, 'Komentar mora biti manji od 2000 znakova').nullable(),
  // subjectValid: yup.string().oneOf(['D', 'N', ''], 'Morate odabrati vrijednosti DA ili NE.'),
  // ownSubject: yup.string().oneOf(['D', 'N', ''], 'Morate odabrati vrijednosti DA ili NE.'),
});

export const subjectArchiveLocationSchema = yup.object().shape({
  archiveLocation: yup.lazy((value) => {
    return typeof value === 'object'
      ? yup
          .object()
          .shape({
            id: yup.number().positive(),
          })
          .nullable()
      : yup
          .number()
          .positive()
          .transform((v, o) => (o === '' ? null : v))
          .nullable();
  }),
});
