import React from 'react';
import { Box, Card, Grid, Divider } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import { dateFormatter } from '@shared/components';
import useTheme from '../../../hooks/useTheme';
import { StyledLabel, StyledTypography } from './style';

const SubjectInfo = ({ subject }) => {
  const theme = useTheme();

  return (
    <Box>
      <Grid container spacing={5}>
        <Grid item sm={12} md={4}>
          <Card>
            <Box
              bgcolor={theme.palette.background.card}
              height="350px"
              display="flex"
              flexDirection="column"
              justifyContent="space-evenly"
            >
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Box width={250}>
                  <StyledLabel>Broj predmeta</StyledLabel>
                </Box>
                <StyledTypography variant="body2" color="textSecondary">
                  {subject?.subjectNumber || '---'}
                </StyledTypography>
              </Box>
              <Divider />
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Box width={250}>
                  <StyledLabel>Godina</StyledLabel>
                </Box>
                <StyledTypography variant="body2" color="textSecondary">
                  {subject?.year || '---'}
                </StyledTypography>
              </Box>
              <Divider />
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Box width={250}>
                  <StyledLabel>Redni broj</StyledLabel>
                </Box>
                <StyledTypography variant="body2" color="textSecondary">
                  {subject?.ordNumber || '---'}
                </StyledTypography>
              </Box>
              <Divider />
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Box width={250}>
                  <StyledLabel>Naziv predmeta</StyledLabel>
                </Box>
                <StyledTypography variant="body2" color="textSecondary">
                  {subject?.name || '---'}
                </StyledTypography>
              </Box>
              <Divider />
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Box width={250}>
                  <StyledLabel>Klasifikaciona oznaka</StyledLabel>
                </Box>
                <StyledTypography variant="body2" color="textSecondary">
                  {subject?.classificationMarkName || '---'}
                </StyledTypography>
              </Box>
              <Divider />
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Box width={250}>
                  <StyledLabel>Vrsta knjige</StyledLabel>
                </Box>
                <StyledTypography variant="body2" color="textSecondary">
                  {subject?.bookTypeName || '---'}
                </StyledTypography>
              </Box>
            </Box>
          </Card>
        </Grid>
        <Grid item sm={12} md={4}>
          <Card>
            <Box
              bgcolor={theme.palette.background.card}
              height="350px"
              display="flex"
              flexDirection="column"
              justifyContent="space-evenly"
            >
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Box width={250}>
                  <StyledLabel>Važeći predmet</StyledLabel>
                </Box>
                <StyledTypography variant="body2" color="textSecondary">
                  {subject?.subjectValid ? (
                    subject.subjectValid === 'D' ? (
                      <DoneIcon style={{ color: theme.palette.success.main }} />
                    ) : (
                      <CloseIcon style={{ color: theme.palette.error.main }} />
                    )
                  ) : (
                    '---'
                  )}
                </StyledTypography>
              </Box>
              <Divider />
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Box width={250}>
                  <StyledLabel>Vlastiti predmet</StyledLabel>
                </Box>
                <StyledTypography variant="body2" color="textSecondary">
                  {subject?.ownSubject ? (
                    subject.ownSubject === 'D' ? (
                      <DoneIcon
                        style={{
                          color: theme.palette.success.main,
                        }}
                      />
                    ) : (
                      <CloseIcon
                        style={{
                          color: theme.palette.error.main,
                        }}
                      />
                    )
                  ) : (
                    '---'
                  )}
                </StyledTypography>
              </Box>
              <Divider />

              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Box width={250}>
                  <StyledLabel>Status predmeta</StyledLabel>
                </Box>
                <StyledTypography variant="body2" color="textSecondary">
                  {subject?.statusName || '---'}
                </StyledTypography>
              </Box>
              <Divider />

              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Box width={250}>
                  <StyledLabel>Odgovorna osoba</StyledLabel>
                </Box>
                <StyledTypography variant="body2" color="textSecondary">
                  {subject?.responsiblePersonName || '---'}
                </StyledTypography>
              </Box>
              <Divider />
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Box width={250}>
                  <StyledLabel>Organizacija</StyledLabel>
                </Box>
                <StyledTypography variant="body2" color="textSecondary">
                  {subject?.organisationName || '---'}
                </StyledTypography>
              </Box>
              <Divider />
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Box width={250}>
                  <StyledLabel>Kreirao korisnik</StyledLabel>
                </Box>
                <StyledTypography variant="body2" color="textSecondary">
                  {subject?.createdBy || '---'}
                </StyledTypography>
              </Box>
            </Box>
          </Card>
        </Grid>
        <Grid item sm={12} md={4}>
          <Card>
            <Box
              bgcolor={theme.palette.background.card}
              height="350px"
              display="flex"
              flexDirection="column"
              justifyContent="space-evenly"
            >
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Box width={250}>
                  <StyledLabel>Datum i vrijeme kreiranja</StyledLabel>
                </Box>
                <StyledTypography variant="body2" color="textSecondary">
                  {dateFormatter(subject?.dateCreated, true) || '---'}
                </StyledTypography>
              </Box>
              <Divider />
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Box width={250}>
                  <StyledLabel>Datum i vrijeme ažuriranja</StyledLabel>
                </Box>
                <StyledTypography variant="body2" color="textSecondary">
                  {dateFormatter(subject?.dateModified, true) || '---'}
                </StyledTypography>
              </Box>
              <Divider />
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Box width={250}>
                  <StyledLabel>Rok za rješavanje predmeta</StyledLabel>
                </Box>
                <StyledTypography variant="body2" color="textSecondary">
                  {dateFormatter(subject?.resolutionDeadline) || '---'}
                </StyledTypography>
              </Box>
              <Divider />
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Box width={250}>
                  <StyledLabel>Datum rješavanja predmeta</StyledLabel>
                </Box>
                <StyledTypography variant="body2" color="textSecondary">
                  {dateFormatter(subject?.resolutionDate) || '---'}
                </StyledTypography>
              </Box>
              <Divider />
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Box width={250}>
                  <StyledLabel>Datum arhiviranja predmeta</StyledLabel>
                </Box>
                <StyledTypography variant="body2" color="textSecondary">
                  {dateFormatter(subject?.archiveDate) || '---'}
                </StyledTypography>
              </Box>
              <Divider />

              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Box width={250}>
                  <StyledLabel>Arhivska lokacija predmeta</StyledLabel>
                </Box>
                <StyledTypography variant="body2" color="textSecondary">
                  {subject?.archiveLocationName || '---'}
                </StyledTypography>
              </Box>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SubjectInfo;
