import React, { useState } from 'react';
import {
  CardHeader,
  Divider,
  Grid,
  Card,
  Tabs,
  Tab,
  Breadcrumbs,
  Typography,
  Link,
  Box,
  Button,
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import FilterListIcon from '@material-ui/icons/FilterList';
import CloseIcon from '@material-ui/icons/Close';
import {
  FilterChip,
  QuickSearch,
  useAPI,
  VerticalBoxContainer,
  PageContentWrapper,
  StatusLabel,
} from '@shared/components';
import PageHeader from '../../components/PageHeader';
import axios from '../../utils/axios';
import { handleChipDelete } from '../../utils/filterHelpers';
import Table from './Table';
import Filter from './Filter';
import Modal from './Modal';
import useTheme from '../../hooks/useTheme';
import ExcelExportButton from '../../components/ExcelExportButton';
import useGlobalAlertContext from '../../hooks/useGlobalAlertContext';

const fields = [
  { label: 'ID', value: 'id' },
  { label: 'Dokument', value: 'document' },
  { label: 'Status dokumenta', value: 'documentStatusName' },
  { label: 'Tip dokumenta', value: 'documentTypeName' },
  { label: 'Atribut (metapodataka)', value: 'attributeName' },
  { label: 'Vrijednost', value: 'valueDisplayed' },
  { label: 'Datum kreiranja', value: 'dateCreated', type: 'dateTime' },
  { label: 'Korisnik kreirao', value: 'createdBy' },
];

const getLabelText = (field) => {
  const map = {
    id: 'ID',
    document: 'Dokument',
    createdBy: 'Korisnik',
    attributeName: 'Atribut (metapodataka)',
    documentTypeName: 'Tip dokumenta',
    valueDisplayed: 'Vrijednost',
    dateCreated: 'Datum kreiranja',
    documentStatusName: 'Status dokumenta',
  };
  return map[field];
};

const getChipValue = (chip, filters) => {
  if (chip === 'dateCreated') {
    return filters[chip].replace('T', ' ');
  }
  return filters[chip];
};

const DocumentAttribute = () => {
  const theme = useTheme();
  const [filters, setFilters] = useState({});
  const [sorters, setSorters] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const { sendSuccessAlertData } = useGlobalAlertContext();
  const [filterModalVisible, setFilterModalVisible] = useState(false);
  const [triggerRerender, setRerender] = useState(false);

  const [selectedDocumentAttribute, setSelectedDocumentAtributte] = useState({});
  const [documentAttributeModalVisible, setDocumentAttributeModalVisible] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState('all');

  const statuses = [
    { id: 4, name: 'Dokument finalno usaglašen', color: 'success' },
    { id: 5, name: 'Dokument nije usaglašen', color: 'error' },
    { id: 6, name: 'Dokument vraćen/poništen', color: 'warning' },
    { id: 7, name: 'Dokument arhiviran', color: 'info' },
  ];
  const [documentAttribute = { nodes: [], totalCount: 0 }, error, loading] = useAPI(
    () =>
      axios
        .get(`document-attributes-main`, {
          params: {
            limit: rowsPerPage,
            offset: page * rowsPerPage,
            conditions: {
              ...filters,
              ...(selectedStatus !== 'all' && { documentStatus: selectedStatus }),
            },
            orderBy: [...sorters, "'id' desc"],
          },
        })
        .then(({ data }) => data),
    [page, rowsPerPage, filters, sorters, triggerRerender, selectedStatus],
  );

  const handleFilterChange = (filterValues) => {
    setPage(0);
    setFilters({ ...filterValues });
  };

  return (
    <VerticalBoxContainer>
      <Modal
        document={selectedDocumentAttribute}
        resetDocument={() => setSelectedDocumentAtributte(undefined)}
        visible={documentAttributeModalVisible}
        sendSuccessAlertData={sendSuccessAlertData}
        setVisible={setDocumentAttributeModalVisible}
        setRerender={setRerender}
      />
      <Filter
        currentValues={filters}
        visible={filterModalVisible}
        setVisible={(visible) => {
          setSelectedStatus('all');
          setFilterModalVisible(visible);
        }}
        handleFilterChange={handleFilterChange}
        getLabelText={getLabelText}
        getChipValue={getChipValue}
        setFilters={setFilters}
        setPage={setPage}
      />
      <PageHeader title="Pretraga dokumenata po metapodacima" />
      <PageContentWrapper>
        <Breadcrumbs style={{ paddingBottom: `${theme.spacing(5)}px` }}>
          <Link variant="body1" color="inherit" to="/" component={RouterLink}>
            Početna
          </Link>
          <Typography variant="body1" color="textPrimary">
            Pretraga dokumenata po metapodacima
          </Typography>
        </Breadcrumbs>
        <Box
          width="100%"
          bgcolor="background.paper"
          borderRadius="2px 2px 0"
          border={`1px solid ${theme.palette.divider}`}
          marginLeft="-1px"
          borderBottom={0}
        >
          <Tabs
            value={selectedStatus}
            onChange={(e, value) => setSelectedStatus(value)}
            indicatorColor="primary"
            textColor="primary"
            aria-label="status group tabs"
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab
              style={{
                minWidth: '0',
              }}
              value="all"
              label={<StatusLabel color="neutral">Svi</StatusLabel>}
            />
            {statuses.map((group) => (
              <Tab
                key={group.id}
                value={group.id}
                style={{
                  maxWidth: '280px',
                }}
                label={<StatusLabel color={group.color}>{group.name}</StatusLabel>}
              />
            ))}
          </Tabs>
        </Box>
        <Card
          style={{
            borderTopLeftRadius: '0',
            borderTopRightRadius: '0',
          }}
        >
          <CardHeader
            title={
              <>
                <QuickSearch
                  name="valueDisplayed"
                  placeholder="Brza pretraga po vrijednosti"
                  setFilters={setFilters}
                  mainFilter={filters}
                />
              </>
            }
            action={
              <Grid container spacing={4}>
                <Grid item>
                  <Button
                    color="primary"
                    disabled={Object.keys(filters).filter((k) => filters[k])?.length === 0}
                    startIcon={<CloseIcon fontSize="small" />}
                    onClick={() => setFilters({})}
                  >
                    <Box fontSize="small">Ukloni sve filtere</Box>
                  </Button>
                </Grid>

                <Grid item>
                  <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<FilterListIcon fontSize="small" />}
                    onClick={() => setFilterModalVisible(true)}
                  >
                    <Box fontSize="small">Filteri</Box>
                  </Button>
                </Grid>
                <Grid item>
                  <ExcelExportButton
                    fields={fields}
                    sorters={[...sorters, "'id' desc"]}
                    filters={{}}
                    routeName="document-attributes-main"
                    fileName="pretraga-po-metapodacima"
                    totalCount={documentAttribute.totalCount}
                  />
                </Grid>
              </Grid>
            }
          />
          <Divider />
          {Object.keys(filters).filter((k) => filters[k])?.length !== 0 && (
            <>
              <Box ml={2} p={2} display="flex" alignItems="center" flexWrap="wrap">
                {Object.keys(filters).map(
                  (chip) =>
                    filters[chip] !== undefined && (
                      <FilterChip
                        color="primary"
                        variant="outlined"
                        key={chip}
                        label={`${getLabelText(chip)}: ${getChipValue(chip, filters)}`}
                        onDelete={() => handleChipDelete(chip, setFilters, setPage)}
                      />
                    ),
                )}
              </Box>
            </>
          )}
          <Table
            loading={loading}
            error={error}
            documentAttributes={documentAttribute.nodes}
            page={page}
            setPage={setPage}
            sorters={sorters}
            setSorters={setSorters}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            totalCount={documentAttribute.totalCount}
            selectedDocumentAttribute={selectedDocumentAttribute}
            setSelectedDocumentAtributte={setSelectedDocumentAtributte}
            setDocumentAttributeModalVisible={setDocumentAttributeModalVisible}
          />
        </Card>
      </PageContentWrapper>
    </VerticalBoxContainer>
  );
};

export default DocumentAttribute;
