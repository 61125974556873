import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import { Box as MuiBox } from '@material-ui/core';

export const useTransferListStyles = makeStyles((theme) => ({
  root: {
    margin: 'auto',
    width: '100%',
  },
  cardHeader: {
    padding: theme.spacing(1, 2),
  },
  cardContent: {
    padding: '0 !important',
    height:({ height }) => height,
  },
  list: {
    height: '100%',
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
  gridItem: {
    minWidth: ({ toggleFullHeight }) => !toggleFullHeight && '15vw',
  },
  search: {
    padding: '0',
    width: '100%',
  },
}));

export const ContentBox = styled(MuiBox)`
  padding: ${({ theme }) => theme.spacing(5)}px;
  margin-bottom: ${({ theme }) => theme.spacing(5)}px;
  height: 100%;
`;

export const ErrorMessage = styled.span`
  font-size: 24px;
  padding-left: ${({ theme }) => theme.spacing(2)}px;
`;
