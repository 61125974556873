import React, { useState, useEffect } from 'react';
import {
  Divider,
  Grid,
  Box,
  TextField,
  FormHelperText,
  Card,
  CardHeader,
  CardContent,
  MenuItem,
} from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { InputModal, FormSelect, FormTextArea } from '@shared/components';

import { archiveLocationSchema } from '@shared/partner-schemas';
import axios from '../../../utils/axios';
import useTheme from '../../../hooks/useTheme';

const ArchiveLocationsModal = ({
  archiveLocation,
  setArchiveLocation,
  visible,
  setVisible,
  sendSuccessAlertData,
  sendErrorAlertData,
  setRerender,
  resetArchiveLocation,
}) => {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [requestError, setRequestError] = useState({});
  const { handleSubmit, errors, reset, clearErrors, register, control } = useForm({
    resolver: yupResolver(archiveLocationSchema),
  });

  const onSubmit = async ({ code, name, isValid, description }) => {
    setLoading(true);
    try {
      const data = archiveLocation
        ? await axios.put(`archive-locations/${archiveLocation.id}`, {
          archiveLocation: { code, name, isValid, description },
        })
        : await axios.post(`archive-locations`, {
          archiveLocation: { code, name, isValid, description },
        });
      if (!data) throw new Error();
      sendSuccessAlertData(
        `Uspješno ${archiveLocation ? 'izmijenjena' : 'unesena'} arhivske lokacije`,
      );
      if (archiveLocation) setArchiveLocation(data.data);
      setRerender((prevState) => !prevState);
      handleClose();
    } catch (error) {
      sendErrorAlertData(
        `${error?.response?.data?.details?.reason ||
        error?.response?.data?.error?.message ||
        `Došlo je do greške prilikom ${archiveLocation ? 'uređivanja' : 'unosa'
        } arhivske lokacije`
        }`,
      );
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setVisible(false);
    resetArchiveLocation();
  };

  useEffect(() => {
    setLoading(false);
    setRequestError(null);
    clearErrors();
    reset();
  }, []);

  return (
    <InputModal
      object={archiveLocation}
      createTitle="Unos nove arhivske lokacije"
      updateTitle={`Uredi arhivsku lokaciju: ${archiveLocation?.code}`}
      createButtonText="Kreiraj arhivsku lokaciju"
      updateButtonText="Uredi arhivsku lokaciju"
      visible={visible}
      onSubmit={onSubmit}
      handleSubmit={handleSubmit}
      handleClose={handleClose}
      requestError={requestError}
      modalWidth={600}
      loading={loading}
      theme={theme}
    >
      <Box
        padding={`${theme.spacing(5)}px`}
        style={{ backgroundColor: theme.palette.background.dark }}
      >
        <Grid
          container
          style={{ minHeight: 250 }}
          spacing={6}
          direction="column"
          alignItems="stretch"
        >
          <Grid item>
            <Card elevation={4} style={{ height: '100%' }}>
              <CardHeader title="Osnovni podaci" titleTypographyProps={{ variant: 'subtitle2' }} />
              <Divider />
              <CardContent>
                <Grid container direction="column" spacing={6}>
                  <Grid item>
                    <TextField
                      fullWidth
                      error={!!errors.code}
                      label="Šifra*"
                      defaultValue={archiveLocation?.code}
                      name="code"
                      id="code"
                      placeholder="Unesite šifru"
                      inputRef={register}
                    />
                    {errors.code && <FormHelperText error>{errors.code.message}</FormHelperText>}
                  </Grid>
                  <Grid item>
                    <TextField
                      fullWidth
                      error={!!errors.name}
                      label="Naziv*"
                      defaultValue={archiveLocation?.name}
                      name="name"
                      id="name"
                      placeholder="Unesite naziv"
                      inputRef={register}
                    />
                    {errors.name && <FormHelperText error>{errors.name.message}</FormHelperText>}
                  </Grid>
                  <Grid item>
                    <FormSelect
                      id="isValid"
                      name="isValid"
                      label="Važi*"
                      required
                      error={!!errors.isValid}
                      defaultValue={archiveLocation?.isValid || 'D'}
                      control={control}
                      fullWidth
                    >
                      <MenuItem value="D">DA</MenuItem>
                      <MenuItem value="N">NE</MenuItem>
                    </FormSelect>
                    {errors.isValid && (
                      <FormHelperText error>{errors.isValid.message}</FormHelperText>
                    )}
                  </Grid>
                  <Grid item>
                    <FormTextArea
                      label="Opis"
                      object={archiveLocation}
                      register={register}
                      name="description"
                      errors={errors}
                      maxLength={2000}
                      rows={4}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </InputModal>
  );
};

export default ArchiveLocationsModal;
