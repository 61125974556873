import React, { useState, useEffect } from 'react';
import { ConfirmDialog } from '@shared/components';
import axios from '../../../utils/axios';

const getParams = (actionType, subjectId) => {
  switch (actionType) {
    case 'resolve':
      return {
        title: 'Rješavanje predmeta',
        button: 'Riješi predmet',
        message: 'Da li ste sigurni da želite riješiti predmet?',
        apiRoute: `/subjects/${subjectId}/resolve`,
        errorMessage: 'Došlo je do greške prilikom rješavanja predmeta',
        successMessage: 'Uspješno riješen predmet',
      };
    default:
      return {
        title: '',
        button: '',
        message: '',
        apiRoute: '',
        errorMessage: 'Došlo je do greške',
        successMessage: '',
      };
  }
};

const ActionModal = ({
  subjectId,
  visible,
  setVisible,
  setLoadingReturn,
  sendSuccessAlertData,
  sendErrorAlertData,
  setRerender,
  actionType,
}) => {
  const [loading, setLoading] = useState(false);
  const { title, message, button, apiRoute, errorMessage, successMessage } = getParams(
    actionType,
    subjectId,
  );
  const handleClose = () => {
    setVisible(false);
  };

  useEffect(() => {
    setLoading(false);
    setLoadingReturn(false);
  }, []);

  const handleApproval = async () => {
    setLoading(true);
    setLoadingReturn(true);
    try {
      await axios.post(apiRoute);
      sendSuccessAlertData(`${successMessage}`);
      setRerender((prevState) => !prevState);
      handleClose();
    } catch (error) {
      sendErrorAlertData(
        `${
          error?.response?.data?.details?.reason ||
          error?.response?.data?.error?.message ||
          errorMessage
        } `,
      );
    } finally {
      setLoading(false);
      setLoadingReturn(false);
    }
  };

  return (
    <ConfirmDialog
      open={visible}
      setOpen={setVisible}
      title={title}
      message={message}
      onSubmit={handleApproval}
      loading={loading}
      confirmText={button}
      denyText="Odustani"
    />
  );
};

export default ActionModal;
