import React, { useState } from 'react';
import { Breadcrumbs, Typography, Link, AppBar, Box, Backdrop, Grid } from '@material-ui/core';
import { Link as RouterLink, useHistory, useParams } from 'react-router-dom';
import {
  VerticalBoxContainer,
  TabPanel,
  useAPI,
  Spinner,
  AntTab,
  AntTabs,
  PageContentWrapper,
} from '@shared/components';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';
import LoopIcon from '@material-ui/icons/Loop';
import PageHeader from '../../components/PageHeader';
import { a11yProps } from '../../utils/tabFormatter';
import axios from '../../utils/axios';
import useGlobalAlertContext from '../../hooks/useGlobalAlertContext';
import useTheme from '../../hooks/useTheme';
import useAuthContext from '../../hooks/useAuthContext';
import DocumentHistory from './Tabs/History';
import Documents from './Tabs/Documents';
import Comments from './Tabs/Comments';
import SubjectInfo from './SubjectInfo';
import ActionModal from '../SubjectsArchived/ActionModal';
import { ActionButton } from './style';

const SubjectDetails = () => {
  const theme = useTheme();
  const [currentTab, setCurrentTab] = useState(0);
  const [triggerRerender, setRerender] = useState(false);
  const [loadingReturn, setLoadingReturn] = useState(false);
  const [actionType, setActionType] = useState('');
  const [actionModalVisible, setActionModalVisible] = useState(false);
  const history = useHistory();
  const { sendSuccessAlertData, sendErrorAlertData } = useGlobalAlertContext();
  const { id } = useParams();
  const { user } = useAuthContext();
  const SCREEN = 'EKRAN2';

  const [subject, error, apiLoading] = useAPI(
    () => axios.get(`subjects/${id}`, { params: { screen: SCREEN } }).then(({ data }) => data),
    [triggerRerender],
  );

  const handleChange = (_, newValue) => {
    setCurrentTab(newValue);
  };

  const tabs = [
    {
      name: 'Dokumenti',
      component: <Documents subjectId={id} />,
    },
    {
      name: 'Komentari',
      component: <Comments subjectId={id} />,
    },
    {
      name: 'Historija',
      component: <DocumentHistory subjectId={id} />,
    },
  ];

  if (apiLoading)
    return (
      <Backdrop
        open
        style={{
          zIndex: theme.zIndex.drawer - 1,
          backgroundColor: theme.palette.background.default,
        }}
      >
        <Spinner size={50} />
      </Backdrop>
    );

  if (error) {
    sendErrorAlertData(
      `${
        error?.response?.data?.details?.reason ||
        error?.response?.data?.error?.message ||
        `Došlo je do greške prilikom preuzimanja informacija o predmetu`
      }`,
    );
    history.replace('/archived-subjects');
  }

  return (
    <VerticalBoxContainer>
      <ActionModal
        subjectId={subject?.id}
        visible={actionModalVisible}
        setVisible={setActionModalVisible}
        sendSuccessAlertData={sendSuccessAlertData}
        sendErrorAlertData={sendErrorAlertData}
        setRerender={setRerender}
        setLoadingReturn={setLoadingReturn}
        actionType={actionType}
        // disabled={!userHasAnyRoles(['DMS_PD_EDIT'])}
      />
      <PageHeader
        showBackIcon
        title={`Detalji predmeta ${id}`}
        history={history}
        extra={
          user.username === subject?.responsiblePersonUsername && (
            <Grid container spacing={4}>
              <Grid item>
                <ActionButton
                  variant="outlined"
                  actioncolor={theme.palette.info.main}
                  onClick={() => {
                    setActionType('activateFromArchive');
                    setActionModalVisible(true);
                  }}
                  disabled={subject?.responsiblePersonUsername !== user?.username}
                  startIcon={loadingReturn ? <Spinner size={20} /> : <LoopIcon />}
                >
                  <Box fontSize="small">Aktiviraj predmet</Box>
                </ActionButton>
              </Grid>
              <Grid item>
                <ActionButton
                  variant="outlined"
                  actioncolor={theme.palette.info.main}
                  onClick={() => {
                    setActionType('issueOnReverse');
                    setActionModalVisible(true);
                  }}
                  disabled={subject?.responsiblePersonUsername !== user?.username}
                  startIcon={loadingReturn ? <Spinner size={20} /> : <OpenInBrowserIcon />}
                >
                  <Box fontSize="small">Izdaj predmet na revers</Box>
                </ActionButton>
              </Grid>
            </Grid>
          )
        }
      />
      <PageContentWrapper style={{ padding: `80px 0 0 0` }}>
        <Breadcrumbs
          style={{
            padding: `${theme.spacing(5)}px 0 ${theme.spacing(3)}px ${theme.spacing(6)}px`,
          }}
        >
          <Link variant="body1" color="inherit" to="/" component={RouterLink}>
            Početna
          </Link>
          <Link variant="body1" color="inherit" to="/archived-subjects" component={RouterLink}>
            Arhivirani predmeti
          </Link>
          <Typography variant="body1" color="textPrimary">
            {tabs[currentTab].extendedName ? tabs[currentTab].extendedName : tabs[currentTab].name}
          </Typography>
        </Breadcrumbs>
        <Box mr={6} ml={6} style={{ marginBottom: '24px' }}>
          <SubjectInfo subject={subject} />
        </Box>
        <Box mr={6} ml={6}>
          <AppBar
            position="static"
            style={{
              background: `${theme.palette.background.default}`,
              padding: '0',
            }}
            elevation={0}
          >
            <AntTabs
              value={currentTab}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
            >
              {tabs.map((tab, index) => (
                <AntTab key={tab.name} label={tab.name} {...a11yProps(index)} />
              ))}
            </AntTabs>
          </AppBar>
          {tabs.map((tab, index) => (
            <TabPanel
              key={tab.name}
              value={currentTab}
              index={index}
              dir={theme.direction}
              theme={theme}
              style={{ marginTop: '0px' }}
            >
              {tab.component}
            </TabPanel>
          ))}
        </Box>
      </PageContentWrapper>
    </VerticalBoxContainer>
  );
};

export default SubjectDetails;
