import React from 'react';
import { Tooltip, Box, Chip } from '@material-ui/core';

import { dateFormatter, daysUntil, dateShort, dateFormatterDate } from '@shared/components';

import CalendarTodayIcon from '@material-ui/icons/CalendarTodayRounded';
import EventBusyRoundedIcon from '@material-ui/icons/EventBusyRounded';
import EventAvailableRoundedIcon from '@material-ui/icons/EventAvailableRounded';

const DeadlineChip = ({ subject }) => {
  if (!subject.resolutionDeadline) return <></>;
  const days = daysUntil(subject.resolutionDeadline);

  if (subject.status === 4 || subject.status === 2)
    return (
      <Chip
        style={{ display: 'flex', border: 'none' }}
        label={<Box sx={{ fontWeight: 'bold' }}>{dateFormatter(subject.resolutionDeadline)}</Box>}
        avatar={<EventAvailableRoundedIcon fontSize="small" />}
        variant="outlined"
      />
    );

  let chip = null;
  if (days < 0)
    chip = (
      <Chip
        style={{ display: 'flex' }}
        label={<Box sx={{ fontWeight: 'bold' }}>{dateFormatter(subject.resolutionDeadline)}</Box>}
        avatar={<CalendarTodayIcon fontSize="small" />}
        variant="outlined"
      />
    );
  else if (days < 15) {
    chip = (
      <Tooltip
        enterDelay={300}
        placement="top-end"
        arrow
        title={`Rok za rješavanje predmeta ističe za ${days === 1 ? '1 dan' : `${days} dana`}`}
      >
        <span>
          <Chip
            style={{ display: 'flex' }}
            label={<Box sx={{ fontWeight: 'bold' }}>{dateShort(subject.resolutionDeadline)}</Box>}
            avatar={<EventBusyRoundedIcon style={{ color: '#f44336' }} fontSize="small" />}
            {...{ style: { borderColor: '#f44336', display: 'flex' } }}
            variant="outlined"
          />
        </span>
      </Tooltip>
    );
  } else if (days < 30) {
    chip = (
      <Tooltip
        enterDelay={300}
        placement="top-end"
        arrow
        title={`Ostalo je još ${days} dana do kraja roka za rješavanje`}
      >
        <span>
          <Chip
            label={<Box sx={{ fontWeight: 'bold' }}>{dateShort(subject.resolutionDeadline)}</Box>}
            avatar={
              subject.statusName === 'Predmet riješen' ? (
                <EventAvailableRoundedIcon fontSize="small" />
              ) : (
                <CalendarTodayIcon style={{ color: '#ff9800' }} fontSize="small" />
              )
            }
            {...{ style: { borderColor: '#ff9800', display: 'flex' } }}
            variant="outlined"
          />
        </span>
      </Tooltip>
    );
  } else
    chip = (
      <Tooltip
        enterDelay={300}
        placement="top-end"
        arrow
        title={`Krajnji rok za rješavanje predmeta je ${dateFormatterDate(
          subject.resolutionDeadline,
        )} (${days} dana)`}
      >
        <span>
          <Chip
            label={<Box sx={{ fontWeight: 'bold' }}>{dateShort(subject.resolutionDeadline)}</Box>}
            avatar={<CalendarTodayIcon style={{ color: '#4caf50' }} fontSize="small" />}
            {...(subject.statusName === 'Predmet riješen'
              ? { display: 'flex' }
              : { style: { borderColor: '#4caf50', display: 'flex' } })}
            variant="outlined"
          />
        </span>
      </Tooltip>
    );

  return chip;
};

export default DeadlineChip;
